import React from 'react';
import { useSnapCarousel } from 'react-snap-carousel';

import {
  ArrowBackIos,
  ArrowBackIosNew,
  ArrowForwardIos
} from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';

import BookCard from 'components/BookCard';
import { useLangContext } from 'components/Globalization';

import {
  CarouselContainer,
  CarouselContent,
  CarouselDot,
  CarouselDots,
  CarouselItem,
  CarouselList
} from './styles';

const Carousel = ({ data, handleView, volumeCode }) => {
  const { scrollRef, pages, activePageIndex, next, prev, goTo } =
    useSnapCarousel();
  const translate = useLangContext();

  return (
    <CarouselContainer>
      <Typography variant="h6">
        {translate('LITERARY_DIDACTIC_WORKS:RELATED_WORKS')}
      </Typography>
      <Divider style={{ margin: '12px 0 24px' }} />
      <CarouselContent>
        <ArrowBackIos
          data-testid="prevButton"
          className="prev"
          size="small"
          color="primary"
          onClick={() => prev()}
        >
          <ArrowBackIosNew />
        </ArrowBackIos>

        <CarouselList ref={scrollRef}>
          {data
            .filter(volume => volume.volumeCode !== volumeCode)
            .map(work => (
              <CarouselItem key={work.volumeCode}>
                <BookCard data={work} handleView={() => handleView(work)} />
              </CarouselItem>
            ))}
        </CarouselList>

        <ArrowForwardIos
          data-testid="nextButton"
          className="next"
          size="small"
          color="primary"
          onClick={() => next()}
        >
          <ArrowForwardIos />
        </ArrowForwardIos>
      </CarouselContent>

      <CarouselDots>
        {pages.map((page, i) => (
          <CarouselDot
            key={page}
            onClick={() => goTo(i)}
            className={i === activePageIndex ? 'active' : ''}
          />
        ))}
      </CarouselDots>
    </CarouselContainer>
  );
};

export default Carousel;
