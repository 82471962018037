import React from 'react';

import { Box, Hidden } from '@mui/material';

import { useLangContext } from 'components/Globalization';

import smartphoneMobile from 'assets/img/notebook_initial_mobile.png';
import desktop from 'assets/img/notebook_initial.png';

import {
  CardContainer,
  CardDesktopTitle,
  CardMobileTitle,
  SmartphoneImage,
  SmartphoneMobileImage
} from '../../styles';

const DownloadDesktop = () => {
  const translate = useLangContext();
  return (
    <CardContainer>
      <Hidden mdUp>
        <CardMobileTitle>
          {translate('CARDS.DESKTOP.CARD_TITLE')}
        </CardMobileTitle>
      </Hidden>
      <Box display="flex" flexDirection="column">
        <Hidden mdDown>
          <CardDesktopTitle>
            {translate('CARDS.DESKTOP.CARD_TITLE')}
          </CardDesktopTitle>
        </Hidden>
      </Box>
      <Hidden mdDown>
        <SmartphoneImage src={desktop} />
      </Hidden>
      <Hidden mdUp>
        <SmartphoneMobileImage
          src={smartphoneMobile}
          style={{ marginBottom: '35px' }}
        />
      </Hidden>
    </CardContainer>
  );
};

export default DownloadDesktop;
