import { CURRENT_ENVIRONMENT } from './constants/currentEnvironment';

const developmentEnv =
  window.env.REACT_APP_ENV === 'development' ||
  window.env.NODE_ENV === 'development';

export const isDevelopment = run => {
  if (developmentEnv && run) run();
  return developmentEnv;
};

export const recaptchaEnabled =
  window.env.REACT_APP_RECAPTCHA_DISABLED !== 'true';

export const currentEnvironment =
  window.env.REACT_APP_CURRENT_ENVIRONMENT || CURRENT_ENVIRONMENT.DEV;

export const isCurrentEnvironment = (
  environment = [
    CURRENT_ENVIRONMENT.DEV,
    CURRENT_ENVIRONMENT.TST,
    CURRENT_ENVIRONMENT.HMG,
    CURRENT_ENVIRONMENT.PRD
  ]
) => environment.includes(currentEnvironment);
