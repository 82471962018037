import PrivacyPolicy from 'modules/App/submodules/PrivacyPolicy';
import TermsOfUse from 'modules/App/TermsOfUse';
import Login from 'modules/Auth/submodules/Login';
import SuccessMessage from 'modules/Auth/submodules/SuccessMessage';
import RequestAccess from 'modules/RequestAccess';
import TermsOfConsentiment from 'modules/RequestAccess/submodules/TermsOfConsentiment';
import StepPasswordBook from 'modules/StepPasswordBook';
import ChangePassword from 'modules/User/submodules/ChangePassword';
import DataProcessingAgreement from 'modules/User/submodules/DataProcessingAgreement';
import RequestPasswordReset from 'modules/User/submodules/RequestPasswordReset';
import ResetPassword from 'modules/User/submodules/ResetPassword';
import SelectSecurityQuestions from 'modules/User/submodules/SelectSecurityQuestions';

import Logout from '../../../Auth/submodules/Logout';
import Token from '../../../Auth/submodules/Token';
import NoAccess from '../../submodules/NoAccess';

export const PUBLIC_ROUTES = [
  Login,
  NoAccess,
  Token,
  Logout,
  SuccessMessage,
  RequestAccess,
  ChangePassword,
  DataProcessingAgreement,
  RequestPasswordReset,
  ResetPassword,
  SelectSecurityQuestions,
  StepPasswordBook,
  TermsOfConsentiment,
  PrivacyPolicy,
  TermsOfUse
];
