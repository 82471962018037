import styled from 'styled-components';

import { Box } from '@mui/material';

export const ContainerBackground = styled(Box)`
  p {
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
    text-justify: inter-word;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  widht: 500px;

  td {
    border: 1px solid ${props => props.theme.palette.text.primary};
    padding: 16px;

    &:first-child {
      background: ${props => props.theme.palette.background.default};
      font-weight: bold;
      text-align: center;
    }
  }
`;
export const CardTable = styled(Table)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ItemList = styled.ul`
  list-style: initial;
  list-style-position: inside;
`;

export const TextCard = styled.p`
  background: ${props => props.theme.palette.background.default};
  font-weight: bold;
  padding: 16px;
  color: ${props => props.theme.palette.primary.main};
`;
