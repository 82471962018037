import { useCallback, useState } from 'react';

import TabContainer from './TabsContainer';

const useTabs = ({ tabs = [], defaultTab, fullWidth = false }) => {
  const [currentTab, setCurrentTab] = useState(defaultTab || tabs[0]?.key || 0);

  const setTabIndex = useCallback(
    index => {
      setCurrentTab(tabs[index]?.key || 0);
    },
    [tabs]
  );

  return {
    TabContainer,
    tabContainerProps: { tabs, currentTab, setCurrentTab, fullWidth },
    currentTab,
    setTab: setCurrentTab,
    setTabIndex
  };
};

export default useTabs;
