import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { usePagination } from 'hooks/usePagination';

import { Add, Delete } from '@mui/icons-material';
import {
  Container,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import { ConfirmDialog } from 'components/Dialog';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { notificationUseCases } from 'modules/Notification/providers';

import { REQUEST_LIST_ROUTES } from './constants/routes';
import { requestListUseCases } from './provider';

const RequestList = () => {
  const translate = useLangContext();
  const history = useHistory();
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [itemRequestId, setItemRequesId] = useState('');

  const fetchInitialData = useCallback(async ({ page, size }) => {
    try {
      const res = await requestListUseCases.getTeacherRequestList(page, size);
      return res;
    } catch (e) {
      return [];
    }
  }, []);

  const handleKeyId = row => row?.requestId;

  const [Pagination, rows, refresh] = usePagination({
    fetchData: fetchInitialData,
    onKeyId: handleKeyId
  });

  const handleDelete = useCallback(async () => {
    try {
      await requestListUseCases.deleteRequest(itemRequestId);
      setModalConfirmationOpen(false);
      notificationUseCases.success('DELETED_SUCCESS');
    } catch (error) {
      console.error(error);
    } finally {
      refresh();
    }
  }, [itemRequestId, refresh]);

  return (
    <Container>
      <ConfirmDialog
        open={modalConfirmationOpen}
        onConfirm={handleDelete}
        onCancel={() => setModalConfirmationOpen(false)}
      >
        <Typography variant="h5" style={{ marginBottom: '12px' }}>
          {translate('MODAL_DIALOG.TITLE')}
        </Typography>
        <Typography variant="body" style={{ marginBottom: '24px' }}>
          {translate('MODAL_DIALOG.DESCRIPTION_1')}
          <br />
          {translate('MODAL_DIALOG.DESCRIPTION_2')}
        </Typography>
      </ConfirmDialog>
      <Typography variant="h4" style={{ marginBottom: '24px' }}>
        {translate('EDUCATION_UNIT')}
      </Typography>
      <Grid container spacing={3} mt={0} mb={8}>
        <Grid container item justifyContent="flex-end">
          <IconButton
            color="primary"
            aria-label="add"
            onClick={() => history.push('/new-request')}
          >
            <Add />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Pagination>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {translate('EDUCATION_UNIT')}
                </TableCell>
                <TableCell align="left">{translate('UF')}</TableCell>
                <TableCell align="left">{translate('CITY')}</TableCell>
                <TableCell align="left">
                  {translate('STAGE_EDUCATION')}
                </TableCell>
                <TableCell align="left">{translate('STATUS')}</TableCell>
                <TableCell align="right">
                  {translate('COMMONS:ACTIONS')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.requestId}>
                  <TableCell align="left">{row.schoolName}</TableCell>
                  <TableCell align="left">{row.stateName}</TableCell>
                  <TableCell align="left">{row.cityName}</TableCell>
                  <TableCell align="left">
                    {row.learningLevel.description}
                  </TableCell>
                  <TableCell align="left">{translate(row.status)}</TableCell>
                  <TableCell align="left">
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setItemRequesId(row.requestId);
                        setModalConfirmationOpen(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Pagination>
        </Grid>
      </Grid>
    </Container>
  );
};

export default wrapper(RequestList, {
  namespace: 'REQUEST_LIST',
  route: {
    path: REQUEST_LIST_ROUTES.REQUEST_LIST,
    breadcrumb: 'REQUEST_LIST'
  }
});
