/* eslint-disable react/prop-types */
import React from 'react';

import { get } from 'lodash';

import { FormControl, FormHelperText, FormLabel } from '@mui/material';

import { useLangContext } from 'components/Globalization';

const FormControlWrapper = ({
  formMethods,
  name,
  label,
  helperText: helperTextProp,
  children
}) => {
  const translate = useLangContext();
  const { errors } = formMethods;
  const error = get(errors, name);

  let hasError = false;
  let helperText = helperTextProp || '';
  if (error) {
    hasError = true;
    helperText =
      typeof error.message === 'string'
        ? translate(error.message)
        : translate(error.message.key, error.message.params);
  }

  return (
    <FormControl required error={hasError} component="fieldset">
      {label ? <FormLabel component="legend">{label}</FormLabel> : null}
      {children}
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

export default FormControlWrapper;
