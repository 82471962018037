import React, { useLayoutEffect, useRef, useState } from 'react';

const ContainerSize = ({ children }) => {
  const containerRef = useRef();
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(containerRef.current?.clientWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      {children(width)}
    </div>
  );
};

export default ContainerSize;
