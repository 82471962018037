import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Container, Grid, Typography } from '@mui/material';

import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import SelectField from 'components/SelectField';
import wrapper from 'components/Wrapper';

import { notificationUseCases } from 'modules/Notification/providers';
import { REQUEST_LIST_ROUTES } from 'modules/RequestList/constants/routes';
import { requestListUseCases } from 'modules/RequestList/provider';
import { ROLES } from 'modules/User/constants/roles';

import { schema } from './schema';
import { Box } from './styles';

const NewRequest = ({ user }) => {
  const translate = useLangContext();
  const history = useHistory();
  const [ufOptions, setUfOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [educationalInstitutionOptions, setEducationalInstitutionOptions] =
    useState([]);
  const [learningLevelOptions, setLearningLevelOptions] = useState([]);

  const formMethods = useForm({
    resolver: yupResolver(schema)
  });

  const { control } = formMethods;

  const fetchUf = useCallback(async () => {
    try {
      const response = await requestListUseCases.getStateList();
      setUfOptions(response);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const {
    uf: watchUf,
    city: watchCity,
    educationalInstitution: watchEducationalInstitution
  } = useWatch({
    control,
    name: ['uf', 'city', 'educationalInstitution']
  });

  const fetchCity = useCallback(async () => {
    try {
      const { initials } = ufOptions.find(item => item.id === watchUf);
      const response = await requestListUseCases.getCityList(initials);
      setCityOptions(response);
    } catch (e) {
      console.error(e);
    }
  }, [ufOptions, watchUf]);

  const fetchEducationalInstitutionOptions = useCallback(async () => {
    try {
      const response = await requestListUseCases.getSchoolList(watchCity);
      setEducationalInstitutionOptions(
        response.map(({ inep, schoolName }) => ({ id: inep, name: schoolName }))
      );
    } catch (error) {
      console.error(error);
    }
  }, [watchCity]);

  const fetchLearningLevelOptions = useCallback(async () => {
    try {
      const response = await requestListUseCases.getLearningLevelList(
        watchEducationalInstitution
      );
      setLearningLevelOptions(
        response.map(({ id, description }) => ({ id, name: description }))
      );
    } catch (error) {
      console.error(error);
    }
  }, [watchEducationalInstitution]);

  const handleSubmit = async formData => {
    const payload = {
      cpf: user.cpf,
      email: user.email,
      name: user.name,
      profile: ROLES.ROLE_TEACHER,
      request: {
        educationalInstitution: formData.educationalInstitution,
        learningLevel: formData.learningLevel
      }
    };

    try {
      await requestListUseCases.postTeacherRequest(payload);
      notificationUseCases.success('WILL_RECEIVE_AN_EMAIL');
      history.goBack();
    } catch (error) {
      if (error.response.status === 404) {
        notificationUseCases.error('INSTITUTION_NOT_FOUND', {
          namespace: 'ERROR'
        });
      } else if (error.response.status === 409) {
        notificationUseCases.error('ACCESS_REQUEST_ALREADY', {
          namespace: 'ERROR'
        });
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchUf();
  }, [fetchUf]);

  useEffect(() => {
    if (watchUf) fetchCity();
  }, [fetchCity, watchUf]);

  useEffect(() => {
    if (watchCity) fetchEducationalInstitutionOptions();
  }, [fetchEducationalInstitutionOptions, watchCity]);

  useEffect(() => {
    if (watchEducationalInstitution) fetchLearningLevelOptions();
  }, [fetchLearningLevelOptions, watchEducationalInstitution]);

  return (
    <Container>
      <Typography variant="h4" style={{ marginBottom: '24px' }}>
        {translate('TITLE')}
      </Typography>
      <Typography variant="body" style={{ marginBottom: '24px' }}>
        {translate('DESCRIPTION')}
      </Typography>
      <Form
        formMethods={formMethods}
        onSubmit={handleSubmit}
        style={{ marginBottom: '24px' }}
      >
        <Grid container spacing={3}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <SelectField
                  name="uf"
                  idKey="id"
                  label={translate('UF')}
                  placeholder={translate('COMMONS:SELECT')}
                  displayEmpty
                  options={ufOptions}
                  formMethods={formMethods}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  name="city"
                  idKey="id"
                  label={translate('CITY')}
                  placeholder={translate('COMMONS:SELECT')}
                  displayEmpty
                  options={cityOptions}
                  formMethods={formMethods}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  name="educationalInstitution"
                  idKey="id"
                  label={translate('SCHOOL')}
                  placeholder={translate('COMMONS:SELECT')}
                  displayEmpty
                  options={educationalInstitutionOptions}
                  formMethods={formMethods}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  name="learningLevel"
                  idKey="id"
                  label={translate('STAGE_EDUCATION')}
                  placeholder={translate('COMMONS:SELECT')}
                  displayEmpty
                  options={learningLevelOptions}
                  formMethods={formMethods}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid container item justifyContent="flex-end">
          <Button variant="outlined" onClick={() => history.goBack()}>
            {translate('COMMONS:BACK')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{ marginLeft: '12px' }}
          >
            {translate('SEND_REQUEST')}
          </Button>
        </Grid>
      </Form>
    </Container>
  );
};

export default wrapper(NewRequest, {
  namespace: 'NEW_REQUEST',
  mapState: state => ({
    user: state.auth.user
  }),
  route: {
    path: REQUEST_LIST_ROUTES.NEW_REQUEST,
    breadcrumb: 'NEW_REQUEST'
  }
});
