import styled from 'styled-components';

import { Box, ListItemText, MenuItem, Typography } from '@mui/material';

export const SelectAllBox = styled(Box)`
  span {
    margin-left: 5px;
    font-size: 0.875rem;
    ~ span {
      margin-left: 0;
    }
  }
`;

export const CustomListItemText = styled(ListItemText)`
  padding: 6px 4px 4px;
  white-space: normal;
  margin-top: ${props => props.marginTop || '0px'};
  flex: ${props => props.flex || '1'};
`;

export const CustomBox = styled(Box)`
  width: 100%;
  height: 1px;
  background: #a0a0a0;
  margin: 8px 0px 16px 0px;
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  align-items: flex-start;
`;

export const StyledFirstName = styled(Typography)`
  font-size: 16px;
`;

export const StyledSecondName = styled(Typography)`
  font-size: 12px;
  color: #7c7d7c;
`;
