import styled from 'styled-components';

import { Typography, Container } from '@mui/material';

export const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    background-color: ${props => props.theme.palette.background.white};
    box-shadow: 0px 1px 6px rgba(51, 51, 51, 0.16);
    max-width: 460px;
    margin-bottom: 40px;
    padding: 32px;
  }
  .MuiInputLabel-root {
    transform: translate(0, -2px);
    font-size: 14px;
    font-weight: 700;
    color: ${props => props.theme.palette.text.dark};
  }
`;

export const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 700;
    margin-bottom: 25px;
  }
`;

export const UserName = styled(Typography)`
  &.MuiTypography-root {
    font-size: 34px;
    margin-bottom: 44px;
    text-align: left;
    line-height: 40px;
    width: 462px;

    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 30px;
      width: 100%;
      text-align: center;
    }
  }
`;

export const StyledImg = styled.img`
  max-width: 128px;
  height: auto;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 7px;
  }
`;

export const Subtitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    color: ${props => props.theme.palette.text.gray};
  }
`;

export const BoxTooltip = styled.div`
  background: ${props => props.theme.palette.background.blue};
  padding: 15px;
  border-radius: 8px;
  min-height: 76px;
  position: relative;
  margin: 0 0 0 20px;
  box-shadow: 9px 9px 6px rgba(0, 0, 0, 0.16);
  max-width: 620px;
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid ${props => props.theme.palette.background.blue};
    position: absolute;
    left: -8px;
    top: 50%;
    margin-top: -4px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 30px;
    width: 100%;
    text-align: center;
  }
`;
