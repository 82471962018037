/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Redirect } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@mui/material';

import { AsyncLoad } from 'components/AsyncLoad';
import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import PublicContainer from 'components/PublicContainer';
import SelectField from 'components/SelectField';
import TextField from 'components/TextField';
import wrapper from 'components/Wrapper';

import { APP_ROUTES } from 'modules/App/constants/route';
import { authUseCases } from 'modules/Auth/providers';
import { USER_ROUTES } from 'modules/User/constants/routes';
import { userService } from 'modules/User/providers';

import formSchema from './formSchema';

const SelectSecurityQuestions = ({
  isAuthenticated,
  securityQuestionsAnswered
}) => {
  const translate = useLangContext();

  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [firstSecurityQuestions, setFirstSecurityQuestions] = useState([]);
  const [secondSecurityQuestions, setSecondSecurityQuestions] = useState([]);

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(formSchema),
    validationContext: {
      abortEarly: false
    }
  });

  const { control } = formMethods;

  const { firstQuestion, secondQuestion } = useWatch({
    control,
    name: ['firstQuestion', 'secondQuestion'],
    defaultValues: {
      firstQuestion: '',
      secondQuestion: ''
    }
  });

  useEffect(() => {
    if (securityQuestions.length) {
      setFirstSecurityQuestions(
        securityQuestions.filter(item => !item.id || item.id !== secondQuestion)
      );

      setSecondSecurityQuestions(
        securityQuestions.filter(item => !item.id || item.id !== firstQuestion)
      );
    }
  }, [securityQuestions, firstQuestion, secondQuestion]);

  const securityQuestionsPromise = useCallback(async () => {
    const questions = await userService.securityQuestions();

    const options = questions.map(({ id, question }) => ({
      id,
      name: question
    }));

    options.unshift({ id: '', name: translate('SELECT_QUESTIONS') });

    setSecurityQuestions(options);
  }, [translate]);

  const onSubmitHandler = useCallback(async formData => {
    const payload = [
      {
        questionId: formData.firstQuestion,
        answer: formData.firstQuestionAnswer
      },
      {
        questionId: formData.secondQuestion,
        answer: formData.secondQuestionAnswer
      }
    ];

    await userService.securityQuestionsAnswers(payload);

    await authUseCases.updateCurrentUserInformation();
  }, []);

  if (securityQuestionsAnswered || !isAuthenticated) {
    return <Redirect to={APP_ROUTES.BASE_ROUTE} />;
  }

  return (
    <PublicContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" color="primary">
            {translate('SECURITY_QUESTIONS')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" paragraph>
            {translate('SELECT_QUESTIONS_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" paragraph>
            {translate('HINT_TEXT')}
          </Typography>
        </Grid>
      </Grid>

      <AsyncLoad promiseFn={securityQuestionsPromise}>
        <Form onSubmit={onSubmitHandler} formMethods={formMethods}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectField
                name="firstQuestion"
                label={translate('FIRST_QUESTION')}
                options={firstSecurityQuestions}
                formMethods={formMethods}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="firstQuestionAnswer"
                label={translate('ANSWER')}
                placeholder={translate('ANSWER_PLACEHOLDER')}
                formMethods={formMethods}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectField
                name="secondQuestion"
                label={translate('SECOND_QUESTION')}
                options={secondSecurityQuestions}
                formMethods={formMethods}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="secondQuestionAnswer"
                label={translate('ANSWER')}
                placeholder={translate('ANSWER_PLACEHOLDER')}
                formMethods={formMethods}
              />
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Grid item>
                <Button type="submit">{translate('SAVE')}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </AsyncLoad>
    </PublicContainer>
  );
};

export default wrapper(SelectSecurityQuestions, {
  namespace: 'SELECT_SECURITY_QUESTIONS',
  mapState: state => ({
    securityQuestionsAnswered: state.auth.securityQuestionsAnswered,
    isAuthenticated: state.auth.isAuthenticated
  }),
  route: {
    path: USER_ROUTES.SELECT_SECURITY_QUESTIONS
  }
});
