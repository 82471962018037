import { http } from 'adapters';
import store from 'framework/store/store';

import RequestListService from './requestList.service';
import RequestListUseCases from './requestList.useCases';

export const requestListService = new RequestListService({ http });

export const requestListUseCases = new RequestListUseCases({
  store,
  requestListService
});
