export const AUTH_ROUTES = {
  LOGIN: '/login',
  TOKEN: '/token',
  LOGOUT: '/logout',
  MFA_SETUP: '/mfa-setup',
  MFA_TOKEN: '/mfa-token',
  VERIFY_CPF: '/verify',
  SUCCESS_MESSAGE: '/success',
  BOOK_PASSWORD: '/book-password',
  TERM_OF_CONSENTIMENT: '/term-of-consentiment'
};
