export default class RequestAccessUseCases {
  /**
   * @param {object} props
   * @param {import('./requestAccess.service').default} props.requestAccessService
   */
  constructor({ requestAccessService }) {
    this.requestAccessService = requestAccessService;
  }

  async getCheckTeacherAccessRequest(cpf, inep, learningLevel) {
    return this.requestAccessService.getCheckTeacherAccessRequest(
      cpf,
      inep,
      learningLevel
    );
  }

  async getStateList() {
    return this.requestAccessService.getStateList();
  }

  async getCityList(initials) {
    return this.requestAccessService.getCityList(initials);
  }

  async getSchoolList(ibge) {
    return this.requestAccessService.getSchoolList(ibge);
  }

  async getLearningLevelList(inep) {
    return this.requestAccessService.getLearningLevelList(inep);
  }

  async postTeacherRequest(payload) {
    return this.requestAccessService.postTeacherRequest({
      ...payload
    });
  }

  async postStudens(payload) {
    return this.requestAccessService.postStudens({
      ...payload
    });
  }

  async teacherRequestAccess() {
    return this.requestAccessService.teacherRequestAccess();
  }
}
