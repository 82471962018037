import { formatString } from 'helpers/formatString';

import { REQUEST_ACCESS } from '../constants/api';

export default class AddressService {
  /**
   * @param {object} props
   * @param {import('adapters/axios').Axios} props.http
   */

  constructor({ http }) {
    this.http = http;
  }

  async getCheckTeacherAccessRequest(cpf, inep, learningLevel) {
    const url = formatString(REQUEST_ACCESS.GET_CHECK_TEACHER_ACCESS_REQUEST, {
      cpf,
      inep,
      learningLevel
    });
    return this.http.get(url, {
      skipErrorHandling: true
    });
  }

  async getStateList() {
    return this.http.get(REQUEST_ACCESS.GET_STATE_LIST);
  }

  async getCityList(initials) {
    const url = formatString(REQUEST_ACCESS.GET_CITY_LIST, {
      initials
    });
    return this.http.get(url);
  }

  async getSchoolList(ibge) {
    const url = formatString(REQUEST_ACCESS.GET_SCHOOL_LIST, {
      ibge
    });
    return this.http.get(url);
  }

  async getLearningLevelList(inep) {
    const url = formatString(REQUEST_ACCESS.GET_LEARNING_LEVEL_LIST, {
      inep
    });
    return this.http.get(url);
  }

  async postTeacherRequest(payload) {
    return this.http.post(REQUEST_ACCESS.POST_TEACHER_REQUEST, payload);
  }

  async postStudens(payload) {
    return this.http.post(REQUEST_ACCESS.POST_SELECT_PROFILE, payload, {
      skipErrorHandling: true
    });
  }

  async teacherRequestAccess() {
    return this.http.get(REQUEST_ACCESS.TEACHER_REQUEST_ACCESS);
  }
}
