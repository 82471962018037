import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { MoreVert } from '@mui/icons-material';
import { Button, Hidden, Menu, MenuItem, useMediaQuery } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useTheme } from '@mui/material/styles';

import { useLangContext } from 'components/Globalization';
import { useThemeContext } from 'components/Theme';
import wrapper from 'components/Wrapper';

import logoPnld from 'assets/img/logo-pnld.png';

import ModuleLink from './fragments/ModuleLink';
import PrivateMenu from './fragments/PrivateMenu';
import ProfileHeaderMenu from './fragments/ProfileHeaderMenu';
import Shortcut from './fragments/Shortcut';
import {
  BrandContainer,
  ButtonContrast,
  Header,
  LinksContainer,
  MenuContainer,
  MenuOptions,
  TitlePage,
  TitleSecondary
} from './style';

const PATH_LINKS = [
  {
    pathname: 'https://www4.planalto.gov.br/legislacao/',
    label: 'LEGISLATION'
  },
  {
    pathname: 'https://www.gov.br/acessoainformacao/pt-br',
    label: 'ACCESS_OF_INFORMATION'
  },
  {
    pathname: 'https://www.gov.br/governodigital/pt-br/acessibilidade-digital',
    label: 'ACCESSIBILITY'
  }
];

const PrivateHeader = ({ menus, links, location, user, userGovBr }) => {
  const { toggleType } = useThemeContext();
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const theme = useTheme();

  const ISNT_MOBILE = useMediaQuery(theme.breakpoints.up('sm'));

  const translate = useLangContext();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActiveLocation = path => {
    if (location) {
      return location.pathname.startsWith(path) ? 'true' : undefined;
    }
    return false;
  };

  const onCloseMenuClickHandler = useCallback(() => {
    setShow(false);
  }, []);

  const validateUserInfo = () => {
    const { cpf, email, name } = user;

    return cpf && email && name;
  };
  return (
    <>
      <PrivateMenu show={show} menus={menus} close={onCloseMenuClickHandler} />
      <Header>
        <BrandContainer to={{ pathname: '/' }}>
          <img src={logoPnld} width={80} alt="Logo PNLD" />
          <Hidden mdDown>
            <TitlePage>{translate('TITLE')}</TitlePage>
          </Hidden>
        </BrandContainer>
        <MenuContainer>
          <LinksContainer>
            {ISNT_MOBILE ? (
              <>
                {PATH_LINKS.map(link => (
                  <Link
                    key={link.pathname}
                    to={{ pathname: link.pathname }}
                    target="_blank"
                  >
                    {translate(link.label)}
                  </Link>
                ))}
              </>
            ) : (
              <>
                <Button
                  id="fade-button"
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <MoreVert color="secondary" />
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button'
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  {PATH_LINKS.map(link => (
                    <MenuItem key={link.pathname} onClick={handleClose}>
                      <Link to={{ pathname: link.pathname }} target="_blank">
                        {translate(link.label)}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </LinksContainer>
          <Shortcut />
          <ButtonContrast onClick={toggleType} color="secondary" />
          <MenuOptions>
            {links.map(link => (
              <ModuleLink
                key={link.to}
                to={link.to}
                active={isActiveLocation(link.to)}
                label={translate(link.label)}
              />
            ))}
          </MenuOptions>

          <ProfileHeaderMenu user={validateUserInfo() ? user : userGovBr} />
        </MenuContainer>
        <TitleSecondary>{translate('SUB_TITLE')}</TitleSecondary>
      </Header>
    </>
  );
};

export default wrapper(PrivateHeader, {
  namespace: 'PRIVATE_HEADER',
  mapState: state => ({
    user: state.auth.user,
    userGovBr: state.auth.userGovBr
  })
});
