import React, { useCallback, useMemo, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  matchPath
} from 'react-router-dom';

import { AsyncLoad } from 'components/AsyncLoad';
import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_ROUTES } from './constants/routes';
import { RequestAccessContext } from './Context';
import { requestAccessUseCases } from './provider';
import IdentifyUser from './submodules/IdentifyUser';
import RequestAccessTeacher from './submodules/RequestAccessTeacher';
import RequestCompleted from './submodules/RequestCompleted';
import SchoolManagement from './submodules/SchoolManagement';
import SelectProfile from './submodules/SelectProfile';
import SuccessMessage from './submodules/SuccessMessage';
import TermsOfUse from './submodules/TermsOfUse';

const routes = [
  {
    component: TermsOfUse,
    getParams: ({ termVersion, needsSign }) => ({ termVersion, needsSign })
  },
  {
    component: SelectProfile
  },
  {
    component: IdentifyUser
  },
  {
    component: RequestAccessTeacher
  },
  {
    component: RequestCompleted
  },
  {
    component: SchoolManagement
  },
  {
    component: SuccessMessage
  }
];

const termVersion = '01002024';

const RequestAccess = () => {
  const [loading, setLoading] = useState(true);
  const [termsOfUseNeedsSign, setTermsOfUseNeedsSign] = useState({});
  const [requestData, setRequestData] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const { pathname } = useLocation();

  const checkTermsOfUseUpdated = useCallback(async () => {
    setLoading(true);

    try {
      const resp = await requestAccessUseCases.checkTermsOfUseUpdated({
        termVersion
      });
      const { needSign, needUpdateTermsAndUse } = resp;

      setTermsOfUseNeedsSign({ needSign, needUpdateTermsAndUse });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const RedirectToTerms = useMemo(() => {
    const isPathTermsOfUse = matchPath(pathname, {
      path: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS_V2
    });
    const hasTermsOfUsePendency =
      termsOfUseNeedsSign.needSign || termsOfUseNeedsSign.needUpdateTermsAndUse;

    if (!loading && isPathTermsOfUse?.isExact && !hasTermsOfUsePendency) {
      return <Redirect to={SelectProfile.path} />;
    }

    if (!loading && isPathTermsOfUse?.isExact && hasTermsOfUsePendency) {
      return <Redirect to={TermsOfUse.path} />;
    }

    return null;
  }, [
    loading,
    pathname,
    termsOfUseNeedsSign.needSign,
    termsOfUseNeedsSign.needUpdateTermsAndUse
  ]);

  return (
    <RequestAccessContext.Provider
      value={{ requestData, setRequestData, isCreating, setIsCreating }}
    >
      <AsyncLoad promiseFn={checkTermsOfUseUpdated}>
        <Switch>
          {RedirectToTerms}

          {routes?.map(route => (
            <Route
              path={route.component.path}
              exact
              key={route.component.path}
              render={() => (
                <route.component.Component
                  {...(route.getParams?.({
                    termVersion,
                    needsSign: termsOfUseNeedsSign
                  }) ?? {})}
                />
              )}
            />
          ))}
        </Switch>
      </AsyncLoad>
    </RequestAccessContext.Provider>
  );
};

export default wrapper(RequestAccess, {
  namespace: 'REQUEST_ACCESS',
  route: {
    path: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS_V2,
    exact: false,
    breadcrumb: 'REQUEST_ACCESS'
  }
});
