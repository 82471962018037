import { http } from 'adapters';

import DownloadWorkService from './downloadWork.service';
import DownloadWorkUseCases from './downloadWork.useCases';

export const downloadWorkService = new DownloadWorkService({ http });

export const downloadWorkUseCases = new DownloadWorkUseCases({
  downloadWorkService
});
