import React, { useContext } from 'react';

export const StepperContext = React.createContext({
  fnNext: () => {},
  fnBack: () => {},
  fnReset: () => {},
  stepperData: {},
  fnSetData: () => {},
  fnSetDataByKey: () => {},
  setActiveStep: () => {}
});

export const useStepperContext = () => useContext(StepperContext);
