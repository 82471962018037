import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useController } from 'react-hook-form';

import PropTypes from 'prop-types';

import { useLangContext } from 'components/Globalization';

import { Container, ErrorsContainer } from './style';

const ReCAPTCHAInput = React.forwardRef(
  ({ formMethods, name, defaultValue }, ref) => {
    const translate = useLangContext();

    const { control, errors } = formMethods;
    const {
      field: { ref: controllerRef, onChange: handleChange, onBlur: handleBlur }
    } = useController({
      name,
      control,
      defaultValue
    });

    return (
      <Container>
        <ReCAPTCHA
          ref={newRef => {
            ref.current = newRef;
            controllerRef.current = newRef;
          }}
          sitekey={window.env.REACT_APP_RECAPTCHA_KEY_V2}
          onChange={token => {
            handleChange(token);
            handleBlur(token);
          }}
          hl="pt-BR"
        />

        <ErrorsContainer>
          {errors[name] ? translate(errors[name].message) : ''}
        </ErrorsContainer>
      </Container>
    );
  }
);

ReCAPTCHAInput.defaultProps = {
  name: 'recaptcha',
  defaultValue: ''
};

ReCAPTCHAInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formMethods: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string,
  defaultValue: PropTypes.string
};
export default ReCAPTCHAInput;
