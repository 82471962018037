import styled from 'styled-components';

import { Button, Card as MUICard, Typography } from '@mui/material';
import { styled as MUIStyled } from '@mui/system';

export const Card = MUIStyled(MUICard)`
	background-color: ${props => props.theme.palette.background.light};
	display: flex;
	flex-direction: column;
	height: 100%;
  width: 100%;
	cursor: pointer;
	box-shadow: none;
`;

export const Component = styled.div`
  border-radius: 4px;
  color: ${props => props.theme.palette.text.light};
  display: block;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 24px;
  min-height: 24px;
  text-align: center;
  width: 100%;
`;

export const Cover = styled.img`
  border: 1px solid ${props => props.theme.palette.border.primary};
  border-radius: 8px;
  display: block;
  width: 100%;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    color: ${props => props.theme.palette.primary.main};
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
  }
`;

export const ButtonContent = styled.div`
  align-items: center;
  align-self: end;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CustomButton = styled(Button)`
  padding: 5px 55px !important;
`;
