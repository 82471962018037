import React from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Container, Grid } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import completed from 'assets/img/completed.png';

import { REQUEST_ACCESS_ROUTES } from '../../constants/routes';
import { useRequestAccessContext } from '../../Context';
import {
  ButtonDoneContained,
  ButtonDoneOutlined,
  ButtonRequestCompletedWrapper,
  RequestCompletedDescription,
  RequestCompletedInfo,
  RequestCompletedTitle,
  SuccessRequestImage
} from './styles';

const RequestCompleted = () => {
  const history = useHistory();

  const { setRequestData, setIsCreating } = useRequestAccessContext();

  const translate = useLangContext();

  const handleGoToSchoolManagement = () => {
    history.push(REQUEST_ACCESS_ROUTES.SCHOOL_MANAGEMENT);
  };

  const handleGoToRequestTeacher = () => {
    history.push(REQUEST_ACCESS_ROUTES.IDENTIFY_USER);
    setRequestData(null);
    setIsCreating(true);
  };

  return (
    <Container>
      <Grid
        justifyContent="center"
        display="flex"
        container
        spacing={3}
        py={3}
        mt={4}
      >
        <Grid item>
          <SuccessRequestImage alt={translate('TITLE')} src={completed} />
        </Grid>
        <Grid item>
          <RequestCompletedTitle>{translate('TITLE')}</RequestCompletedTitle>
          <RequestCompletedDescription>
            {translate('SUBTITLE')}
          </RequestCompletedDescription>
          <RequestCompletedInfo>{translate('MESSAGE')}</RequestCompletedInfo>

          <ButtonRequestCompletedWrapper item justifyContent="center">
            <ButtonDoneOutlined
              sx={{
                fontWeight: 600,
                padding: '6px 24px'
              }}
              variant="outlined"
              onClick={handleGoToSchoolManagement}
            >
              {translate('BUTTON')}
            </ButtonDoneOutlined>
            <ButtonDoneContained
              sx={{
                fontWeight: 600,
                padding: '6px 24px'
              }}
              variant="contained"
              onClick={handleGoToRequestTeacher}
            >
              <AddIcon />
              {translate('BUTTON_ADD_TEACHER')}
            </ButtonDoneContained>
          </ButtonRequestCompletedWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default wrapper(RequestCompleted, {
  namespace: 'REQUEST_COMPLETED',
  route: { path: REQUEST_ACCESS_ROUTES.REQUEST_COMPLETED }
});
