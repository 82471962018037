import LiteraryDidacticWorksListTab from 'modules/App/LiteraryDidacticWorksList';
import DownloadWork from 'modules/DownloadWork';
import InitialPage from 'modules/InitialPage';
import LiteraryDidacticWorks from 'modules/LiteraryDidacticWorks';
import LiteraryDidacticWorksDetail from 'modules/LiteraryDidacticWorks/submodules/LiteraryDidacticWorksDetail';
import RequestAccess from 'modules/RequestAccess';
import RequestList from 'modules/RequestList';
import NewRequest from 'modules/RequestList/submodules/NewRequest';
import StepPasswordBook from 'modules/StepPasswordBook';
import ConfirmUpdate from 'modules/UpdateRegister/submodules/ConfirmUpdate';
import Register from 'modules/UpdateRegister/submodules/UpdateRegister';
import V2_RequestAccess from 'modules/V2_RequestAccess';

export const adminRouteProvider = {
  routes: [InitialPage],
  links: [],
  menus: []
};

export const teacherRouteProvider = {
  routes: [
    LiteraryDidacticWorks,
    LiteraryDidacticWorksListTab,
    LiteraryDidacticWorksDetail,
    RequestList,
    NewRequest,
    DownloadWork,
    Register,
    ConfirmUpdate,
    V2_RequestAccess
  ],
  links: [],
  menus: []
};

export const studentRouteProvider = {
  routes: [
    LiteraryDidacticWorks,
    LiteraryDidacticWorksListTab,
    LiteraryDidacticWorksDetail,
    DownloadWork,
    Register,
    ConfirmUpdate,
    V2_RequestAccess
  ],
  links: [],
  menus: []
};

export const defaultRouteProvider = {
  routes: [V2_RequestAccess, RequestAccess, StepPasswordBook],
  links: [],
  menus: []
};
