import { http } from 'adapters';
import store from 'framework/store/store';

import LiteraryDidacticWorksService from './literaryDidacticWorks.service';
import LiteraryDidacticWorksUseCases from './literaryDidacticWorks.useCases';

export const literaryDidacticWorksService = new LiteraryDidacticWorksService({
  http
});

export const literaryDidacticWorksUseCases = new LiteraryDidacticWorksUseCases({
  store,
  literaryDidacticWorksService
});
