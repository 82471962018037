import React from 'react';
import { useController } from 'react-hook-form';

import PropTypes from 'prop-types';

import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Switch as MuiSwitch,
  Typography
} from '@mui/material';

const compoenentType = {
  checkbox: MuiCheckbox,
  switch: MuiSwitch
};
function Checkbox({
  type,
  label,
  formMethods,
  name,
  defaultValue,
  disabled,
  id,
  onChange: onChangeProp = () => {},
  ...rest
}) {
  const { control } = formMethods;

  const {
    field: { ref, value, onChange, ...inputProps }
  } = useController({
    name,
    control,
    defaultValue
  });

  const Component = compoenentType[type] || MuiCheckbox;

  return (
    <FormControlLabel
      {...inputProps}
      {...rest}
      name={name}
      label={<Typography variant="body2">{label}</Typography>}
      value={value}
      defaultValue={defaultValue}
      onChange={e => {
        onChangeProp(e);
        onChange(e.target.checked);
      }}
      control={
        <Component id={id || name} checked={value} inputRef={ref} {...rest} />
      }
      disabled={disabled}
    />
  );
}

Checkbox.defaultProps = {
  label: '',
  type: 'checkbox',
  defaultValue: false,
  disabled: false,
  id: undefined
};

Checkbox.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  formMethods: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string
};

export default Checkbox;
