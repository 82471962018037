import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';

import { authActions } from 'framework/store/slices/auth.slice';

import history from '../../../framework/history';
import { AUTH_ROUTES } from '../../../modules/Auth/constants/routes';

export default class AuthUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('services/providers/user/service').default} props.userService
   * @param {import('services/providers/auth/service').default} props.authService
   */
  constructor({ store, authService, userService }) {
    this.store = store;
    this.authService = authService;
    this.userService = userService;
    this.GOV_BR_PROVIDER_ID = 'Gov.BR';
  }

  async login({ username, password }) {
    const user = await this.authService.signIn(username, password);

    if (user.challengeName) {
      await this.store.dispatch(authActions.challengeUser(user));
    } else {
      await this.signIn(user.getSignInUserSession());
    }

    return user;
  }

  async accessTokenLogin(jwt, token, username) {
    const { user, session } = await this.authService.accessTokenLogin(
      jwt,
      token,
      username
    );
    await this.signIn(session);
    return { user, session };
  }

  async signIn(session) {
    if (!session) return;

    try {
      this.authService.unsetMeFailedFlag();
      this.store.dispatch(authActions.meFailed(false));
      const user = await this.userService.me();
      this.store.dispatch(authActions.signIn({ user, session }));
    } catch (error) {
      const currentUser = await Auth.currentAuthenticatedUser();
      this.store.dispatch(
        authActions.setUserGovBr({
          cpf: currentUser.username,
          email: currentUser.attributes.email,
          name: currentUser.attributes.name
        })
      );
      this.store.dispatch(authActions.signIn({ user: {}, session }));
    }
  }

  async getMe() {
    const { isAuthenticated } = this.store.getState().auth;

    if (isAuthenticated) {
      try {
        this.authService.unsetMeFailedFlag();
        this.store.dispatch(authActions.meFailed(false));
        return await this.userService.me();
      } catch (error) {
        this.authService.setMeFailedFlag();
        this.store.dispatch(authActions.meFailed(true));
        await this.checkGovBrSession();
        return null;
      }
    }

    return null;
  }

  async updateCurrentUserInformation() {
    const user = await this.getMe();
    if (user) this.store.dispatch(authActions.me(user));
  }

  async signOut(redirect = false) {
    const session = await this.authService.restoreUserSession();

    if (session) {
      if (this.getAuthProvider(session) === this.GOV_BR_PROVIDER_ID) {
        await this.authService.govBrSignOut();
      } else {
        this.authService.unsetIsWaitingGovBrAuthLogout();
        this.store.dispatch(authActions.isWaitingGovBrLogout(false));
      }

      await this.authService.awsLogout();
    }

    this.authService.clearAuthStorage();

    this.store.dispatch(authActions.signOut());

    if (redirect) {
      history.push(AUTH_ROUTES.LOGIN);
    }
  }

  async restoreCurrentSession() {
    const session = await this.authService.restoreUserSession();
    if (session) await this.signIn(session);
    else this.signOut();
  }

  async forceLogout() {
    this.store.dispatch(authActions.signOut());
  }

  async checkGovBrSession() {
    const session = await this.authService.restoreUserSession();

    if (session) {
      if (this.getAuthProvider(session) === this.GOV_BR_PROVIDER_ID) {
        this.authService.setIsWaitingGovBrAuthLogout();
        this.store.dispatch(authActions.isWaitingGovBrLogout(true));
      } else {
        this.authService.unsetIsWaitingGovBrAuthLogout();
        this.store.dispatch(authActions.isWaitingGovBrLogout(false));
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getAuthProvider(session) {
    const jwtDecoded = jwt_decode(session.idToken.jwtToken);
    const { identities } = jwtDecoded;
    return identities && identities[0] && identities[0].providerName;
  }

  async getFullToken(code) {
    await this.authService.getFullToken(code);
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }
}
