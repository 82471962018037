import styled from 'styled-components';

import { Container } from '@mui/system';

export const StyledContainer = styled(Container)`
  max-width: 90% !important;

  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    max-width: 100% !important;
  }
`;
