import React from 'react';

import PropTypes from 'prop-types';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import newLogoPnld from 'assets/img/new_logo_pnld.png';

import {
  Container,
  ContainerPublic,
  Footer,
  FooterCopy,
  StyledImgFooter
} from './style';

const PublicContainer = ({ children }) => {
  const translate = useLangContext();

  return (
    <ContainerPublic>
      <Container>{children}</Container>

      <Footer>
        <StyledImgFooter src={newLogoPnld} alt={translate('LOGO_PNLD')} />
        <FooterCopy>{translate('COPYRIGHT')}</FooterCopy>
      </Footer>
    </ContainerPublic>
  );
};

PublicContainer.defaultProps = {
  children: null
};

PublicContainer.propTypes = {
  children: PropTypes.node
};

export default wrapper(PublicContainer, {
  namespace: 'PUBLIC_CONTAINER'
});
