import React from 'react';

import { Box, Container, Grid, Typography } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { AUTH_ROUTES } from 'modules/Auth/constants/routes';
import {
  ItemList,
  TextCard
} from 'modules/V2_RequestAccess/submodules/TermsOfUse/styles';

import { ContainerBackground } from './styles';

const TermsOfConsentiment = () => {
  const translate = useLangContext();

  return (
    <Container>
      <Grid container spacing={3} py={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {translate('TERMS_OF_USE_TITLE')}
          </Typography>
        </Grid>
        <Grid borderRadius={12} item xs={12}>
          <ContainerBackground>
            <p>
              Neste documento são apresentados os termos e condições de uso do{' '}
              <b>Portal do Livro Digital do PNLD</b> (“Portal”), disponibilizado
              pelo <b>Fundo Nacional de Desenvolvimento da Educação</b> (FNDE)
              no âmbito de sua competência de operacionalizar o Programa
              Nacional do Livro e do Material Didático (“PNLD”).
            </p>
            <br />
            <p>
              <b>1. CARACTERÍSTICAS GERAIS DO PORTAL. </b>
              O Portal é gerenciado pelo FNDE, autarquia Federal vinculada ao
              Ministério da Educação (MEC) no âmbito da qual é operacionalizado
              o Programa Nacional do Livro e do Material Didático – PNLD.
              <br />
              <br />
              O Portal tem como objetivo disponibilizar materiais do PNLD às
              escolas públicas da educação básica participantes do programa.
              Esses materiais são destinados ao uso de estudantes, professores e
              gestores escolares, de forma gratuita e em formato digital.
              <br />
              <br />
              <b>2. ACESSO E UTILIZAÇÃO DO PORTAL. </b>
              Para a utilização do Portal, é necessária a autenticação do
              usuário via acesso integrado com a plataforma gov.br.
            </p>
            <br />
            <p>O uso do Portal envolve três perfis de usuários:</p>
            <br />
            <ItemList>
              <p>
                <li>
                  <b>Diretor(a): </b>
                  usuário(a) responsável pela aprovação no Portal dos pedidos de
                  inclusão de professores e de estudantes e pelo cadastramento
                  dos estudantes de sua respectiva unidade de ensino.
                </li>

                <li>
                  <b>Professor(a): </b>
                  usuário(a) que, mediante prévio cadastro e aprovação pelo(a)
                  Diretor(a), poderá utilizar o Portal para acessar os materiais
                  do PNLD.
                </li>

                <li>
                  <b>Estudante: </b>
                  usuário(a) que, mediante prévio cadastro pelo(a) representante
                  da unidade de ensino, poderá utilizar o Portal para acessar os
                  materiais do PNLD.
                </li>
              </p>
            </ItemList>
            <br />
            <p>
              Ao utilizar a Plataforma, os usuários assumem responsabilidades
              pelas informações fornecidas na ocasião do cadastro.
            </p>
            <br />
            <p>
              A(s) unidades de ensino, por meio dos seus representantes e
              dirigentes, ficam cientes de que haverá o tratamento de dados
              pessoais de crianças (indivíduos menores de 12 anos, nos termos da
              Lei Federal nº 8.069/1990), e em observância com o art. 14 da Lei
              Federal nº 13.709/2018, será necessária a coleta de consentimento
              específico e destacado de um dos pais ou representante legal das
              crianças, sem prejuízo ao titular condicionado ao melhor interesse
              do indivíduo.
            </p>
            <br />
            <p>
              Em caso de suspeita de uso irregular, inclusive em desacordo com
              as regras trazidas no presente termos de uso, o Usuário
              responsável fica sujeito à suspensão do cadastro, sem que seja
              devida qualquer indenização ou compensação, bem como a demais
              medidas legais. A unidade de ensino na qual está matriculada a
              criança, fica responsável pelo acompanhamento do seu acesso do
              Portal.
            </p>
            <br />
            <p>
              <b>3. DEVERES DOS USUÁRIOS DO PORTAL. </b>O Portal deve ser
              utilizado única e exclusivamente para os propósitos relacionados
              ao acesso dos materiais de apoio à prática educativa oferecidos
              pelo PNLD, sendo vedada sua utilização para finalidades diversas.
            </p>
            <br />
            <p>
              O FNDE não se responsabiliza pelo uso do Portal para finalidades
              distintas daquelas definidas no escopo de suas competências
              relativas ao PNLD.
            </p>
            <br />
            <p>
              Para o bom funcionamento do Portal, os usuários se declaram
              cientes e concordam com os deveres e condições apresentados a
              seguir:
            </p>
            <br />
            <Grid container>
              <Grid item xs={12} lg={6}>
                <TextCard>São deveres dos usuários:</TextCard>
                <Box padding={2}>
                  <p>
                    <ItemList>
                      <li>
                        Garantir a veracidade e legalidade das informações
                        fornecidas no cadastro no Portal;
                      </li>
                      <li>
                        Manter a guarda e a integridade das credenciais de
                        acesso, ciente de que essas são de uso pessoal,
                        intransferível e confidencial;
                      </li>
                      <li>
                        Alterar imediatamente suas credenciais de acesso caso
                        haja suspeita ou confirmação quanto à perda da
                        confidencialidade dessas informações;
                      </li>
                      <li>
                        Reportar qualquer situação irregular no uso do Portal;
                      </li>
                      <li>
                        Utilizar o Portal de acordo com a boa-fé e com a
                        legislação aplicável, inclusive a de proteção de dados
                        pessoais.
                      </li>
                    </ItemList>
                  </p>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextCard>É vedado aos usuários:</TextCard>
                <Box padding={2}>
                  <p>
                    <ItemList>
                      <li>
                        Utilizar credenciais de acesso de outra pessoa,
                        fazendo-se passar por terceiro ou manipulando
                        identificadores;
                      </li>
                      <li>
                        Compartilhar, exibir, circular ou transferir as
                        credenciais de acesso a terceiros;
                      </li>
                      <li>
                        Inserir, compartilhar ou reproduzir qualquer tipo de
                        conteúdo ilegal, ameaçador, fraudulento, difamatório,
                        abusivo, ofensivo, enganoso, discriminatório ou que seja
                        capaz de violar direitos;
                      </li>
                      <li>
                        Interferir no funcionamento operacional do Portal, de
                        seus servidores e/ou de suas redes;
                      </li>
                      <li>
                        Vender, licenciar, copiar, reproduzir, transferir ou
                        aferir qualquer forma de vantagem a partir dos dados e
                        conteúdos disponibilizados no Portal;
                      </li>
                      <li>
                        Introduzir vírus, worms, códigos maliciosos e/ou
                        qualquer tipo de arquivo ou programa que tenha como
                        finalidade prejudicar o funcionamento ou coletar dados
                        do Portal.
                      </li>
                    </ItemList>
                  </p>
                </Box>
              </Grid>
            </Grid>
            <br />
            <br />
            <p>
              Sem prejuízo das medidas cabíveis em âmbito judicial ou
              administrativo, o FNDE poderá bloquear, desativar ou remover,
              temporária ou definitivamente, o perfil de acesso e/ou quaisquer
              conteúdos disponibilizados por usuários, caso identifique o
              descumprimento das condições acima relacionadas ou risco iminente
              de prejuízo ao Portal ou a seus usuários.
            </p>
            <br />
            <p>
              <b>4. DIREITOS AUTORIAIS E CONEXOS. </b>
              Todas as obras e materiais educacionais disponibilizados no Portal
              são destinadas exclusivamente ao uso não comercial, em
              conformidade com as finalidades do Portal e com as disposições
              previstas na Lei Federal n.º 9.610/1998 (Lei de Direitos
              Autorais).
            </p>
            <br />
            <p>
              É vedado aos Usuários reproduzir, publicar, alugar, vender,
              transferir, distribuir, oferecer ou expor qualquer cópia, parcial
              ou integral, de quaisquer dos materiais disponibilizados no
              Portal, ressalvada a utilização no contexto escolar, desde que
              respeitados os limites da legislação aplicável.
            </p>
            <br />
            <p>
              <b>5. SEGURANÇA E DISPONIBILIDADE. </b>Para a disponibilização do
              Portal e manutenção da infraestrutura, sistemas e bases de dados,
              foram adotadas medidas de segurança físicas, eletrônicas e
              operacionais com o objetivo de prevenir a ocorrência de acessos
              não autorizados e/ou situações acidentais ou ilícitas de
              destruição, perda, alteração, comunicação ou difusão, nos termos
              da Política Nacional de Segurança da Informação.
            </p>
            <br />
            <p>
              Ainda assim, não é possível assegurar - de forma absoluta - que o
              Portal se manterá livre de interrupções e/ou ações indevidas por
              parte de terceiros. Nesse sentido, embora o Portal tenha como
              pressuposto a disponibilidade contínua, as funcionalidades podem,
              excepcionalmente, não estar disponíveis de modo ininterrupto,
              sendo possível a ocorrência de instabilidades ou
              indisponibilidades decorrentes de questões diversas de ordem
              técnica, as quais devem ser esperadas pelos usuários, tais como a
              realização de manutenção dos servidores, ajustes emergenciais aos
              sistemas de informação, entre outros.
            </p>
            <br />
            <p>
              <b>6. CONDIÇÕES GERAIS, CANAL DE CONTATO E ATUALIZAÇÕES. </b>
              Em caso de dúvidas, sugestões ou reporte de problemas técnicos
              relacionados ao Portal, o usuário poderá entrar em contato com o
              FNDE por meio do(s) seguinte(s) canal(is): Serviço de Atendimento
              ao Cidadão através do telefone: 0800-616161 ou e-mail:
              ouvidoria@fnde.gov.br
            </p>
            <br />
            <p>
              A concordância com os Termos de Uso é condição necessária para a
              utilização do Portal.
            </p>
            <br />
            <p>
              Estes Termos poderão sofrer atualizações e ajustes a qualquer
              tempo. Caso haja atualização relevante nas condições de uso e
              acesso do Portal, utilizaremos os meios disponíveis para informar
              os usuários quanto ao teor das alterações.
            </p>
          </ContainerBackground>
        </Grid>
      </Grid>
    </Container>
  );
};

export default wrapper(TermsOfConsentiment, {
  namespace: 'TERMS_OF_CONSENTIMENT',
  route: {
    path: AUTH_ROUTES.TERM_OF_CONSENTIMENT
  }
});
