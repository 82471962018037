import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { formatCPF } from 'utils/textFormatUtils';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, Button, Box, Container } from '@mui/material';

import history from 'framework/history';
import { authActions } from 'framework/store/slices/auth.slice';

import { AsyncLoad } from 'components/AsyncLoad';
import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import SelectField from 'components/SelectField';
import SelectSchoolModal from 'components/SelectSchoolModal';
import TextField from 'components/TextField';
import wrapper from 'components/Wrapper';

import { APP_ROUTES } from 'modules/App/constants/route';
import { UPDATE_REGISTER } from 'modules/UpdateRegister/constants/routes';
import { registerUpdateUseCases } from 'modules/UpdateRegister/providers';

import Avatar from 'assets/img/update-register-avatar.png';

import { schema } from './schema';
import {
  StyledContainer,
  StyledImg,
  StyledTypography,
  Title,
  Subtitle,
  UserName,
  BoxTooltip
} from './styles';

const UpdateRegister = ({ user, userNeedsUpdate }) => {
  const translate = useLangContext();
  const { cpf } = user;
  const { state } = useLocation();
  const { learningLevel } = state || {};

  const [openModal, setOpenModal] = useState(false);
  const [school, setSchool] = useState({});
  const [learningLevelList, setLearningLevelList] = useState([]);

  if (!userNeedsUpdate) {
    history.push(APP_ROUTES.BASE_ROUTE);
  }

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
      cpf: formatCPF(cpf),
      learningLevel: learningLevel?.id
    }
  });

  const { setValue, clearErrors, errors } = formMethods;

  const closeModal = selectedItem => {
    setOpenModal(false);
    setSchool(selectedItem);
    clearErrors('school');
  };

  const getLearningLevelList = useCallback(async () => {
    try {
      const response = await registerUpdateUseCases.learningLevel();

      setLearningLevelList(response);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onSubmit = formData => {
    const learningLevelValue = learningLevelList.find(
      item => item.id === parseInt(formData.learningLevel, 10)
    );

    const payload = {
      cpf,
      school,
      learningLevel: learningLevelValue
    };

    history.push({
      pathname: UPDATE_REGISTER.CONFIRM_REGISTER,
      state: {
        ...payload
      }
    });
  };

  useEffect(() => {
    if (state) {
      setSchool(state.school);
    }
  }, [learningLevel, setValue, state]);

  return (
    <>
      <SelectSchoolModal
        open={openModal}
        handleModal={closeModal}
        school={school}
      />
      <Container>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <UserName variant="h1">
            {translate('HELLO')}, {user?.name}!
          </UserName>
          <Box mb={2}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <StyledImg src={Avatar} alt={translate('IMG_DESCRIPTION')} />
              </Grid>
              <Grid item>
                <BoxTooltip>
                  <Title>{translate('TITLE_PAGE')}</Title>
                  <Subtitle> {translate('SUBTITLE')}</Subtitle>
                </BoxTooltip>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>
      <StyledContainer alignSelf="center" direction="row" alignItems="center">
        <StyledTypography variant="h6" component="h2">
          {translate('TITLE')}
        </StyledTypography>
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="cpf"
                formMethods={formMethods}
                label={translate('COMMONS:CPF')}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              {school?.schoolName || errors.school ? (
                <TextField
                  name="school"
                  formMethods={formMethods}
                  value={school?.schoolName}
                  label={translate('SCHOOL')}
                  disabled
                />
              ) : (
                <Box mb={1}>
                  <Typography variant="subtitle2" fontWeight="bold">
                    {translate('SCHOOL')}
                  </Typography>
                </Box>
              )}
              <Box mt={1}>
                <Button variant="outlined" onClick={() => setOpenModal(true)}>
                  {translate('COMMONS:SEARCH')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} mt={0}>
              <AsyncLoad promiseFn={getLearningLevelList}>
                <SelectField
                  name="learningLevel"
                  formMethods={formMethods}
                  label={translate('LEARNING_LEVEL')}
                  placeholder={translate('COMMONS:SELECT')}
                  options={learningLevelList}
                  optionRenderer={option => option.description}
                  displayEmpty
                />
              </AsyncLoad>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body">{translate('DISCLAIMER')}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                marginTop: '24px',
                padding: '12px 24px'
              }}
            >
              {translate('COMMONS:GO_FORWARD')}
            </Button>
          </Grid>
        </Form>
      </StyledContainer>
    </>
  );
};

export default wrapper(UpdateRegister, {
  namespace: 'UPDATE_REGISTER',
  route: {
    path: UPDATE_REGISTER.REGISTER,
    breadcrumb: 'UPDATE_REGISTER'
  },
  mapState: state => ({
    user: state.auth.user,
    userNeedsUpdate: state.auth.userNeedsUpdate
  }),
  mapDispatch: dispatch => ({
    handleUserUpdate: boolean => {
      dispatch(authActions.userNeedsUpdate(boolean));
    }
  })
});
