import React from 'react';

import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { REQUEST_STATUS } from 'modules/V2_RequestAccess/constants/requestStatusEnum';

const DeleteButton = ({ row, onClick = () => {} }) => {
  const { requestStatus } = row;
  if (
    [
      REQUEST_STATUS.INCOMPLETE,
      REQUEST_STATUS.APPROVED,
      REQUEST_STATUS.DISAPPROVED
    ].includes(requestStatus)
  ) {
    return (
      <IconButton onClick={onClick}>
        <Delete />
      </IconButton>
    );
  }
  return null;
};

export default DeleteButton;
