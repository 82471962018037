import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ErrorsContainer = styled.span`
  height: 30px;
  font-size: 0.75rem;
  font-weight: 600;
  color: #f44336;
`;
