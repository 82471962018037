import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { router } from 'components/Router';

import { AUTH } from 'modules/Auth/constants/auth';
import { AUTH_ROUTES } from 'modules/Auth/constants/routes';
import { authService } from 'modules/Auth/providers';
import { USER_ROUTES } from 'modules/User/constants/routes';
import CreatePasswordForm from 'modules/User/fragments/CreatePasswordForm';

const ChangePassword = ({ challenge }) => {
  const onSubmitHandler = async ({ password }) => {
    await authService.completeNewPassword({
      challenge,
      password
    });
  };

  if (!challenge || challenge.challengeName !== AUTH.NEW_PASSWORD_REQUIRED) {
    return <Redirect to={AUTH_ROUTES.LOGIN} />;
  }

  return (
    <CreatePasswordForm cpf={challenge.username} onSubmit={onSubmitHandler} />
  );
};

const mapState = state => ({
  challenge: state.auth.challenge
});

const connected = connect(mapState)(ChangePassword);

export default router(connected, {
  path: [USER_ROUTES.CHANGE_PASSWORD]
});
