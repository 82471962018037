import { combineReducers } from '@reduxjs/toolkit';

import auth from './slices/auth.slice';
import institution from './slices/institution.slice';
import notification from './slices/notification.slice';
import object from './slices/object.slice';
import warnings from './slices/warnings.slice';

const rootReducer = combineReducers({
  auth,
  institution,
  notification,
  object,
  warnings
});

export default rootReducer;
