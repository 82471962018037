import React from 'react';

import { Container, Grid, Typography } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import Steps from './fragments/Steps';

const SuplemmentaryData = () => {
  const translate = useLangContext();

  return (
    <Container>
      <Grid container spacing={3} py={3}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h4">
            {translate('SUPPLEMENTARY_DATA_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" component="h4">
            {translate('SUPPLEMENTARY_DATA')}
          </Typography>
        </Grid>
      </Grid>
      <Steps />
    </Container>
  );
};

export default wrapper(SuplemmentaryData, {
  namespace: 'REQUEST_ACCESS'
});
