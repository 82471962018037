import React from 'react';

import { Box, Container, Grid, Typography } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import TermsOfUseContent from 'modules/RequestAccess/submodules/TermsOfUse/fragments/TermsOfUseContent';

import { APP_ROUTES } from '../constants/route';

const TermsOfUse = () => {
  const translate = useLangContext();

  return (
    <Container>
      <Box mt={4} mb={4}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            {translate('REQUEST_ACCESS:TERMS_OF_USE')}
          </Typography>
        </Grid>
        <Grid borderRadius={12} item xs={12}>
          <TermsOfUseContent />
        </Grid>
      </Box>
    </Container>
  );
};

export default wrapper(TermsOfUse, {
  namespace: 'TERMS_OF_USE',
  route: {
    path: APP_ROUTES.TERMS_OF_USE
  }
});
