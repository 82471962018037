import React from 'react';

import { Container, Typography, Button, Grid } from '@mui/material';

import history from 'framework/history';

import { useLangContext } from 'components/Globalization';
import PublicContainer from 'components/PublicContainer';
import WarningTag from 'components/Warnings/fragments/Tag';
import wrapper from 'components/Wrapper';

import { AUTH_ROUTES } from 'modules/Auth/constants/routes';

const SuccessMessage = () => {
  const translate = useLangContext();

  return (
    <PublicContainer>
      <Container>
        <Grid container spacing={3} py={4}>
          <Grid item xs={12}>
            <Typography variant="h4">
              {translate('SUCCESS_MESSAGE_TITLE')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <WarningTag
              warning={{
                message: translate('SUCCESS_MESSAGE'),
                options: { variant: 'success', hideCloseButton: true },
                style: {
                  minWidth: '100%'
                }
              }}
            />
          </Grid>
          <Grid item container justifyContent="flex-end" xs={12}>
            <Button variant="contained" onClick={() => history.push('/')}>
              {translate('ACCESS_PNLD')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </PublicContainer>
  );
};

export default wrapper(SuccessMessage, {
  namespace: 'LOGIN',
  route: {
    path: AUTH_ROUTES.SUCCESS_MESSAGE
  }
});
