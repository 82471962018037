import { setLocale } from 'yup';

export default setLocale({
  mixed: {
    required: 'VALIDATION:REQUIRED_FIELD'
  },
  string: {
    required: 'VALIDATION:REQUIRED_FIELD',
    length: ({ length }) => ({
      key: 'VALIDATION:STRING_LENGTH',
      params: { length }
    }),
    email: 'VALIDATION:INVALID_EMAIL',
    cpf: 'VALIDATION:INVALID_CPF',
    cnpj: 'VALIDATION:INVALID_CNPJ',
    url: 'VALIDATION:INVALID_URL',
    max: ({ max }) => ({
      key: 'VALIDATION:MAX_LENGTH',
      params: { max }
    })
  },
  number: {
    min: ({ min }) => ({
      key: 'VALIDATION:MIX_NUMBER',
      params: { min }
    }),
    max: ({ max }) => ({
      key: 'VALIDATION:MAX_NUMBER',
      params: { max }
    })
  },
  date: {
    required: 'VALIDATION:REQUIRED_FIELD',
    type: 'VALIDATION:INVALID_DATE'
  },
  object: {
    required: 'VALIDATION:REQUIRED_FIELD'
  },
  array: {
    required: 'VALIDATION:REQUIRED_FIELD',
    min: 'VALIDATION:REQUIRED_SELECT_ONE'
  },
  boolean: {
    required: 'VALIDATION:REQUIRED_FIELD'
  }
});
