import React, { useCallback, useState } from 'react';

import { authUseCases } from 'services/providers/auth';

import { Box, Button, Container, Grid } from '@mui/material';

import { AsyncLoad } from 'components/AsyncLoad';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { notificationUseCases } from 'modules/Notification/providers';
import { requestAccessUseCases } from 'modules/RequestAccess/provider';
import { StyledWarningTag } from 'modules/RequestAccess/styles';

const SelectProfile = ({ userGovBr }) => {
  const translate = useLangContext();
  const [showWarningTag, setShowWarningTag] = useState(false);

  const handleSubmit = async () => {
    const payload = {
      ...userGovBr,
      profile: 'ROLE_STUDENT'
    };
    try {
      await requestAccessUseCases.postStudens(payload);
      setShowWarningTag(true);
    } catch (error) {
      const errorKey =
        error.response.data.message === 'drm.integration.failed'
          ? 'STUDENT_LICENSE_ERROR'
          : 'STUDENT_NOT_FOUND';

      notificationUseCases.error(translate(errorKey));
    }
  };

  const handleLoginRedirect = useCallback(async () => {
    await authUseCases.signOut();
  }, []);

  return (
    <Container>
      <AsyncLoad promiseFn={handleSubmit}>
        {showWarningTag && (
          <Grid container xs={12} mb={4}>
            <StyledWarningTag
              warning={{
                message: translate('REQUEST_SUCCESS'),
                options: { variant: 'info', hideCloseButton: true }
              }}
            >
              <Box>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleLoginRedirect}
                >
                  {translate('PASSWORD_UPDATED:LOGIN')}
                </Button>
              </Box>
            </StyledWarningTag>
          </Grid>
        )}
      </AsyncLoad>
    </Container>
  );
};

export default wrapper(SelectProfile, {
  namespace: 'SELECT_PROFILE',
  mapState: state => ({
    userGovBr: state.auth.userGovBr
  }),
  breadcrumb: 'SELECT_PROFILE'
});
