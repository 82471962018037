import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { Button, Container, Grid, Typography } from '@mui/material';

import history from 'framework/history';

import Checkbox from 'components/Checkbox';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import TermsOfUseContent from 'modules/RequestAccess/submodules/TermsOfUse/fragments/TermsOfUseContent';

import { REQUEST_ACCESS_ROUTES } from '../../constants/routes';
import { requestAccessUseCases } from '../../provider';
import RefuseModal from './fragments/RefuseModal';

const TermsOfUse = ({ termVersion, needsSign }) => {
  /* ATENÇÃO: termVersion deve conter a última versão dos termos de uso
   ao aplicar qualquer alteração no texto dos termos de uso, termVersion deve ser atualizado com a nova versão.
	 termVersion está definido na pagina de RequestAccess */

  const translate = useLangContext();
  const [openRefuseModal, setOpenRefuseModal] = useState(false);

  const formMethods = useForm();

  const { control } = formMethods;

  const termsOfUseWatch = useWatch({
    control,
    name: 'termsOfUse'
  });

  const acceptTermsOfUse = async () => {
    const payload = {
      termVersion
    };

    try {
      if (needsSign.needUpdateTermsAndUse) {
        await requestAccessUseCases.updateTermsOfUse(payload);
      } else {
        await requestAccessUseCases.acceptTermsOfUse(payload);
      }
      history.push(REQUEST_ACCESS_ROUTES.SELECT_PROFILE);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <RefuseModal
        open={openRefuseModal}
        closeModal={() => setOpenRefuseModal(false)}
      />
      <Container maxWidth="md">
        <Grid container spacing={3} py={3}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h1">
              {translate('TERMS_OF_USE')}
            </Typography>
          </Grid>
          <Grid borderRadius={12} item xs={12}>
            <TermsOfUseContent />

            <Checkbox
              formMethods={formMethods}
              name="termsOfUse"
              label={<p>{translate('ACCEPT_TERMS_OF_USE')}</p>}
            />
          </Grid>

          <Grid item container justifyContent="center" xs={12}>
            <Grid>
              <Button
                onClick={() => setOpenRefuseModal(true)}
                variant="outlined"
              >
                {translate('COMMONS:REFUSE')}
              </Button>
              <Button
                variant="contained"
                onClick={() => acceptTermsOfUse()}
                style={{ marginLeft: '20px' }}
                disabled={!termsOfUseWatch}
              >
                {translate('COMMONS:ACCEPT')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default wrapper(TermsOfUse, {
  namespace: 'REQUEST_ACCESS',
  route: { path: REQUEST_ACCESS_ROUTES.TERMS_OF_USE }
});
