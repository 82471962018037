/* eslint-disable no-return-await */
export default class RegisterUpdateUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('./registerUpdateService.service').default} props.registerUpdateService
   */
  constructor({ registerUpdateService }) {
    this.registerUpdateService = registerUpdateService;
  }

  async schoolsList(params) {
    return await this.registerUpdateService.schoolsList(params);
  }

  async learningLevel() {
    return await this.registerUpdateService.learningLevel();
  }

  async confirmUpdate(payload) {
    return await this.registerUpdateService.confirmUpdate(payload);
  }
}
