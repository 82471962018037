export default class RequestListUseCases {
  /**
   * @param {object} props
   * @param {import('./requestList.service').default} props.requestListService
   */
  constructor({ requestListService }) {
    this.requestListService = requestListService;
  }

  async getTeacherRequestList() {
    return this.requestListService.getTeacherRequestList();
  }

  async deleteRequest(requestId) {
    return this.requestListService.deleteRequest(requestId);
  }

  async getStateList() {
    return this.requestListService.getStateList();
  }

  async getCityList(initials) {
    return this.requestListService.getCityList(initials);
  }

  async getSchoolList(ibge) {
    return this.requestListService.getSchoolList(ibge);
  }

  async getLearningLevelList(inep) {
    return this.requestListService.getLearningLevelList(inep);
  }

  async postTeacherRequest(payload) {
    return this.requestListService.postTeacherRequest(payload);
  }
}
