import React from 'react';

import { globalization, useLangContext } from 'components/Globalization';

import newLogoPnld from 'assets/img/new_logo_pnld.png';

import { Footer, FooterCopy, Img } from './style';

const PrivateFooter = () => {
  const translate = useLangContext();

  return (
    <Footer>
      <Img src={newLogoPnld} alt={translate('LOGO_PNLD')} />
      <FooterCopy>{translate('COPYRIGHT')}</FooterCopy>
    </Footer>
  );
};

const globalized = globalization(PrivateFooter, 'PRIVATE_FOOTER');

export default globalized;
