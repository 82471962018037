export default class DownloadWorkUseCases {
  /**
   * @param {object} props
   * @param {import('./downloadWork.service').default} props.downloadWorkService
   */
  constructor({ downloadWorkService }) {
    this.downloadWorkService = downloadWorkService;
  }

  async getFileUrl(documentId) {
    return this.downloadWorkService.getFileUrl(documentId);
  }

  async getVolume(subscriptionVolumeId) {
    return this.downloadWorkService.getVolume(subscriptionVolumeId);
  }

  async getCollections(subscriptionCollectionId) {
    return this.downloadWorkService.getCollections(subscriptionCollectionId);
  }
}
