import styled from 'styled-components';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Divider, ListItemButton, Stack } from '@mui/material';

export const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  color: ${props => props.theme.palette.primary.main};
`;

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    border-color: ${props => props.theme.palette.border.light};
  }
`;

export const List = styled(ListItemButton)`
  &.MuiListItemButton-root {
    background-color: ${props => props.theme.palette.text.light};
    justify-content: space-between;
  }
`;

export const StyledStack = styled(Stack)`
  padding: 16px;
  align-items: center;
`;
