import React, { useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { MASKS } from 'constants/masks';
import { formatCPF } from 'utils/textFormatUtils';

import { yupResolver } from '@hookform/resolvers/yup';
import { Paper, Grid, Box } from '@mui/material';

import history from 'framework/history';

import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import MaskedTextField from 'components/MaskedTextField';
import TextField from 'components/TextField';

import { REQUEST_ACCESS_ROUTES } from '../../../../constants/routes';
import { useRequestAccessContext } from '../../../../Context';
import { requestAccessUseCases } from '../../../../provider';
import {
  FormTitle,
  StyledActionButton,
  StyledCancelButton
} from '../../../../styles';
import { schema } from './schema';

const IdentifyForm = ({ userGovBr }) => {
  const translate = useLangContext();
  const { requestData, setRequestData } = useRequestAccessContext();

  const { cpf, name } = userGovBr;

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cpf: formatCPF(cpf || requestData.cpf),
      email: requestData?.email
    }
  });
  const {
    control,
    formState: { isDirty }
  } = formMethods;

  const handleCancel = () => {
    history.push(REQUEST_ACCESS_ROUTES.SELECT_PROFILE);
  };

  const handleSubmit = useCallback(
    async formData => {
      const redirect = history.push({
        pathname: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS_TEACHER
      });

      try {
        let resp;
        if (!requestData?.id) {
          resp = await requestAccessUseCases.postRequestAccessTeacher({
            ...formData,
            name
          });
        } else if (isDirty) {
          resp = await requestAccessUseCases.putRequestAccessTeacher({
            payload: { ...requestData, ...formData },
            requestAccessTeacherId: requestData.id
          });
        }
        setRequestData(prev => ({ ...prev, ...resp }));
        redirect();
      } catch (error) {
        console.error(error);
      }
    },
    [isDirty, name, requestData, setRequestData]
  );

  const emailWatch = useWatch({
    control,
    name: 'email'
  });

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      alignItems="center"
      mt="5%"
      mb="5%"
    >
      <Paper style={{ width: 504, height: 404, padding: 32 }}>
        <Box mb={5}>
          <FormTitle variant="h4" gutterBottom component="h1" fontSize="20px">
            {translate('FORM_TITLE')}
          </FormTitle>
        </Box>
        <Form formMethods={formMethods} onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MaskedTextField
                name="cpf"
                label={translate('COMMONS:CPF')}
                formMethods={formMethods}
                fullWidth
                placeholder="000.000.000-00"
                mask={MASKS.CPF}
                isDisabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                label={translate('COMMONS:EMAIL')}
                placeholder="name@email.com.br"
                fullWidth
                formMethods={formMethods}
              />
            </Grid>
          </Grid>
          <Grid mt="32px" container justifyContent="space-between">
            <StyledCancelButton variant="outlined" onClick={handleCancel}>
              {translate('COMMONS:CANCEL')}
            </StyledCancelButton>
            <StyledActionButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={!emailWatch}
            >
              {translate('COMMONS:GO_FORWARD')}
            </StyledActionButton>
          </Grid>
        </Form>
      </Paper>
    </Grid>
  );
};

export default IdentifyForm;
