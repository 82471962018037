export default class LiteraryDidacticWorksUseCases {
  /**
   * @param {object} props
   * @param {import('./literaryDidacticWorks.service').default} props.literaryDidacticWorksService
   */
  constructor({ store, literaryDidacticWorksService }) {
    this.literaryDidacticWorksService = literaryDidacticWorksService;
    this.store = store;
  }

  async getSchoolSegmentList() {
    return this.literaryDidacticWorksService.getSchoolSegmentList();
  }

  async getGradeList(inep, segmentId) {
    return this.literaryDidacticWorksService.getGradeList(inep, segmentId);
  }

  async getTypeLiteraryList(inep, segmentId, seriesId) {
    return this.literaryDidacticWorksService.getTypeLiteraryList(
      inep,
      segmentId,
      seriesId
    );
  }

  async getComponentList(inep, segmentId, seriesId, typeLiterary) {
    return this.literaryDidacticWorksService.getComponentList(
      inep,
      segmentId,
      seriesId,
      typeLiterary
    );
  }

  async postBooksTeacherSearch(payload) {
    return this.literaryDidacticWorksService.postBooksTeacherSearch({
      ...payload
    });
  }

  async getRelatedWorkList(inep, collectionId) {
    return this.literaryDidacticWorksService.getRelatedWorkList(
      inep,
      collectionId
    );
  }

  async getSchoolSegmentStudentList() {
    return this.literaryDidacticWorksService.getSchoolSegmentStudentList();
  }

  async getTypeLiteraryStudentList(inep, segmentId) {
    return this.literaryDidacticWorksService.getTypeLiteraryStudentList(
      inep,
      segmentId
    );
  }

  async getComponentStudentList(inep, segmentId) {
    return this.literaryDidacticWorksService.getComponentStudentList(
      inep,
      segmentId
    );
  }

  async postBooksStudentSearch(payload) {
    return this.literaryDidacticWorksService.postBooksStudentSearch({
      ...payload
    });
  }

  async postReceivePasswordByEmail() {
    return this.literaryDidacticWorksService.postReceivePasswordByEmail();
  }

  async getFileUrl(id) {
    return this.literaryDidacticWorksService.getFileUrl(id);
  }

  async getLicensesInfo() {
    return this.literaryDidacticWorksService.getLicensesInfo();
  }
}
