import React from 'react';

import { Box } from '@mui/system';

import src from 'assets/img/new_logo_pnld.png';

import Image from '../Image';

const LogoRNP = () => (
  <Box display="block" p={2} maxWidth={250}>
    <Image src={src} alt="Logotipo" />
  </Box>
);

export default LogoRNP;
