import { http } from 'adapters';
import store from 'framework/store/store';

import { userService } from 'modules/User/providers';

import AuthService from './auth.service';
import AuthUseCases from './auth.useCases';

export const authService = new AuthService({ http, store });

export const authUseCases = new AuthUseCases({
  store,
  authService,
  userService
});
