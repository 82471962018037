import React from 'react';

import PropTypes from 'prop-types';

import { Button, DialogActions, DialogContent, Grid } from '@mui/material';

import { Dialog, DialogTitle } from 'components/Dialog';

import { ContainerBackground, Table } from '../styles';

const ModalUser = ({ open, handleModal }) => (
  <Dialog maxWidth="lg" onClose={handleModal} open={open}>
    <DialogTitle style={{ padding: '24px' }}>
      Termo de Consentimento de Uso dos dados
    </DialogTitle>
    <DialogContent>
      <Grid item xs={12}>
        <Grid borderRadius={12} item xs={12}>
          <ContainerBackground style={{ width: '100%' }} borderRadius={4}>
            <p style={{ textAlign: 'center' }}>
              <u>
                <b>Aviso de Privacidade - Portal do Livro Digital</b>
              </u>
            </p>
            <br />
            <br />
            <p>
              Em atenção às diretrizes previstas na Lei Federal nº 13.709/2018
              (Lei Geral de Proteção de Dados ou “LGPD”), o presente Aviso de
              Privacidade possui o objetivo de informar como são tratados os
              dados pessoais no âmbito do{' '}
              <b>Portal do Livro Digital do PNLD (“Portal”)</b>.
            </p>
            <br />
            <br />
            <p>
              <b>1. Quem é o Controlador dos dados pessoais?</b>
              <br />
              O Controlador dos dados pessoais tratados no âmbito do Portal do
              Livro Digital é o Fundo Nacional de Desenvolvimento da Educação –
              FNDE, autarquia federal vinculada ao Ministério da Educação.
              <br />
            </p>
            <br />
            <br />
            <p>
              <b>
                2. Como e para quê (ou por quais motivos) meus dados pessoais
                são utilizados?
              </b>
              <br />
              Todos os dados pessoais abaixo são tratados com o objetivo de
              permitir alcance das finalidades específicas de identificar,
              autenticar os usuários adequadamente, viabilizar o funcionamento
              do Portal e atender eventuais requisições oficiais e/ou
              legislações aplicáveis.
            </p>
            <br />
            <br />
            <Table>
              <tbody>
                <tr>
                  <td>Professor(a)</td>
                  <td>
                    <ul>
                      <li>• Nome completo</li>
                      <li>• CPF</li>
                      <li>• E-mail</li>
                      <li>• Telefone</li>
                      <li>• UF, município e unidade de ensino</li>
                      <li>• Etapa da educação de atuação</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Diretor(a)</td>
                  <td>
                    <ul>
                      <li>• Nome completo</li>
                      <li>• CPF</li>
                      <li>• E-mail</li>
                      <li>• Função</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Estudante</td>
                  <td>
                    <ul>
                      <li>• Nome completo</li>
                      <li>• CPF</li>
                      <li>• Ano letivo</li>
                      <li>• Código INEP</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </Table>
            <br />
            <br />
            <p>
              <b>3. Como são tratados os dados pessoais de crianças?</b>
              <br />
              Considerando a finalidade do Portal, haverá o tratamento de dados
              pessoais de crianças (indivíduos menores de 12 anos, nos termos da
              Lei Federal nº 8.069/1990). O tratamento de dados pessoais desses
              indivíduos está adequado à finalidade e aos propósitos específicos
              de viabilizar cadastro, autenticação e fornecimento do conteúdo no
              Portal. Em observância com o art. 14 da Lei Federal nº
              13.709/2018, será de responsabilidade das unidades de ensino,
              quando instadas pelo FNDE, a coleta de consentimento específico e
              destacado de um dos pais ou representante legal das crianças, sem
              prejuízo ao titular, condicionado ao melhor interesse da criança.
            </p>
            <br />
            <p>
              <b>4. Com quem os dados pessoais são compartilhados?</b>
              <br />
              Os dados pessoais tratados no escopo do Portal não serão
              compartilhados, salvo em casos específicos previstos e limitados
              em Lei, de forma justificada, tais como: requisições oficiais,
              atendimento de obrigações legais ou regulatórias.
            </p>
            <br />
            <p>
              <b>5. Por quanto tempo os dados pessoais serão armazenados?</b>
              <br />
              Os dados pessoais são armazenados apenas pelo tempo necessário
              para o alcance das finalidades apresentadas neste aviso. Não
              obstante, algumas informações podem ser mantidas para o
              cumprimento de obrigações legais e/ou regulatórias e/ou para
              exercício regular de direitos em processos administrativos,
              judiciais ou arbitrais.
            </p>
            <br />
            <p>
              <b>6. Como os dados pessoais são protegidos?</b>
              <br />
              As informações e os dados pessoais são tratados de acordo com
              mecanismos robustos como acesso restrito, controle e auditoria de
              acessos, monitoramento e medidas de segurança físicas, eletrônicas
              e procedimentais capazes de prevenir a ocorrência de acessos não
              autorizados e situações acidentais ou ilícitas de destruição,
              perda, alteração, comunicação ou difusão, nos termos da Política
              Nacional de Segurança da Informação.
            </p>
            <br />
            <br />
            <p>
              <b>
                7. Quais são os direitos assegurados aos titulares de dados?
              </b>
              <br />
              A LGPD traz direitos aos titulares de dados pessoais, os quais
              podem ser exercidos se respeitadas as limitações técnicas e
              jurídicas eventualmente aplicáveis, bem como as condições de
              validação de identidade, quando for o caso.
              <br />
              <br />
              Informamos que o exercício desses direitos será viabilizado em
              consonância com as demais disposições legais e regulatórias
              aplicáveis, estando sujeito, inclusive, à observância de
              procedimentos aplicáveis no âmbito da Administração Pública
              Federal.
              <br />
              <br />
              São direitos dos titulares relacionados ao Portal:
              <br />
              <br />
              <b>• Confirmação do tratamento e acesso aos dados:</b> o titular
              pode requerer a confirmação da existência de tratamento de suas
              informações pessoais no âmbito do Portal, bem como solicitar o
              acesso aos seus dados pessoais eventualmente utilizados.
              <br />
              <br />
              <b>• Correção ou atualização:</b> o titular pode solicitar a
              correção e/ou atualização de seus dados pessoais, caso estejam
              incorretos, imprecisos ou desatualizados.
              <br />
              <br />
              <b>• Eliminação, anonimização e/ou bloqueio:</b> o titular pode
              solicitar eliminação, anonimização ou bloqueio de seus dados,
              desde que processados de forma excessiva, desnecessária e/ou em
              desconformidade com a LGPD.
              <br />
              <br />
              <b>• Oposição:</b> o titular pode solicitar a oposição ao
              tratamento de seus dados pessoais, observados os limites e as
              imposições legalmente previstos.
              <br />
              <br />
              <b>• Portabilidade:</b> uma vez regulamentado pela Autoridade
              Nacional de Proteção de Dados, o titular pode solicitar a
              portabilidade de seus dados pessoais, quando tecnicamente viável.
              <br />
              <br />
              <b>• Informações sobre o compartilhamento:</b> sem prejuízo das
              informações fornecidas neste aviso, o titular pode solicitar
              esclarecimentos adicionais sobre o compartilhamento de seus dados
              pessoais.
              <br />
              <br />
              <b>• Informações sobre o consentimento:</b> quando aplicável, o
              titular pode solicitar informações sobre possíveis consequências
              caso não autorize o tratamento de seus dados pessoais.
              <br />
              <br />
              <b>• Revogação do consentimento e eliminação de dados:</b> nos
              casos em que o tratamento dos dados ocorrer mediante a obtenção do
              consentimento, o titular ou seu responsável legal pode solicitar
              revogação dessa autorização e/ou a eliminação de dados pessoais
              tratados com base nessa autorização.
            </p>
            <br />
            <p>
              As solicitações referentes ao exercício destes direitos devem ser
              direcionadas ao canal:{' '}
              <a
                href="http://falabr.cgu.gov.br"
                target="_blank"
                rel="noreferrer"
              >
                http://falabr.cgu.gov.br.
              </a>
            </p>

            <p>
              <b>8. Canal de comunicação e atualizações</b>
              <br />O presente aviso pode sofrer atualizações e ajustes a
              qualquer tempo. Caso haja atualização relevante, utilizaremos os
              meios disponíveis para informar os usuários.
            </p>
          </ContainerBackground>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions style={{ padding: '24px' }}>
      <Button
        onClick={() => handleModal(false)}
        variant="outlined"
        id="btn-refuse"
      >
        fechar
      </Button>
    </DialogActions>
  </Dialog>
);

ModalUser.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired
  // eslint-disable-next-line react/forbid-prop-types
};

export default ModalUser;
