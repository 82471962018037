/* eslint-disable no-lone-blocks */
import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';

import * as Yup from 'yup';

import { AsyncLoad } from 'components/AsyncLoad';
import { useLangContext } from 'components/Globalization';
import TextField from 'components/TextField';
import wrapper from 'components/Wrapper';

import { APP_ROUTES } from 'modules/App/constants/route';
import { USER_ROUTES } from 'modules/User/constants/routes';
import CreatePasswordForm from 'modules/User/fragments/CreatePasswordForm';
import { userService } from 'modules/User/providers';

import PasswordUpdatedPopUp from './fragments/PasswordUpdatedPopUp';
import { Container, CustomLink, CustomText } from './style';

const ResetPassword = () => {
  const translate = useLangContext();
  const match = useRouteMatch();
  const { token } = match.params;

  const [user, setUser] = useState({});
  const [showPasswordUpdated, setShowPasswordUpdated] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [securityQuestionValidation, setSecurityQuestionValidation] =
    useState();

  const userPromise = useCallback(async () => {
    try {
      const userResponse = await userService.findUserByToken(token);
      setUser(userResponse);
      setIsTokenValid(true);
    } catch (error) {
      setIsTokenValid(false);
    }
  }, [token]);

  const onSubmitHandler = useCallback(
    async ({
      password,
      password_confirmation,
      recaptcha,
      questionId,
      answer
    }) => {
      const payload = {
        token,
        password,
        recaptcha,
        cpf: user.cpf,
        confirmPassword: password_confirmation
      };

      if (!user.securityQuestion) {
        await userService.createPassword(payload);
      } else {
        const securityAnswer = { questionId, answer };
        await userService.changePassword({ ...payload, securityAnswer });
      }

      setShowPasswordUpdated(true);
    },
    [token, user]
  );

  useEffect(() => {
    if (!user.securityQuestion) return;

    setSecurityQuestionValidation({
      answer: Yup.string().required(translate('ANSWER_REQUIRED')),
      questionId: Yup.string().required(translate('QUESTION_REQUIRED'))
    });
  }, [translate, user.securityQuestion]);

  if (!token || !user) {
    return <Redirect to={APP_ROUTES.BASE_ROUTE} />;
  }

  return (
    <AsyncLoad promiseFn={userPromise}>
      {isTokenValid ? (
        <Container>
          <PasswordUpdatedPopUp show={showPasswordUpdated} />

          <CreatePasswordForm
            cpf={user.cpf}
            onSubmit={onSubmitHandler}
            customValidation={securityQuestionValidation}
          >
            {formMethods =>
              user.securityQuestion ? (
                <>
                  <input
                    type="hidden"
                    name="questionId"
                    ref={formMethods.register}
                    value={user.securityQuestion.id}
                  />

                  <TextField
                    name="answer"
                    label={user.securityQuestion.question}
                    placeholder={translate('ANSWER')}
                    formMethods={formMethods}
                  />
                </>
              ) : null
            }
          </CreatePasswordForm>
        </Container>
      ) : (
        <Container style={{ justifyContent: 'center', margin: 'auto' }}>
          <h3>{translate('INVALID_TOKEN')}</h3>
          <CustomText>
            <CustomLink to={USER_ROUTES.REQUEST_PASSWORD_RESET}>
              {translate('LINK_EMAIL')}
            </CustomLink>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translate('TEXT_TOKEN')
              }}
            />
            <CustomLink to={APP_ROUTES.BASE_ROUTE}>
              {translate('LINK_INICIAL')}
            </CustomLink>
          </CustomText>
        </Container>
      )}
    </AsyncLoad>
  );
};

export default wrapper(ResetPassword, {
  namespace: 'RESET_PASSWORD',
  route: { path: [USER_ROUTES.RESET_PASSWORD, USER_ROUTES.CREATE_PASSWORD] }
});
