/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';

import { authService, authUseCases } from 'services/providers/auth';

import { AsyncLoad } from 'components/AsyncLoad';

import { notificationUseCases } from 'modules/Notification/providers';

export const HUB_EVENTS = {
  SIGN_IN: 'signIn',
  SIGN_IN_FAILURE: 'signIn_failure',
  SIGN_UP: 'signUp',
  SIGN_OUT: 'signOut'
};

const AuthProvider = ({ children }) => {
  const restoreUserSessionPromise = useCallback(async () => {
    await authUseCases.restoreCurrentSession();
  }, []);

  useEffect(() => {
    authService.registerListener('auth', data => {
      const session = data?.payload?.data?.signInUserSession;
      if (
        data?.payload?.data?.challengeName === 'MFA_SETUP' &&
        data?.payload?.event === HUB_EVENTS.SIGN_IN
      ) {
        return authUseCases.signOut();
      }

      try {
        switch (data.payload.event) {
          case HUB_EVENTS.SIGN_IN:
            return authUseCases.signIn(session);
          case HUB_EVENTS.SIGN_UP:
            return authUseCases.signUp();
          case HUB_EVENTS.SIGN_OUT:
            return authUseCases.signOut();
          default:
            return data.payload.data;
        }
      } catch (error) {
        console.error(error);
        return notificationUseCases.error(data.payload.data, {
          namespace: 'ERROR'
        });
      }
    });
  }, []);

  return (
    <AsyncLoad promiseFn={restoreUserSessionPromise}>{children}</AsyncLoad>
  );
};

export default AuthProvider;
