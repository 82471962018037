import React, { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { AsyncLoad } from 'components/AsyncLoad';
import { useLangContext } from 'components/Globalization';
import WarningTag from 'components/Warnings/fragments/Tag';

import { literaryDidacticWorksUseCases } from 'modules/LiteraryDidacticWorks/provider';

export const HomeWarnings = ({ userInfo }) => {
  const translate = useLangContext();
  const [licensesPendingGeneration, setLicensesPendingGeneration] =
    useState(true);

  const { isRoleStudent } = userInfo;

  const fetchLicensesInfo = useCallback(async () => {
    try {
      const resp = await literaryDidacticWorksUseCases.getLicensesInfo();
      setLicensesPendingGeneration(resp.licensesInProgressGeneration);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      {!isRoleStudent && (
        <Grid item xs={12}>
          <WarningTag
            warning={{
              message: translate('INFO_MESSAGE'),
              options: { variant: 'info', hideCloseButton: true },
              style: {
                minWidth: '100%'
              }
            }}
          />
        </Grid>
      )}
      <AsyncLoad promiseFn={fetchLicensesInfo}>
        {licensesPendingGeneration && (
          <Grid item xs={12}>
            <WarningTag
              warning={{
                message: translate('LICENSES_PENDING_MESSAGE'),
                options: { variant: 'warning', hideCloseButton: true },
                style: {
                  minWidth: '100%'
                }
              }}
            />
          </Grid>
        )}
      </AsyncLoad>
    </>
  );
};
