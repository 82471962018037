import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';

import GlobalStyles from 'styles/global';

import history from 'framework/history';
import store from 'framework/store/store';

import Theme from 'components/Theme';

import 'framework/awsAmplify';
import 'framework/i18n';
import 'framework/yup';
import 'assets/fonts/fonts.css';

import App from 'modules/App';
import NotificationProvider from 'modules/Notification/context/NotificationProvider';

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Theme>
        <GlobalStyles />
        <NotificationProvider>
          <Router history={history}>
            <App />
          </Router>
        </NotificationProvider>
      </Theme>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
