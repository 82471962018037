import React from 'react';

import { Card, Cover, Title } from './styles';

const BookCard = ({ data, handleView }) => (
  <Card onClick={handleView}>
    <Cover src={data.urlCover} alt={data.titleBook} />
    <Title>{data.titleBook}</Title>
  </Card>
);

export default BookCard;
