import { createSlice } from '@reduxjs/toolkit';

export const OBJECT_REDUCER = 'object';

const INITIAL_STATE = {
  objectData: undefined,
  isDateExpired: false
};

const object = createSlice({
  name: OBJECT_REDUCER,
  initialState: INITIAL_STATE,
  reducers: {
    setObject: (state, { payload }) => {
      state.objectData = payload;
    },
    cleanObject: state => {
      // eslint-disable-next-line no-unused-vars
      state = { ...INITIAL_STATE };
    }
  }
});

export const objectActions = object.actions;

export default object.reducer;
