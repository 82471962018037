import { userService } from 'services/providers/user';

import { http } from 'adapters';
import store from 'framework/store/store';

import AuthService from './service';
import AuthUseCases from './useCases';

export const authService = new AuthService({ http });

export const authUseCases = new AuthUseCases({
  store,
  authService,
  userService
});
