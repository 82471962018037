import { createContext, useContext } from 'react';

export const RequestAccessContext = createContext({
  requestData: {},
  setRequestData: () => {},
  isCreating: false,
  setIsCreating: () => {}
});

export const useRequestAccessContext = () => useContext(RequestAccessContext);
