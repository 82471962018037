import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { Button, Grid, Typography } from '@mui/material';

import ImgLogin from 'assets/img/img-login.png';

export const WrapperTitle = styled.div`
  margin: 80px auto 0;
  width: 600px;
  text-align: left;
  img {
    margin-bottom: 24px;
  }
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    max-width: 100%;
    width: 300px;
    margin-top: 40px;
  }
`;

export const MainTitle = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 48px;
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    font-size: 24px;
  }
`;

export const LeftContainer = styled.div`
  align-items: center;
  background: ${props =>
    `${props.theme.palette.primary.main} url(${ImgLogin}) no-repeat center bottom/auto 56%`};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 680px;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  overflow: hidden;
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    height: 58vh;
    min-height: auto;
  }
`;

export const RightContainer = styled.div`
  background: ${props => props.theme.palette.secondary.main};
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  padding: 64px 0;
`;

export const ButtonAccess = styled(Button)`
  text-transform: none !important;
  font-size: 17px;
  font-weight: 600;
  width: 430px;
  max-width: 90%;
`;

export const CustomLink = styled(Link)`
  color: ${props => props.theme.palette.info.main};
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const DownloadLink = styled.a`
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  margin-top: -7px;
  display: block;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    font-size: 10px !important;
  }
`;

export const CustomParagraph = styled(Typography)`
  font-size: 14px;
  padding-bottom: 20px;
  font-weight: bold;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    font-weight: normal !important;
    margin-top: 14px !important;
  }
`;

export const StyledText = styled(Typography)`
  &.MuiTypography-root {
    width: 430px;
    text-align: center;
    margin: 5% 0;
    font-weight: 500;
    color: ${props => props.theme.palette.primary.main};
    @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
      font-weight: 700;
      font-size: 20px;
      width: 280px;
    }
  }
`;

export const LinkWrapper = styled(Grid)`
  display: inline-block !important;
  margin: 24px 0px !important;
  text-align: center;
`;

export const StyledGrid = styled(Grid)`
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    display: block !important;
    margin-top: ${props => props.hidePrivacyPolicy && '100px !important'};
  }

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    margin-top: ${props => props.hidePrivacyPolicy && '50px !important'};
  }
`;
