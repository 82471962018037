import styled from 'styled-components';

export const ContainerPublic = styled.div`
  background: ${props => props.theme.palette.background.light};
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const Img = styled.img`
  max-width: 100%;
`;

export const StyledImgFooter = styled(Img)`
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    max-width: 90%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Footer = styled.footer`
  align-items: center;
  background: ${props => props.theme.palette.background.white};
  border-top: 1px solid ${props => props.theme.palette.border.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const FooterCopy = styled.div`
  align-items: center;
  background: ${props => props.theme.palette.primary.main};
  border-top: 1px solid ${props => props.theme.palette.border.primary};
  color: ${props => props.theme.palette.text.light};
  display: flex;
  font-size: 14px;
  height: 48px;
  justify-content: center;
  line-height: 16px;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    text-align: center;
    padding: 0px 42px;
    height: 56px;
  }
`;

export const LogoContainer = styled.div`
  text-align: -webkit-center;
  padding-bottom: 32px;
  img {
    max-width: 100px;
  }
`;
