import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { isArray } from 'lodash';
import { ThemeContext } from 'styled-components';

import { DefaultLoader } from '../AsyncLoad/DefaultLoader';

export const GlobalizationContext = React.createContext(() => {});

export const Globalization = ({ namespace, children }) => {
  const theme = useContext(ThemeContext);

  try {
    const [t] = useTranslation(namespace);

    return (
      <GlobalizationContext.Provider value={t}>
        {children}
      </GlobalizationContext.Provider>
    );
  } catch (error) {
    return <DefaultLoader color={theme.palette.primary.main} />;
  }
};

export const useLangContext = () => useContext(GlobalizationContext);

export const globalization = (Component, namespace) => props => {
  const defaultNamespaces = ['DEFAULT', 'VALIDATION'];

  if (isArray(namespace)) defaultNamespaces.unshift(...namespace);
  else defaultNamespaces.unshift(namespace);

  return (
    <Globalization namespace={namespace}>
      <Component {...props} />
    </Globalization>
  );
};
