import React, { useCallback, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';

import { useQueryParams } from 'hooks/useQueryParams';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';
import { authService, authUseCases } from 'services/providers/auth';
import { ThemeContext } from 'styled-components';

import { authActions } from 'framework/store/slices/auth.slice';
import { ROLES } from 'helpers/roles';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { APP_ROUTES } from 'modules/App/constants/route';
import { AUTH_ROUTES } from 'modules/Auth/constants/routes';
import { INITIAL_PAGE_ROUTES } from 'modules/InitialPage/constants/routes';
import { notificationUseCases } from 'modules/Notification/providers';
import { UPDATE_REGISTER } from 'modules/UpdateRegister/constants/routes';
import { userUseCases } from 'modules/User/providers';

import { LogoRNP } from '../../../../components/Image';
import { LogoContainer } from '../../../../components/PublicContainer/style';
import history from '../../../../framework/history';
import { Container } from '../../../App/submodules/NoAccess/style';
import { TokenLoaderContainer, TokenWrapperContainer } from './style';

const getRedirectFromState = state => {
  try {
    const jsonString = atob(state);
    return JSON.parse(jsonString);
  } catch (e) {
    console.error(e);
    return null;
  }
};
const Token = ({ addUserGovBr, handleUserUpdate }) => {
  const theme = useContext(ThemeContext);
  const translate = useLangContext();

  const query = useQueryParams();

  const redirectUri = query.state ? getRedirectFromState(query.state) : null;

  const login = useCallback(
    async (jwtDecoded, token, username) => {
      const isStudent = jwtDecoded['cognito:groups'].includes(
        ROLES.ROLE_STUDENT
      );

      try {
        const response = await authUseCases.accessTokenLogin(
          jwtDecoded,
          token,
          username
        );

        if (response.session && response.user) {
          addUserGovBr({
            cpf: response.user.username,
            email: response.session.idToken.payload.email,
            name: response.session.idToken.payload.name
          });

          if (isStudent) {
            try {
              const { needUpdate } = await userUseCases.needsUpdate();
              handleUserUpdate(needUpdate);

              if (needUpdate) {
                return history.push(UPDATE_REGISTER.REGISTER);
              }
            } catch (error) {
              console.error(error);
            }
          }

          return history.push(
            redirectUri.redirectTo || INITIAL_PAGE_ROUTES.INITIAL_PAGE
          );
        }
        return history.push(APP_ROUTES.NOT_ALLOWED);
      } catch (error) {
        notificationUseCases.error(error, { namespace: 'ERROR' });
        return history.push(APP_ROUTES.NOT_ALLOWED);
      }
    },
    [addUserGovBr, handleUserUpdate, redirectUri.redirectTo]
  );

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);

    const getFullToken = async () => {
      const token = await authService.getFullToken(queryParams.code);

      if (token) {
        const jwtDecoded = jwt_decode(token.access_token);
        const username = jwtDecoded.username.split('gov.br_')[1];
        await login(jwtDecoded, token, username);
      }
    };

    const init = () => {
      if (queryParams.code && !authUseCases.isAuthenticated()) {
        getFullToken();
      }
      return (
        !authUseCases.isAuthenticated() && <Redirect to={AUTH_ROUTES.LOGIN} />
      );
    };

    init();
  }, [login]);

  return (
    <Container>
      <TokenWrapperContainer>
        <TokenLoaderContainer>
          <PulseLoader
            size="10px"
            margin="2px"
            color={theme.palette.primary.light}
          />
          <span>{translate('VALIDATION')}</span>
        </TokenLoaderContainer>

        <LogoContainer>
          <LogoRNP />
        </LogoContainer>
      </TokenWrapperContainer>
    </Container>
  );
};

export default wrapper(Token, {
  namespace: 'TOKEN',
  route: {
    path: AUTH_ROUTES.TOKEN
  },
  mapDispatch: dispatch => ({
    addUserGovBr: key => {
      dispatch(authActions.setUserGovBr(key));
    },
    handleUserUpdate: boolean => {
      dispatch(authActions.userNeedsUpdate(boolean));
    }
  })
});
