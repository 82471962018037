import React, { useContext } from 'react';
import { PulseLoader } from 'react-spinners';

import { ThemeContext } from 'styled-components';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { Container, LoadingText, LoadingPopup } from './style';

const FormLoader = () => {
  const theme = useContext(ThemeContext);
  const translate = useLangContext();

  return (
    <Container>
      <LoadingPopup>
        <LoadingText>{translate('LOADING')}</LoadingText>

        <div>
          <PulseLoader
            size="10px"
            margin="2px"
            color={theme.palette.primary.main}
          />
        </div>
      </LoadingPopup>
    </Container>
  );
};

export default wrapper(FormLoader, {
  namespace: 'FORM_LOADING'
});
