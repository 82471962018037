import { useState, useCallback } from 'react';

import * as Yup from 'yup';

const usePasswordValidation = translate => {
  const [validation, setValidation] = useState({
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialCharacter: false
  });

  const CreatePasswordSchema = useCallback(
    customValidation =>
      Yup.object().shape({
        password: Yup.string().required(translate('PASSWORD_REQUIRED')),
        password_confirmation: Yup.string()
          .required(translate('PASSWORD_REQUIRED'))
          .test(
            'confirmPassword',
            translate('CONFIRMATION_INVALID'),
            function validatePassword(value) {
              const { password } = this.parent;
              return password === value;
            }
          ),
        termsOfUse: Yup.boolean().oneOf([true], 'VALIDATION:REQUIRED_FIELD'),
        recaptcha: Yup.string().recaptcha('RECAPTCHA_REQUIRED'),
        ...customValidation
      }),
    [translate]
  );

  const onPasswordChangeHandler = useCallback(event => {
    const { value } = event.target;

    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialCharacter = /\W/.test(value);
    const minLength = value.length >= 11;

    setValidation({
      hasLowerCase,
      hasUpperCase,
      hasNumber,
      hasSpecialCharacter,
      minLength
    });
  }, []);

  return {
    validation,
    CreatePasswordSchema,
    onPasswordChangeHandler
  };
};

export default usePasswordValidation;
