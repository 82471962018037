import { http } from 'adapters';
import store from 'framework/store/store';

import UserService from './user.service';
import UserUseCases from './user.useCases';

export const userService = new UserService({ http });

export const userUseCases = new UserUseCases({
  store,
  userService
});
