import React, { useState } from 'react';

import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_ROUTES } from './constants/routes';
import { RequestAccessStepContext } from './fragments/Context';
import SelectProfile from './submodules/SelectProfile';
import SuccessMessage from './submodules/SuccessMessage';
import SupplementaryData from './submodules/SupplementaryData';
import TermsOfUse from './submodules/TermsOfUse';

const RequestAccess = () => {
  const [step, setStep] = useState(1);

  return (
    <>
      <RequestAccessStepContext.Provider value={{ step, setStep }}>
        {step === 0 && <TermsOfUse />}
        {step === 1 && <SelectProfile />}
        {step === 2 && <SupplementaryData />}
        {step === 3 && <SuccessMessage />}
      </RequestAccessStepContext.Provider>
    </>
  );
};

export default wrapper(RequestAccess, {
  namespace: 'REQUEST_ACCESS',
  route: {
    path: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS,
    breadcrumb: 'REQUEST_ACCESS'
  }
});
