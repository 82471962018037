import { FONT_FAMILY } from 'constants/fonts';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *, *:before, *:after {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }
		

  body {
    font-family: ${FONT_FAMILY};
    font-feature-settings: 'lnum';
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none;
  }
  
  ul {
    list-style: none;
  }
`;
