export const REQUEST_ACCESS_ROUTES = {
  REQUEST_ACCESS: '/request-access',
  REQUEST_ACCESS_V2: '/request-access-v2',
  TERMS_OF_USE: '/request-access-v2/terms-of-use',
  SELECT_PROFILE: '/request-access-v2/select-profile',
  IDENTIFY_USER: '/request-access-v2/identify-user',
  REQUEST_ACCESS_TEACHER: '/request-access-v2/teacher',
  SCHOOL_MANAGEMENT: '/request-access-v2/school-management',
  REQUEST_COMPLETED: '/request-access-v2/completed',
  SUCCESS: '/request-access-v2/success'
};
