import styled from 'styled-components';

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
`;

export const CarouselContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  & > button {
    position: absolute;

    &.prev {
      left: -46px;
    }

    &.next {
      right: -46px;
    }
  }
`;

export const CarouselList = styled.ul`
  display: flex;
  gap: 24px;
  overflow: auto;
  padding: 0 0 12px;
  scroll-snap-type: x mandatory;
  width: 100%;
`;

export const CarouselItem = styled.li`
  display: flex;
  flex-shrink: 0;
  width: 268px;
`;

export const CarouselDots = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 24px;
`;

export const CarouselDot = styled.div`
  background-color: ${props => props.theme.palette.border.light};
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 8px;

  &.active {
    background-color: ${props => props.theme.palette.primary.main};
  }
`;
