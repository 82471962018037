import styled from 'styled-components';

import { Box } from '@mui/material';

export const ContainerBackground = styled(Box)`
  background: ${props => props.theme.palette.background.light};
  border: ${props => `1px solid ${props.theme.palette.border.light}`};
  padding: 24px !important;
  border-radius: 1%;
  p {
    color: ${props => props.theme.palette.text.primary};
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
    text-justify: inter-word;
  }
`;
