import React, { useCallback, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useStepperContext } from 'hooks/useStepper/StepperContext';

import { Button } from '@mui/material';

import history from 'framework/history';

import Checkbox from 'components/Checkbox';
import { useLangContext } from 'components/Globalization';

import { REQUEST_ACCESS_ROUTES } from '../../../../constants/routes';
import { useRequestAccessContext } from '../../../../Context';
import { requestAccessUseCases } from '../../../../provider';
import { SummaryBox } from '../../styles';
import Section from './Section';
import { ButtonWrapper, SummaryTitle } from './styles';

const SECTION_TITLE = {
  PERSONAL_INFO: 'PERSONAL_INFO',
  LOCALITY: 'LOCALITY',
  SCHOOL: 'SCHOOL',
  STAGE_EDUCATION: 'STAGE_EDUCATION'
};

const SummaryInfo = () => {
  const translate = useLangContext();
  const formMethods = useForm();

  const { fnBack, fnSetCurrentStep } = useStepperContext();
  const { requestData, setRequestData } = useRequestAccessContext();

  const { control } = formMethods;

  const infoIsCorrect = useWatch({
    control,
    name: 'acceptInfoIsCorrect'
  });

  const INFO = useMemo(
    () => [
      {
        sectionTitle: SECTION_TITLE.PERSONAL_INFO,
        items: [
          {
            label: translate('COMMONS:EMAIL'),
            value: requestData?.email
          }
        ]
      },
      {
        sectionTitle: SECTION_TITLE.LOCALITY,
        items: [
          {
            label: translate('UF'),
            value: requestData?.uf
          },
          {
            label: translate('CITY'),
            value: requestData?.cityName
          }
        ]
      },
      {
        sectionTitle: SECTION_TITLE.SCHOOL,
        items: [
          {
            label: translate('SCHOOL'),
            value: `${requestData?.school} - INEP ${requestData?.inep}`
          }
        ]
      },
      {
        sectionTitle: SECTION_TITLE.STAGE_EDUCATION,
        items: [
          {
            label: translate('SCHOOL_STEP'),
            value: requestData?.learningLevelName
          }
        ]
      }
    ],
    [requestData, translate]
  );

  const handlePressEdit = useCallback(
    sectionTitle => {
      switch (sectionTitle) {
        case SECTION_TITLE.PERSONAL_INFO:
          setRequestData(prev => ({ ...prev }));
          history.push(REQUEST_ACCESS_ROUTES.IDENTIFY_USER);
          break;
        case SECTION_TITLE.LOCALITY:
          fnSetCurrentStep(0);
          break;
        case SECTION_TITLE.SCHOOL:
          fnSetCurrentStep(1);
          break;
        case SECTION_TITLE.STAGE_EDUCATION:
          fnSetCurrentStep(2);
          break;
        default:
          break;
      }
    },
    [fnSetCurrentStep, setRequestData]
  );

  const handleSubmit = useCallback(async () => {
    const { id } = requestData;

    try {
      await requestAccessUseCases.putRequestAccessTeacherFinish({
        requestAccessTeacherId: id
      });

      history.push({
        pathname: REQUEST_ACCESS_ROUTES.REQUEST_COMPLETED
      });
    } catch (error) {
      console.error(error);
    }
  }, [requestData]);

  return (
    <SummaryBox>
      <SummaryTitle>{translate('SUMMARY.TITLE')}</SummaryTitle>

      {INFO?.map(info => (
        <Section
          key={info.sectionTitle}
          info={info}
          handlePressEdit={() => handlePressEdit(info.sectionTitle)}
        />
      ))}

      <Checkbox
        formMethods={formMethods}
        name="acceptInfoIsCorrect"
        label={translate('SUMMARY.INFO_IS_CORRECT')}
      />

      <ButtonWrapper>
        <Button variant="outlined" onClick={fnBack}>
          {translate('COMMONS:BACK')}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!infoIsCorrect}
        >
          {translate('COMMONS:DONE')}
        </Button>
      </ButtonWrapper>
    </SummaryBox>
  );
};

export default SummaryInfo;
