/* eslint-disable no-console */
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, IconButton, InputBase, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100]
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));
function InputSearch({ placeholder, onSearch, maxLength, select = false }) {
  const classes = useStyles();
  const formMethods = useForm({ defaultValues: { searchTerm: '' } });
  const { control, register, handleSubmit, reset } = formMethods;

  const onSubmit = formData => {
    onSearch(formData.searchTerm);
  };

  const stop = e => e.stopPropagation();

  const searchTerm = useWatch({ control, name: 'searchTerm' });
  let selectProps = {};

  if (select) {
    selectProps = {
      autoFocus: true,
      onChange: e => onSearch(e.target.value),
      onKeyDown: e => {
        if (e.key !== 'Escape') {
          stop(e);
        } else {
          onSearch('');
        }
      },
      onClick: stop
    };
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.root} elevation={0}>
        <InputBase
          name="searchTerm"
          id="searchTerm"
          placeholder={placeholder}
          className={classes.input}
          inputRef={register}
          inputProps={{ maxLength }}
          {...(select ? selectProps : {})}
        />
        {searchTerm ? (
          <IconButton
            size="small"
            color="primary"
            className={classes.iconButton}
            onClick={e => {
              reset();
              onSearch('');
              stop(e);
            }}
          >
            <ClearIcon />
          </IconButton>
        ) : null}
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          type={select ? 'button' : 'submit'}
          size="small"
          color={select ? 'default' : 'primary'}
          className={classes.iconButton}
          aria-label="search"
          id="btSearch"
          onClick={e => {
            stop(e);
          }}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </form>
  );
}

InputSearch.defaultProps = {
  placeholder: ''
};

InputSearch.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired
};

export default InputSearch;
