import { formatString } from 'helpers/formatString';

import { REGISTER_UPDATE } from '../constants/api';

export default class RegisterUpdateService {
  /**
   * @param {object} props
   * @param {import('adapters/axios').Axios} props.http
   */

  constructor({ http }) {
    this.http = http;
  }

  async schoolsList(params) {
    return this.http.get(REGISTER_UPDATE.SCHOOLS_LIST, {
      params: { ...params }
    });
  }

  async learningLevel() {
    return this.http.get(REGISTER_UPDATE.LEARNING_LEVEL);
  }

  async confirmUpdate(payload) {
    const url = formatString(REGISTER_UPDATE.CONFIRM_UPDATE, {
      payload
    });
    return this.http.put(url, payload);
  }
}
