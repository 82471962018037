import React from 'react';

import { Container, Grid, Typography } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_ROUTES } from '../../constants/routes';
import { useRequestAccessContext } from '../../Context';
import IdentifyForm from './fragments/Form';
import { UserName } from './styles';

const IdentifyUser = ({ userGovBr }) => {
  const translate = useLangContext();
  const { requestData } = useRequestAccessContext();

  return (
    <Container>
      <Grid>
        <UserName variant="h3" component="h1">
          {translate('HELLO')}, {userGovBr?.name}!
        </UserName>
        <Typography>
          {translate('FILL_DATA', { role: requestData?.role })}
        </Typography>
        <IdentifyForm userGovBr={userGovBr} />
      </Grid>
    </Container>
  );
};

export default wrapper(IdentifyUser, {
  namespace: 'IDENTIFY_USER',
  mapState: state => ({
    userGovBr: state.auth.userGovBr
  }),
  route: { path: REQUEST_ACCESS_ROUTES.IDENTIFY_USER }
});
