import React, { useCallback, useState } from 'react';

import { useStepper } from 'hooks/useStepper';

import { Grid } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import ManagerComplementaryData from '../ManagerComplementaryData';
import SelectCity from '../SelectCity';
import SelectSchool from '../SelectSchool';
import SelectStageActingEducation from '../SelectStageActingEducation';
import SelectUF from '../SelectUF';

const Steps = () => {
  const translate = useLangContext();
  const [data, setData] = useState([]);
  const [addNew, setAddNew] = useState(true);

  const stepPages = [
    {
      title: translate('STEP_UF'),
      Content: SelectUF
    },
    {
      title: translate('STEP_CITY'),
      Content: SelectCity
    },
    {
      title: translate('STEP_SCHOOL'),
      Content: SelectSchool
    },
    {
      title: translate('STEP_STAGE_EDUCATION'),
      Content: SelectStageActingEducation
    }
  ];

  const handleAdd = useCallback(() => {
    setAddNew(true);
  }, []);

  const handleRemove = useCallback(row => {
    setData(current => current.filter(item => item.id !== row.id));
  }, []);

  const handleFinish = useCallback((stepData, reset) => {
    setData(current => [...current, stepData]);
    setAddNew(false);
    reset();
  }, []);

  const [Stepper] = useStepper({
    stepPages,
    onFinish: handleFinish
  });

  return (
    <Grid container spacing={3} py={3}>
      <Grid item xs={12}>
        {addNew ? (
          <Stepper />
        ) : (
          <ManagerComplementaryData
            data={data}
            onAdd={handleAdd}
            onRemove={handleRemove}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default wrapper(Steps, {
  namespace: 'REQUEST_ACCESS'
});
