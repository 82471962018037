import React, { useContext } from 'react';

export const RequestAccessStepContext = React.createContext({
  step: () => {},
  setStep: () => {},
  stepperData: {}
});

export const useRequestAccessStepContext = () =>
  useContext(RequestAccessStepContext);
