import React, { useCallback } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';

import history from 'framework/history';

import { globalization, useLangContext } from 'components/Globalization';

import { AUTH_ROUTES } from 'modules/Auth/constants/routes';

const PasswordUpdatedPopUp = ({ show }) => {
  const translate = useLangContext();

  const onClickHandler = useCallback(() => {
    history.push(AUTH_ROUTES.LOGIN);
  }, []);

  return (
    <Dialog open={show}>
      <DialogContent>
        <DialogContentText>{translate('PASSWORD_UPDATED')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickHandler}>{translate('LOGIN')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const globalized = globalization(PasswordUpdatedPopUp, 'PASSWORD_UPDATED');

export default globalized;
