import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { Box, Button, Grid, Typography } from '@mui/material';

import Checkbox from 'components/Checkbox';
import Form from 'components/Form';
import FormControlWrapper from 'components/FormControlWrapper';
import { useLangContext } from 'components/Globalization';
import PdfPreviewModal from 'components/PdfPreviewModal';
import ReCAPTCHAInput from 'components/ReCAPTCHAInput';
import TextField from 'components/TextField';
import wrapper from 'components/Wrapper';

import termsOfUse from 'assets/termsOfService/termsOfUse.pdf';

import usePasswordValidation from './fragments/PasswordValidation';
import PasswordValidationDisplay from './fragments/PasswordValidationDisplay';
import { Container } from './style';

const CreatePasswordForm = ({
  children,
  cpf,
  onSubmit,
  customValidation = {}
}) => {
  const translate = useLangContext();
  const recaptchaRef = useRef();
  const [showModal, setShowModal] = useState(false);

  const { validation, onPasswordChangeHandler, CreatePasswordSchema } =
    usePasswordValidation(translate);

  return (
    <Container>
      <PdfPreviewModal
        title={translate('TERMS_OF_USE_MODAL_TITLE')}
        file={termsOfUse}
        open={showModal}
        onClose={() => setShowModal(false)}
      />

      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h4" color="primary">
            {translate('NEW_PASSWORD')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            {translate('FORM_FILLING')}
          </Typography>
        </Grid>
      </Grid>

      <Form
        defaultValues={{ cpf }}
        onSubmit={onSubmit}
        schema={CreatePasswordSchema(customValidation)}
      >
        {formMethods => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="cpf"
                formMethods={formMethods}
                label={translate('DOCUMENT')}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="password"
                name="password"
                label={translate('PASSWORD')}
                placeholder={translate('PASSWORD_PLACEHOLDER')}
                onChange={onPasswordChangeHandler}
                formMethods={formMethods}
                maxLength={20}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="password"
                name="password_confirmation"
                label={translate('REPEAT_PASSWORD')}
                placeholder={translate('REPEAT_PASSWORD_PLACEHOLDER')}
                formMethods={formMethods}
                maxLength={20}
              />
            </Grid>

            <Grid item xs={12}>
              {children ? children(formMethods) : null}
            </Grid>

            <Grid item xs={12}>
              <ReCAPTCHAInput formMethods={formMethods} ref={recaptchaRef} />
            </Grid>

            <Grid item xs={12}>
              <PasswordValidationDisplay {...validation} />
            </Grid>

            <Grid item xs={12}>
              <Box style={{ marginLeft: '3px' }}>
                <FormControlWrapper name="termsOfUse" formMethods={formMethods}>
                  <Checkbox
                    name="termsOfUse"
                    formMethods={formMethods}
                    label={
                      <Trans
                        t={translate}
                        i18nKey="TERMS_OF_USE"
                        components={{
                          Link: (
                            <Button
                              variant="body2"
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowModal(true);
                              }}
                            />
                          )
                        }}
                      />
                    }
                  />
                </FormControlWrapper>
              </Box>
            </Grid>

            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item>
                <Button type="submit">{translate('SAVE')}</Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Form>
    </Container>
  );
};

export default wrapper(CreatePasswordForm, {
  namespace: 'CREATE_PASSWORD'
});
