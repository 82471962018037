/* eslint-disable react/prop-types */
import React from 'react';

import { Button, Grid } from '@mui/material';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '../Dialog';

export const ConfirmDialog = ({
  open,
  title,
  confirmButtonText = 'Sim',
  cancelButtonText = 'Não',
  onConfirm,
  onCancel,
  children
}) => (
  <Dialog open={open}>
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button data-testid="btnCancel" variant="outlined" onClick={onCancel}>
            {cancelButtonText}
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-testid="btnConfirm"
            variant="contained"
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
);
