import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { ListItemText } from '@mui/material';

export const MenuContainer = styled.div`
  width: 240px;
`;

export const NavBrand = styled.img`
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
`;

export const MenuLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
  text-decoration: none;

  ${props => css`
    color: ${props.theme.palette.grey[400]};
  `}
`;

export const MenuItem = styled(ListItemText)`
  color: ${props => props.theme.textColors.primaryPastel01};
`;
