/* eslint-disable class-methods-use-this */
import { Auth, Hub } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import * as qs from 'qs';

import { LocalStorage } from '../../../adapters/localStorage';
import { AUTH_API } from './api';

export default class AuthService {
  /**
   * @param {object} props
   * @param {import('config/http/axios').Axios} props.http
   */
  constructor({ http }) {
    this.http = http;
    this.storage = new LocalStorage();
    this.COGNITO_SINGIN_KEY_PREPPEND = 'CognitoIdentityServiceProvider';
    this.AMPLIFY_SIGNIN_KEY = 'amplify-signin-with-hostedUI';
    this.ME_FAILED_KEY = 'ME_FAILED';
    this.WAITING_GOV_BR_LOGOUT = 'WAITING_GOV_BR_LOGOUT';
  }

  async signIn(username, password) {
    return Auth.signIn(username, password);
  }

  async signOut() {
    sessionStorage.clear();
    await Auth.signOut();
  }

  async accessTokenLogin(jwt, token, username) {
    const idTokenDecode = jwt_decode(token.id_token);
    if (
      !this.storage.get(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.accessToken`
      )
    ) {
      this.storage.set(this.AMPLIFY_SIGNIN_KEY, false);
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.LastAuthUser`,
        username
      );
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.idToken`,
        token.id_token
      );
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.accessToken`,
        token.access_token
      );
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.refreshToken`,
        token.refresh_token
      );
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.clockDrift`,
        '0'
      );
      this.storage.set(
        `${this.COGNITO_SINGIN_KEY_PREPPEND}.${jwt.client_id}.${username}.userData`,
        {
          UserAttributes: [
            {
              Name: 'sub',
              Value: jwt.sub
            },
            {
              Name: 'email_verified',
              Value: 'true'
            },
            {
              Name: 'email',
              Value: idTokenDecode.email
            },
            {
              Name: 'provider',
              Value: idTokenDecode.identities[0].providerName
            }
          ],
          Username: username
        }
      );
    }

    const session = await this.restoreUserSession();

    return {
      user: {
        username
      },
      session
    };
  }

  async awsLogout() {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.error('[awsLogout-ERROR]', error);
    }
  }

  async govBrSignOut() {
    this.getForceLogoutUrl();
  }

  clearAuthStorage() {
    const keys = Object.keys(this.storage.getStorage());

    // eslint-disable-next-line no-restricted-syntax
    for (const element of keys) {
      if (
        element.includes(this.COGNITO_SINGIN_KEY_PREPPEND) ||
        element.includes(this.AMPLIFY_SIGNIN_KEY)
      ) {
        this.storage.remove(element);
      }
    }
  }

  async completeNewPassword({ challenge, password }) {
    await Auth.completeNewPassword(
      challenge,
      password,
      null,
      challenge.challengeParam.userAttributes
    );
  }

  async restoreUserSession() {
    try {
      const userSession = await Auth.currentAuthenticatedUser();
      return await userSession.getSignInUserSession();
    } catch (error) {
      console.error('[restoreUserSession-ERROR]', error);
      return null;
    }
  }

  async validateRecaptcha(token) {
    return this.http.post(AUTH_API.RECAPTCHA_VALIDATION, {
      gRecaptchaResponse: token,
      gRecaptchaVersion: 'v2'
    });
  }

  async transactionToken() {
    return this.http.get(AUTH_API.TRANSACTION_TOKEN);
  }

  async getUserToken() {
    try {
      const currentSession = await Auth.currentSession();

      if (!currentSession.isValid()) return null;

      return currentSession.getIdToken().getJwtToken();
    } catch (error) {
      return null;
    }
  }

  registerListener(event, listener) {
    Hub.listen(event, listener);
  }

  getForceLogoutUrl() {
    window.location.href = `https://${window.env.REACT_APP_AUTH_URL}/logout?response_type=code&client_id=${window.env.REACT_APP_COGNITO_CLIENT_ID_NO_SECRET}&logout_uri=${window.env.REACT_APP_FRONTEND_URL}/logout&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin`;
  }

  setMeFailedFlag() {
    this.storage.set(this.ME_FAILED_KEY, true);
  }

  unsetMeFailedFlag() {
    this.storage.remove(this.ME_FAILED_KEY);
  }

  getMeFailedFlag() {
    return this.storage.get(this.ME_FAILED_KEY);
  }

  setIsWaitingGovBrAuthLogout() {
    this.storage.set(this.WAITING_GOV_BR_LOGOUT, true);
  }

  unsetIsWaitingGovBrAuthLogout() {
    this.storage.remove(this.WAITING_GOV_BR_LOGOUT);
  }

  getIsWaitingGovBrFlag() {
    return this.storage.get(this.WAITING_GOV_BR_LOGOUT);
  }

  async getFullToken(code) {
    const url = `https://${window.env.REACT_APP_AUTH_URL}/oauth2/token`;
    const data = {
      code,
      grant_type: 'authorization_code',
      redirect_uri: `${window.env.REACT_APP_FRONTEND_URL}/token`,
      client_id: window.env.REACT_APP_COGNITO_CLIENT_ID_NO_SECRET
    };
    const options = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    };

    try {
      return this.http.post(url, qs.stringify(data), options);
    } catch (e) {
      return null;
    }
  }

  isAuthenticated() {
    return this.storage.get(
      `${this.COGNITO_SINGIN_KEY_PREPPEND}.${window.env.REACT_APP_COGNITO_CLIENT_ID_NO_SECRET}.LastAuthUser`
    );
  }
}
