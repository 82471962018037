import { createSlice } from '@reduxjs/toolkit';

import { ROLES } from 'helpers/roles';

export const AUTH_REDUCER = 'auth';

const hasRole = (user, role) => user?.roleList?.includes(role);

const auth = createSlice({
  name: AUTH_REDUCER,
  initialState: {
    isAuthenticated: false,
    user: undefined,
    challenge: undefined,
    session: undefined,
    securityQuestionsAnswered: undefined,
    dataProcessingAgreement: undefined,
    meFailed: false,
    isWaitingGovBrLogout: false,
    userGovBr: {},
    userNeedsUpdate: undefined
  },
  reducers: {
    signIn: (state, { payload }) => {
      state.user = payload.user;
      state.session = payload.session;
      state.isAuthenticated = true;
      state.securityQuestionsAnswered = payload.user.securityQuestionsAnswered;
      state.dataProcessingAgreement = true; // payload.user.dataProcessingAgreement;

      if (hasRole(payload?.user, ROLES.ROLE_TEACHER)) {
        state.role = ROLES.ROLE_TEACHER;
      } else if (hasRole(payload?.user, ROLES.ROLE_STUDENT)) {
        state.role = ROLES.ROLE_STUDENT;
      } else {
        state.role = ROLES.ROLE_NEW_USER;
      }
    },
    signOut: state => {
      state.user = undefined;
      state.session = undefined;
      state.role = undefined;
      state.securityQuestionsAnswered = undefined;
      state.dataProcessingAgreement = undefined;
      state.isAuthenticated = false;
    },
    challengeUser: (state, { payload }) => {
      state.challenge = payload;
    },
    me: (state, { payload }) => {
      state.user = payload;
      state.securityQuestionsAnswered = payload.securityQuestionsAnswered;
      state.dataProcessingAgreement = true; // payload.dataProcessingAgreement;
    },
    meFailed: (state, { payload }) => {
      state.meFailed = payload;
    },
    isWaitingGovBrLogout: (state, { payload }) => {
      state.isWaitingGovBrLogout = payload;
    },
    setUserGovBr: (state, { payload }) => {
      state.userGovBr = payload;
    },
    userNeedsUpdate: (state, { payload }) => {
      state.userNeedsUpdate = payload;
    }
  }
});

export const authActions = auth.actions;

export default auth.reducer;
