export const ROLES = {
  ROLE_BOOK_TEAM: 'ROLE_BOOK_TEAM',
  ROLE_LIBRARIAN: 'ROLE_LIBRARIAN',
  ROLE_TEACHER: 'ROLE_TEACHER',
  ROLE_STUDENT: 'ROLE_STUDENT'
};

export const ROLES_OPTIONS = Object.keys(ROLES).map(role => ({
  id: role,
  name: `ROLES:${role}`
}));
