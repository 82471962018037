import styled from 'styled-components';

export const Box = styled.div`
  background: ${props => props.theme.palette.background.white};
  border: 1px solid ${props => props.theme.palette.border.primary};
  border-radius: 4px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 48px 0;
  width: 100%;
`;
