import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const PasswordValidation = styled.p`
  display: flex;
  align-items: center;
  margin: 0px;

  color: ${props =>
    props.isValid
      ? props.theme.palette.success.main
      : props.theme.palette.grey[600]};

  & svg {
    margin-right: 5px;
  }
`;
