import React, { useState } from 'react';

import { Button, Container, Grid, Typography } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { notificationUseCases } from 'modules/Notification/providers';

import { useRequestAccessStepContext } from '../../fragments/Context';
import ModalUser from './DataUsageModal';
import TermsOfUseContent from './fragments/TermsOfUseContent';

const TermsOfUse = () => {
  const translate = useLangContext();
  const { setStep } = useRequestAccessStepContext();
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <ModalUser
        open={openModal}
        handleModal={() => setOpenModal(false)}
        userData={null}
        userRole={null}
      />
      <Container>
        <Grid container spacing={3} py={3}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h1">
              {translate('TERMS_OF_USE')}
            </Typography>
          </Grid>
          <Grid borderRadius={12} item xs={12}>
            <TermsOfUseContent />
          </Grid>
          <Grid item container justifyContent="space-between" xs={12}>
            <Button
              onClick={() => setOpenModal(true)}
              variant="outlined"
              id="btn-refuse"
            >
              Termo de Consentimento de Uso dos dados
            </Button>
            <Grid justifyContent="flex-end">
              <Button
                onClick={() =>
                  notificationUseCases.warning('TERMS_OF_USE_REFUSED')
                }
                variant="outlined"
                id="btn-refuse"
              >
                {translate('COMMONS:REFUSE')}
              </Button>
              <Button
                variant="contained"
                onClick={() => setStep(1)}
                style={{ marginLeft: '20px' }}
                id="btn-accept"
              >
                {translate('COMMONS:ACCEPT')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default wrapper(TermsOfUse, {
  namespace: 'REQUEST_ACCESS'
});
