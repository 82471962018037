/* eslint-disable no-return-await */
import { isArray } from 'lodash';

import {
  addNotification,
  closeNotification,
  removeNotification
} from 'framework/store/slices/notification.slice';
import ErrorTranslation from 'managers/errorTranslation';

export default class NotificationUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   */
  constructor({ store }) {
    this.store = store;
    this.errorTranslation = new ErrorTranslation();
  }

  /**
   * @param {string} error
   */
  async error(error, optionsProp = {}) {
    const { namespace, ...options } = optionsProp;

    const humanized = await this.errorTranslation.humanize(
      error?.response?.data || error,
      namespace
    );

    if (isArray(humanized)) {
      return await Promise.all(
        humanized.map(
          async message => await this.newNotification(message, 'error', options)
        )
      );
    }

    await this.newNotification(humanized, 'error', options);

    return humanized;
  }

  async success(message, options = {}) {
    await this.newNotification(message, 'success', options);
  }

  async warning(message, options = {}) {
    await this.newNotification(message, 'warning', options);
  }

  async newNotification(message, type, options = {}) {
    await this.store.dispatch(
      addNotification({
        message,
        options: {
          variant: type,
          ...options
        }
      })
    );
  }

  /**
   * @param {string} id
   */
  async closeNotification(key) {
    await this.store.dispatch(
      closeNotification({
        dismissAll: !key, // dismiss all if no key has been defined
        key
      })
    );
  }

  /**
   * @param {string} id
   */
  async removeNotification(id) {
    await this.store.dispatch(removeNotification(id));
  }
}
