export const USER_API = {
  ME: '/users/me',
  CREATE: '/users',
  RESET_PASSWORD: '/users/reset-password',
  FIND_USER_BY_TOKEN: '/users/reset-password/{token}/user',
  CHANGE_PASSWORD: '/users/change-password',
  CREATE_PASSWORD: '/users/create-password',
  FIRST_ACCESS: '/users/{id}/first-access',
  SET_FIRST_ACCESS: '/users/first-access',
  DEACTIVATE_USER: '/users/{id}/deactivate',
  RESTORE_USER: '/users/{id}/restore',
  DELETE_USER: '/users/{id}',
  USERS: '/users',
  USERS_SEARCH: 'users/search',
  SECURITY_QUESTIONS: '/users/security-questions',
  SECURITY_QUESTIONS_ANSWER: '/users/security-questions/answers',
  DATA_PROCESSING: '/data-processing-agreements',
  DATA_PROCESSING_AGREEMENT: '/data-processing-agreements/checking',
  PATCH_CHANGE_STATUS: '/users/enable',
  PUT_RESET_CREDENTIALS: '/admin/reset-credentials/users/{id}',
  GET_FNDE_USER_LIST: '/users/fnde',
  GET_ROLES: '/users/roles',
  GET_USERS_PROFILES: '/users/created/roles',
  STUDENT_NEEDS_UPDATE: '/users/student-grade-data-is-updated'
};
