import React from 'react';

import { useIsMobile } from 'hooks/useIsMobile';

import { MobileGrid, StyledTableCell } from '../../styles';
import DeleteButton from '../DeleteButton';
import EditButton from '../EditButton';

const ActionButtons = ({ row, redirectEdit, onDelete }) => {
  const isMobile = useIsMobile();

  const buttons = (
    <StyledTableCell align="left" order={6}>
      <EditButton row={row} redirect={redirectEdit} />
      <DeleteButton row={row} onClick={onDelete} />
    </StyledTableCell>
  );
  if (!isMobile) {
    return buttons;
  }
  return (
    <MobileGrid container item sx={{ flexGrow: 1 }}>
      {buttons}
    </MobileGrid>
  );
};

export default ActionButtons;
