import React, { useCallback, useState } from 'react';

import { useQueryParams } from 'hooks/useQueryParams';

import { Container, Typography, Grid, Box } from '@mui/material';

import { AsyncLoad } from 'components/AsyncLoad';
import { useLangContext } from 'components/Globalization';
import SelectField from 'components/SelectField';
import wrapper from 'components/Wrapper';

import { DOWNLOAD_WORK_ROUTES } from './constants/routes';
import { downloadWorkUseCases } from './provider';

const Download = () => {
  const query = useQueryParams();
  const downloadType = Object.keys(query).join();
  const downloadId = query[downloadType];

  const [options, setOptions] = useState([]);

  const downloadFile = useCallback(async documentId => {
    try {
      const signedUrl = await downloadWorkUseCases.getFileUrl(documentId);

      window.open(signedUrl, '_blank');
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchData = useCallback(async () => {
    if (downloadType === 'subscription_volume_id') {
      try {
        const response = await downloadWorkUseCases.getVolume(downloadId);
        setOptions([
          {
            name: `${response.volumeCode} - ${response.grade} - ${response.description}`,
            id: response.document_id
          }
        ]);
        downloadFile(response.document_id);
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        const response = await downloadWorkUseCases.getCollections(downloadId);
        setOptions(
          response.map(option => ({
            name: `${option.volumeCode} - ${option.grade} - ${option.description}`,
            id: option.document_id
          }))
        );
      } catch (e) {
        console.error(e);
      }
    }
  }, [downloadId, downloadType, downloadFile]);

  const translate = useLangContext();

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mt={10}>
            <Typography variant="h4">{translate('SELECT_VOLUME')}</Typography>
            <Typography variant="subtitle1">
              {translate('DESCRIPTION')}
            </Typography>
          </Box>
        </Grid>
        {options && (
          <AsyncLoad promiseFn={fetchData}>
            <Grid item xs={12}>
              <Box mb={30}>
                <SelectField
                  name="city"
                  idKey="id"
                  label={translate('SELECT_VOLUME')}
                  placeholder="Selecione"
                  displayEmpty
                  defaultValue={
                    downloadType === 'subscription_volume_id' && options[0]?.id
                  }
                  onChange={e => downloadFile(e.target.value)}
                  options={options}
                  style={{ width: '100%' }}
                />
              </Box>
            </Grid>
          </AsyncLoad>
        )}
      </Grid>
    </Container>
  );
};

export default wrapper(Download, {
  namespace: 'DOWNLOAD_WORK',
  route: { path: DOWNLOAD_WORK_ROUTES.DOWNLOAD_WORK }
});
