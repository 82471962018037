import styled from 'styled-components';

import { Button, Grid, Typography } from '@mui/material';

export const TitlePage = styled.h1`
  color: ${props => props.theme.bgColors.colorSecondary08};
  margin: 32px 0;
`;

export const TitleStep = styled.h1`
  color: ${props => props.theme.palette.text.primary};
  font-weight: normal;
  line-height: 40px;
  margin: 0 0 24px;
`;

export const Box = styled.div`
  background: ${props => props.theme.palette.background.white};
  border: 1px solid ${props => props.theme.palette.border.primary};
  border-radius: 4px;
  box-shadow: 4px 4px 4px rgba(0; 0, 0, 0.1);
  padding: 24px;
  margin: 48px 0;
  width: 100%;
`;

export const Wrapper = styled(Grid)`
  position: relative;
  margin-bottom: 48px;

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledActionButton = styled(Button)`
  padding: 8px 24px;
  font-size: 16px;
  line-height: 20px;
`;

export const StyledCancelButton = styled(StyledActionButton)`
  margin-right: 8px;
`;

export const Card = styled.div`
  background: ${props => props.theme.palette.background.white};
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 458px;
  box-shadow: 0px 1px 6px rgba(51, 51, 51, 0.16);

  ${props => props.theme.breakpoints.down('sm')} {
    height: auto;
    flex-direction: column;
    width: 80%;
  }
`;

export const TitleCard = styled.p`
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-top: 40px;
`;

export const DescriptionCard = styled.span`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
  width: 60%;
`;

export const StyledImage = styled.img`
  width: 85%;

  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  color: ${props => props.theme.palette.primary.main};
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  padding: 8px 24px;
  border: 1px solid ${props => props.theme.palette.primary.main};
  border-radius: 20px;
  background-color: ${props => props.theme.palette.background.white};

  cursor: pointer;
  position: absolute;
  bottom: 16px;
  opacity: ${props => props.disabled && `0.35`};

  ${props => props.theme.breakpoints.down('md')} {
    position: relative;
    margin-top: 42px;
  }
`;

export const FormTitle = styled(Typography)`
  line-height: 24px;
  font-weight: 700;
`;
