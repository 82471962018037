import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import pdfjsWorker from 'react-pdf/dist/esm/pdf.worker.entry';

import range from 'lodash/range';

import ContainerWidth from 'components/ContainerWidth';
import { Dialog, DialogContent, DialogTitle } from 'components/Dialog';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfPreviewModal = ({ file, title = '', open, onClose: handleClose }) => {
  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages: pageCount }) => {
    setNumPages(pageCount);
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      <DialogContent>
        <ContainerWidth>
          {width => (
            <Document
              wrap={false}
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {range(numPages).map(pageIndex => (
                <Page key={pageIndex} width={width} pageIndex={pageIndex} />
              ))}
            </Document>
          )}
        </ContainerWidth>
      </DialogContent>
    </Dialog>
  );
};

export default PdfPreviewModal;
