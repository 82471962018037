import React from 'react';

import { useIsMobile } from 'hooks/useIsMobile';

import { useLangContext } from 'components/Globalization';

import { MobileGrid, StyledTableCell, Tag } from '../../styles';

const CustomTableRow = ({ row }) => {
  const isMobile = useIsMobile();
  const translate = useLangContext();

  const tableRows = (
    <>
      <StyledTableCell order={1}>
        {row.school} - {row.inep}
      </StyledTableCell>
      <StyledTableCell align="left" order={3}>
        {row.uf}
      </StyledTableCell>
      <StyledTableCell align="left" order={4}>
        {row.cityName}
      </StyledTableCell>
      <StyledTableCell align="left" order={5}>
        {row.learningLevelName}
      </StyledTableCell>
      <StyledTableCell align="center" order={2}>
        <Tag>{translate(row.requestStatus)}</Tag>
      </StyledTableCell>
    </>
  );

  if (!isMobile) {
    return tableRows;
  }
  return (
    <MobileGrid container item sx={{ flexDirection: 'column', flexGrow: 3 }}>
      {tableRows}
    </MobileGrid>
  );
};

export default CustomTableRow;
