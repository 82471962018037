import React from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import { Home } from '@mui/icons-material';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Container,
  IconButton
} from '@mui/material';

import history from 'framework/history';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { StyledLink, StyledNavigateNext, StyledText } from './styles';

const Crumb = ({ isLast, match, children }) => {
  const translate = useLangContext();

  const crumbContent = translate(
    `${children.props.children.toUpperCase().replace(/-/g, '_')}`
  );

  return isLast ? (
    <StyledText component="span">{crumbContent}</StyledText>
  ) : (
    <StyledLink to={match.url}>{crumbContent}</StyledLink>
  );
};

const Breadcrumbs = ({ breadcrumbs }) => (
  <Container maxWidth="xl">
    <div style={{ margin: '24px 0' }}>
      <MuiBreadcrumbs
        separator={<StyledNavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        <IconButton onClick={() => history.push('/')}>
          <Home />
        </IconButton>

        {breadcrumbs
          .filter(item => item.key !== '/')
          .map(({ match, breadcrumb }, index) => {
            const isLast = index === breadcrumbs.length - 2;

            return (
              <Crumb key={match.url} isLast={isLast} match={match}>
                {breadcrumb}
              </Crumb>
            );
          })}
      </MuiBreadcrumbs>
    </div>
  </Container>
);
export default wrapper(withBreadcrumbs()(Breadcrumbs), {
  namespace: 'BREADCRUMBS'
});
