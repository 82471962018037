import { useMemo } from 'react';

import { get } from 'lodash';

import { useLangContext } from 'components/Globalization';

const useFormError = (errors, name, supressErrorText = false) => {
  const translate = useLangContext();

  const error = get(errors, name);

  const errorProps = useMemo(() => {
    if (!error) return {};

    return {
      error: true,
      helperText:
        !supressErrorText &&
        (typeof error.message === 'string'
          ? translate(error.message)
          : translate(error.message.key, error.message.params))
    };
  }, [error, supressErrorText, translate]);

  return errorProps;
};

export default useFormError;
