import styled from 'styled-components';

import WarningTag from 'components/Warnings/fragments/Tag';

export const TitlePage = styled.h1`
  color: ${props => props.theme.bgColors.colorSecondary08};
  margin: 32px 0;
`;

export const TitleStep = styled.h1`
  color: ${props => props.theme.palette.text.primary};
  font-weight: normal;
  line-height: 40px;
  margin: 0 0 24px;
`;

export const Box = styled.div`
  background: ${props => props.theme.palette.background.white};
  border: 1px solid ${props => props.theme.palette.border.primary};
  border-radius: 4px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 48px 0;
  width: 100%;
`;

export const StyledWarningTag = styled(WarningTag)`
  min-width: 100%;
`;
