import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Apps } from '@mui/icons-material';
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material';

import { useLangContext } from 'components/Globalization';

import { REQUEST_ACCESS_ROUTES } from 'modules/V2_RequestAccess/constants/routes';

import { Container } from './styles';

const Shortcut = () => {
  const translate = useLangContext();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const handleToggle = () => {
    setOpen(isPrevOpen => !isPrevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Container>
      <Apps
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        variant="text"
        onClick={handleToggle}
        color="secondary"
        style={{ marginRight: '12px' }}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() =>
                      history.push(REQUEST_ACCESS_ROUTES.SCHOOL_MANAGEMENT)
                    }
                  >
                    {translate('SCHOOL_MANAGEMENT:SCHOOL_MANAGEMENT')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Container>
  );
};

export default Shortcut;
