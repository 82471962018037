import styled from 'styled-components';

import { APP_MEASURES } from 'modules/App/constants/measures';

export const ContentWrapper = styled.div`
  background: ${props => props.theme.palette.background.light};
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 32px;
  min-height: ${`calc(100vh - ${
    APP_MEASURES.HEADER_HEIGHT + APP_MEASURES.FOOTER_HEIGHT
  }px)`};
`;
