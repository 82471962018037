import React, { useState, useCallback } from 'react';

import { useStepperContext } from 'hooks/useStepper/StepperContext';

import { Button, Grid } from '@mui/material';

import { AsyncLoad } from 'components/AsyncLoad';
import { useLangContext } from 'components/Globalization';
import SelectField from 'components/SelectField';
import wrapper from 'components/Wrapper';

import { requestAccessUseCases } from '../../../../provider';
import { Box } from '../../../../styles';

const SelectSchool = () => {
  const translate = useLangContext();
  const { fnBack, fnNext, stepperData, fnSetDataByKey } = useStepperContext();
  const [school, setSchool] = useState();
  const [schoolOptions, setSchoolOptions] = useState([]);

  const fetchSchoolOptions = useCallback(async () => {
    try {
      const response = await requestAccessUseCases.getSchoolList(
        stepperData.city.id
      );
      setSchoolOptions(
        response.map(({ inep, schoolName }) => ({ id: inep, name: schoolName }))
      );
    } catch (error) {
      console.error(error);
    }
  }, [stepperData.city.id]);

  const handleSelectedSchool = () => {
    const { id, name } = schoolOptions.find(item => item.id === school);
    fnSetDataByKey('school', { id, name });
    fnNext();
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AsyncLoad promiseFn={fetchSchoolOptions}>
            <SelectField
              name="school"
              idKey="id"
              label={translate('SCHOOL')}
              placeholder={translate('SCHOOL_PLACEHOLDER')}
              displayEmpty
              options={schoolOptions}
              onChange={evt => {
                setSchool(evt.target.value);
              }}
              style={{ width: '100%' }}
            />
          </AsyncLoad>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button variant="outlined" onClick={() => fnBack()}>
            {translate('COMMONS:BACK')}
          </Button>
          <Button
            variant="contained"
            disabled={!school}
            style={{ marginLeft: '15px' }}
            onClick={() => handleSelectedSchool()}
          >
            {translate('COMMONS:CONTINUE')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default wrapper(SelectSchool, {
  namespace: 'REQUEST_ACCESS'
});
