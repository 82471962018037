import { useEffect, useState } from 'react';

/**
 * Accepted types in __useStorageState__ hook's storage param.
 * @enum {Storage}
 */
export const STORAGE_STATE_TYPES = {
  LOCAL: localStorage,
  SESSION: sessionStorage
};

/**
 * Used to synchronize a state with a Browser's Storages item.
 * @param {string} key of the `Storage`'s item.
 * @param {T} defaultValue applied to state if item is null or empty.
 * @param {STORAGE_STATE_TYPES} storage
 * @returns {[T, React.SetStateAction<T>]} a __tuple__ including the local
 * `state` and it's `setState`.
 * @template T
 */
export default function useStorageState(
  key,
  defaultValue,
  storage = STORAGE_STATE_TYPES.LOCAL
) {
  const [value, setValue] = useState(() => {
    let parsedValue;
    if (key?.length > 0) {
      const rawValue = storage.getItem(key);
      if (rawValue && rawValue.length > 0) {
        try {
          parsedValue = JSON.parse(rawValue);
        } catch {
          parsedValue = defaultValue;
        }
      } else parsedValue = defaultValue;
    } else parsedValue = defaultValue;
    return parsedValue;
  });

  useEffect(() => {
    const json = JSON.stringify(value);
    storage.setItem(key, json);
  }, [key, value, storage]);

  return [value, setValue];
}

/**
 * Used to synchronize a state with a Browser's LocalStorage item.
 * @param {string} key of the `window.LocalStorage`'s item.
 * @param {T} defaultValue applied to state if item is null or empty.
 * @param {STORAGE_STATE_TYPES} storage
 * @returns {[T, React.SetStateAction<T>]} a __tuple__ including the local
 * `state` and it's `setState`.
 * @template T
 */
export const useLocalStorageState = (key, defaultValue) =>
  useStorageState(key, defaultValue, STORAGE_STATE_TYPES.LOCAL);

/**
 * Used to synchronize a state with a Browser's SessionStorage item.
 * @param {string} key of the `window.SessionStorage`'s item.
 * @param {T} defaultValue applied to state if item is null or empty.
 * @param {STORAGE_STATE_TYPES} storage
 * @returns {[T, React.SetStateAction<T>]} a __tuple__ including the local
 * `state` and it's `setState`.
 * @template T
 */
export const useSessionStorageState = (key, defaultValue) =>
  useStorageState(key, defaultValue, STORAGE_STATE_TYPES.SESSION);
