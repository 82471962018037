import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();

  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return Array.from(searchParams.entries()).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {}
    );
  }, [search]);
  return queryParams;
};
