import React, { useCallback, useState } from 'react';

import { Container, Grid, Typography } from '@mui/material';

import history from 'framework/history';
import { ROLES } from 'helpers/roles';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { INITIAL_PAGE_ROUTES } from 'modules/InitialPage/constants/routes';

import logoStudent from 'assets/img/logo-student.png';
import logoTeacher from 'assets/img/logo-teacher.png';

import { REQUEST_STATUS } from '../../constants/requestStatusEnum';
import { REQUEST_ACCESS_ROUTES } from '../../constants/routes';
import { useRequestAccessContext } from '../../Context';
import { requestAccessUseCases } from '../../provider';
import {
  Card,
  DescriptionCard,
  StyledButton,
  StyledImage,
  TitleCard,
  Wrapper
} from '../../styles';

const rolesMap = [
  {
    role: ROLES.ROLE_TEACHER,
    requestPage: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS_TEACHER,
    managementPage: REQUEST_ACCESS_ROUTES.SCHOOL_MANAGEMENT,
    fetchData: () => requestAccessUseCases.getRequestAccessTeacher(),
    steps: {
      city: 0,
      inep: 1,
      learningLevel: 2,
      default: 3
    }
  },
  {
    role: ROLES.ROLE_STUDENT,
    requestPage: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS
  }
];

const SelectProfile = () => {
  const translate = useLangContext();
  const [isLoading, setIsLoading] = useState(false);

  const { setRequestData } = useRequestAccessContext();

  const profiles = [
    {
      id: ROLES.ROLE_STUDENT,
      name: translate('STUDENT'),
      image: logoStudent,
      description: translate('STUDENT_DESCRIPTION')
    },
    {
      id: ROLES.ROLE_TEACHER,
      name: translate('TEACHER'),
      image: logoTeacher,
      description: translate('TEACHER_DESCRIPTION')
    }
  ];

  const fetchData = useCallback(async service => {
    setIsLoading(true);
    try {
      const resp = await service();
      return resp;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    return null;
  }, []);

  const getStep = useCallback((firstRequest, role) => {
    const currentMap = rolesMap.find(map => map.role === role);

    const firstNullKey = Object.entries(firstRequest).find(
      ([key, value]) => key !== 'id' && !value
    );

    if (!firstNullKey) {
      return currentMap.steps.default;
    }
    return currentMap.steps[firstNullKey];
  }, []);

  const redirectToIdentify = email => {
    if (!email) history.push(REQUEST_ACCESS_ROUTES.IDENTIFY_USER);
  };

  const handleSelectProfile = useCallback(
    async role => {
      const selectedRole = rolesMap.find(item => item.role === role);

      const requestedData = await fetchData(selectedRole.fetchData);

      if (role === ROLES.ROLE_STUDENT) {
        history.push(REQUEST_ACCESS_ROUTES.REQUEST_ACCESS);
      }

      if (!requestedData && role !== ROLES.ROLE_STUDENT) {
        return redirectToIdentify();
      }

      const firstRequest = requestedData.content[0];

      if (
        requestedData.totalElements > 1 ||
        firstRequest?.requestStatus === REQUEST_STATUS.PENDING ||
        firstRequest?.requestStatus === REQUEST_STATUS.DISAPPROVED
      ) {
        return history.push(selectedRole.managementPage);
      }

      if (firstRequest?.requestStatus === REQUEST_STATUS.APPROVED) {
        return history.push(INITIAL_PAGE_ROUTES.INITIAL_PAGE);
      }

      if (!firstRequest?.email) {
        return redirectToIdentify();
      }

      const initialStep = getStep(firstRequest, role);

      setRequestData({
        ...firstRequest,
        role: selectedRole.role,
        initialStep
      });

      return history.push(selectedRole.requestPage);
    },
    [fetchData, getStep, setRequestData]
  );

  return (
    <Container>
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: 4,
          flexDirection: 'column'
        }}
      >
        <Typography variant="h4" textAlign="center" component="h1">
          {translate('SELECT_PROFILE_TITLE')}
        </Typography>
      </Grid>
      <Wrapper container spacing={5} justifyContent="center">
        {profiles?.map(profile => (
          <Grid
            item
            key={profile}
            xs={12}
            lg={4}
            display="flex"
            justifyContent="center"
          >
            <Card>
              <StyledImage src={profile.image} alt={profile.name} />
              <TitleCard>{profile?.name}</TitleCard>
              <DescriptionCard>{profile?.description}</DescriptionCard>
              <StyledButton
                disabled={isLoading || profile.disabled}
                onClick={() => handleSelectProfile(profile.id)}
              >
                {translate('REGISTER_PROFILE')}
              </StyledButton>
            </Card>
          </Grid>
        ))}
      </Wrapper>
    </Container>
  );
};

export default wrapper(SelectProfile, {
  namespace: 'SELECT_PROFILE',
  mapState: state => ({
    userGovBr: state.auth.userGovBr
  }),
  route: { path: REQUEST_ACCESS_ROUTES.SELECT_PROFILE },
  breadcrumb: 'SELECT_PROFILE'
});
