import UserService from 'services/providers/user/service';

import { http } from 'adapters';
import store from 'framework/store/store';

import UserUseCases from './useCases';

export const userService = new UserService({ http });

export const userUseCases = new UserUseCases({
  store,
  userService
});
