import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Trans } from 'react-i18next';

import { isArray } from 'lodash';

import {
  Box,
  Table,
  TableContainer,
  TablePagination,
  Typography
} from '@mui/material';

import Loader from 'components/Form/fragments/FormLoader';
import { useLangContext } from 'components/Globalization';

const notIsNullOrUndefined = value => {
  // !!fiz assim porque
  // 0 === false
  // '' === true
  // duvida perguntar para o Halisson
  if (value === null) return false;
  if (value === undefined) return false;
  return true;
};

export const PaginationContext = React.createContext({
  isOpen: () => {
    /* do nothing */
  },
  setOpen: () => {
    /* do nothing */
  },
  sort: () => {
    /* do nothing */
  },
  setSort: () => {
    /* do nothing */
  },
  fnRefreshPage: () => {
    /* do nothing */
  },
  fnHandleFetchData: () => {
    /* do nothing */
  },
  direction: () => {
    /* do nothing */
  },
  setDirection: () => {
    /* do nothing */
  },
  fnHandleSelectAll: () => {
    /* do nothing */
  },
  fnHandleSelectItem: () => {
    /* do nothing */
  },
  fnHandleIsSelectedItem: () => false,
  IsSelectedAll: false
});

const FooterPagination = ({
  pageIndex,
  pageSize,
  totalElements,
  onChangePage,
  onChangeRowsPerPage,
  countSelectRows = 0
}) => {
  const translate = useLangContext();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}
    >
      {countSelectRows > 0 && (
        <Trans
          variant="body2"
          parent={Typography}
          t={translate}
          i18nKey="PAGINATION:LABEL_SELECTED"
          values={{ value: countSelectRows }}
        />
      )}

      <TablePagination
        color="primary"
        component="div"
        colSpan={12}
        rowsPerPageOptions={[10, 20, 50]}
        count={totalElements}
        rowsPerPage={pageSize}
        page={pageIndex}
        SelectProps={{
          inputProps: { 'aria-label': 'Exibir' },
          native: true
        }}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelRowsPerPage={translate('PAGINATION:LABEL_ROWS_PER_PAGE')}
        nexticonbuttontext={translate('PAGINATION:NEXT_ICON_BUTTON_TEXT')}
        backiconbuttontext={translate('PAGINATION:BACK_ICON_BUTTON_TEXT')}
        labelDisplayedRows={({ page }) =>
          ` ${page + 1} ${translate('PAGINATION:LABEL_FROM')} ${Math.ceil(
            totalElements / pageSize
          )} ${translate('PAGINATION:LABEL_PAGES')}`
        }
      />
    </div>
  );
};

export const usePagination = ({
  fetchData,
  onKeyId,
  defaultColumnName,
  defaultDirection,
  onCanSelectRow = () => true
}) => {
  const translate = useLangContext();
  const prevFetchDataRef = useRef();

  const [empty, setEmpty] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [direction, setDirection] = useState(defaultDirection);
  const [sort, setSort] = useState(defaultColumnName);

  const handleSelectAdd = useCallback(
    async data => {
      const canAdd = onCanSelectRow(data);
      if (canAdd) {
        setSelectedRows(prev => {
          const items = [...prev];
          items.push(data);
          return items;
        });
      }
    },
    [onCanSelectRow]
  );

  const handleSelectRem = useCallback(data => {
    setSelectedRows(prev => prev.filter(item => item.keyId !== data.keyId));
  }, []);

  const handleSelectFindItem = useCallback(
    keyId => {
      const hasAdded = selectedRows.find(item => item.keyId === keyId);
      const selectedRow = rows.find(item => item.keyId === keyId);

      return {
        item: selectedRow || hasAdded,
        exist: !!hasAdded
      };
    },
    [rows, selectedRows]
  );

  const handleSelectItem = useCallback(
    keyId => {
      const { item, exist } = handleSelectFindItem(keyId);

      if (exist) {
        handleSelectRem(item);
        return;
      }

      if (!exist) {
        handleSelectAdd(item);
      }
    },
    [handleSelectAdd, handleSelectFindItem, handleSelectRem]
  );

  const handleIsSelectIedtem = useCallback(
    keyId => {
      const hasItem = selectedRows.find(item => item.keyId === keyId);
      return !!hasItem;
    },
    [selectedRows]
  );

  const IsSelectedAll = useMemo(() => {
    if (selectedRows.length === 0 || rows.length === 0) return false;
    const rowsKeyId = rows.map(item => item.keyId);
    const selectedRowsKeyId = selectedRows.map(item => item.keyId);
    const dif = rowsKeyId.filter(item => !selectedRowsKeyId.includes(item));

    return dif.length === 0;
  }, [rows, selectedRows]);

  const getCorrectContent = response => {
    if (isArray(response?.content)) {
      return response?.content;
    }
    if (isArray(response)) {
      return response;
    }
    return [];
  };

  const handleLoadAll = useCallback(async () => {
    const res = await fetchData({
      page: 0,
      size: totalElements
    });

    return getCorrectContent(res).map(item => ({
      keyId: onKeyId ? onKeyId(item) : item.id,
      ...item
    }));
  }, [fetchData, onKeyId, totalElements]);

  const handleSelectAll = useCallback(async () => {
    setLoading(true);
    const rowsAll = await handleLoadAll();
    setLoading(false);

    if (IsSelectedAll) {
      rowsAll.forEach(row => {
        handleSelectRem(row);
      });
    } else {
      rowsAll.forEach(row => {
        const { exist } = handleSelectFindItem(row.keyId);
        if (!exist) handleSelectAdd(row);
      });
    }
  }, [
    IsSelectedAll,
    handleLoadAll,
    handleSelectAdd,
    handleSelectFindItem,
    handleSelectRem
  ]);

  const handleFetchData = useCallback(
    async (pIndex, pSize, pSort, pDirection) => {
      const res = await fetchData({
        page: notIsNullOrUndefined(pIndex) ? pIndex : pageIndex,
        size: notIsNullOrUndefined(pSize) ? pSize : pageSize,
        sort: notIsNullOrUndefined(pSort) ? pSort : sort,
        direction: notIsNullOrUndefined(pDirection) ? pDirection : direction
      });

      const content = getCorrectContent(res).map(item => ({
        keyId: onKeyId ? onKeyId(item) : item.id,
        ...item
      }));
      setEmpty(content.length === 0);
      setRows(content);
      setTotalElements(parseInt(res?.totalElements || 0, 10) || 0);
    },
    [direction, fetchData, onKeyId, pageIndex, pageSize, sort]
  );

  useEffect(() => {
    if (prevFetchDataRef.current !== fetchData) {
      prevFetchDataRef.current = fetchData;
      setPageIndex(0);
      handleFetchData(0, pageSize, sort, direction);
    }
  }, [direction, fetchData, handleFetchData, pageIndex, pageSize, sort]);

  const refreshPage = useCallback(() => {
    handleFetchData(pageIndex, pageSize, sort);
  }, [handleFetchData, pageIndex, pageSize, sort]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      handleFetchData(newPage, pageSize);
      setPageIndex(newPage);
    },
    [handleFetchData, pageSize]
  );

  const handleChangeRowsPerPage = useCallback(
    event => {
      const newPageSize = parseInt(event.target.value, 10);
      handleFetchData(0, newPageSize);
      setPageIndex(0);
      setPageSize(newPageSize);
    },
    [handleFetchData]
  );
  const [listRowOpen, setListRowOpen] = useState([]);

  const isOpen = useCallback(
    keyId => {
      const value = listRowOpen.find(item => item.keyId === keyId);
      if (!value) return false;
      return value.open || false;
    },
    [listRowOpen]
  );

  const setOpen = useCallback(
    keyId => {
      const index = listRowOpen.findIndex(item => item.keyId === keyId);
      if (index === -1) {
        const newListRowOpen = [...listRowOpen];
        newListRowOpen.push({
          keyId,
          open: true
        });

        setListRowOpen(newListRowOpen);
      } else {
        const newListRowOpen = [...listRowOpen];
        newListRowOpen[index].open = !newListRowOpen[index].open;
        setListRowOpen(newListRowOpen);
      }
    },
    [listRowOpen]
  );

  const Pagination = useCallback(
    ({ children }) => (
      <PaginationContext.Provider
        value={{
          isOpen,
          setOpen,
          sort,
          setSort,
          fnRefreshPage: refreshPage,
          fnHandleFetchData: handleFetchData,
          direction,
          setDirection,
          fnHandleSelectItem: handleSelectItem,
          fnHandleIsSelectedItem: handleIsSelectIedtem,
          fnHandleSelectAll: handleSelectAll,
          IsSelectedAll
        }}
      >
        {isLoading && <Loader />}
        <TableContainer>
          <Table aria-label="collapsible table">{children}</Table>
        </TableContainer>

        {empty && <Box my={2}> {translate('COMMONS:NO_DATA')}</Box>}

        {totalElements !== 0 && (
          <FooterPagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalElements={totalElements}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            countSelectRows={selectedRows.length}
          />
        )}
      </PaginationContext.Provider>
    ),
    [
      isOpen,
      setOpen,
      sort,
      refreshPage,
      handleFetchData,
      direction,
      handleSelectItem,
      handleIsSelectIedtem,
      handleSelectAll,
      IsSelectedAll,
      isLoading,
      empty,
      translate,
      totalElements,
      pageIndex,
      pageSize,
      handleChangePage,
      handleChangeRowsPerPage,
      selectedRows.length
    ]
  );
  return [Pagination, rows, refreshPage, selectedRows];
};

export const usePaginationContext = () => useContext(PaginationContext);
