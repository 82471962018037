/* eslint-disable react/prop-types */
import React from 'react';

import { CustomLink } from './style';

const ModuleLink = ({ to, active, label }) => (
  <CustomLink to={to} active={active}>
    {label}
  </CustomLink>
);

export default ModuleLink;
