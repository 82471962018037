/* eslint-disable no-debugger */
import * as yup from 'yup';

function findField(volumeDetailId, detailListConfig) {
  return detailListConfig.find(
    field => field.volumeDetailId === volumeDetailId
  );
}

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

yup.addMethod(yup.array, 'detailFieldList', function detailFieldList() {
  return this.of(
    yup.object().shape({
      volumeDetailId: yup.number(),
      detailType: yup.string(),
      value: yup
        .number()
        .transform(emptyStringToNull)
        .nullable()
        .required()
        .when(
          ['volumeDetailId', 'detailType', '$detailListConfig'],
          (volumeDetailId, detailType, detailListConfig, schema) => {
            const field = findField(volumeDetailId, detailListConfig);

            let fieldSchema = schema.min(field.minValue || 0);

            if (typeof field.maxValue === 'number') {
              fieldSchema = fieldSchema.max(field.maxValue || 0);
            }

            return fieldSchema;
          }
        )
    })
  );
});
