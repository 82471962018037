import { http } from 'adapters';
import store from 'framework/store/store';

import RequestAccessService from './requestAccess.service';
import RequestAccessUseCases from './requestAccess.useCases';

export const requestAccessService = new RequestAccessService({ http });

export const requestAccessUseCases = new RequestAccessUseCases({
  store,
  requestAccessService
});
