import React from 'react';

import { Grid, Hidden } from '@mui/material';

import { useLangContext } from 'components/Globalization';

import appStore from 'assets/img/app-store.png';
import playStore from 'assets/img/google-play.png';
import qrCodeApple from 'assets/img/qr-code-apple.png';
import qrCodeGoogle from 'assets/img/qr-code-google.png';
import smartphoneMobile from 'assets/img/smartphone_initial_mobile.png';
import smartphone from 'assets/img/smartphone_initial.png';

import {
  CardContainer,
  CardDesktopTitle,
  CardMobileTitle,
  DownloadAppContainer,
  DownloadAppImage,
  QrCodeImage,
  SmartphoneImage,
  SmartphoneMobileImage
} from '../../styles';

const DownloadApp = () => {
  const translate = useLangContext();
  return (
    <CardContainer>
      <Hidden mdUp>
        <CardMobileTitle>{translate('CARDS.PHONE.CARD_TITLE')}</CardMobileTitle>
      </Hidden>
      <Grid container flexDirection="column">
        <Hidden mdDown>
          <CardDesktopTitle>
            {translate('CARDS.PHONE.CARD_TITLE')}
          </CardDesktopTitle>
        </Hidden>
        <Grid item container justifyContent="space-around" alignItems="center">
          <Grid item>
            <DownloadAppContainer>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <a
                    href="https://apps.apple.com/br/app/leitor-mobile-do-pnld/id6470533378"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadAppImage src={appStore} />
                  </a>
                </Grid>
                <Hidden mdDown>
                  <Grid item>
                    <QrCodeImage src={qrCodeApple} />
                  </Grid>
                </Hidden>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <a
                    href="https://play.google.com/store/apps/details?id=br.rnp.pnld.leitor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadAppImage src={playStore} />
                  </a>
                </Grid>
                <Hidden mdDown>
                  <Grid item>
                    <QrCodeImage src={qrCodeGoogle} />
                  </Grid>
                </Hidden>
              </Grid>
            </DownloadAppContainer>
          </Grid>
          <Grid item>
            <Hidden mdUp>
              <SmartphoneMobileImage src={smartphoneMobile} />
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      <Hidden mdDown>
        <SmartphoneImage src={smartphone} />
      </Hidden>
    </CardContainer>
  );
};

export default DownloadApp;
