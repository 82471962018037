import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as Yup from 'yup';

import { Button, Grid, Typography } from '@mui/material';

import { emailOrDocumentValidation } from 'helpers/emailOrDocumentValidation';

import Form from 'components/Form';
import { globalization, useLangContext } from 'components/Globalization';
import PublicContainer from 'components/PublicContainer';
import { router } from 'components/Router';
import TextField from 'components/TextField';

import { APP_ROUTES } from 'modules/App/constants/route';
import { USER_ROUTES } from 'modules/User/constants/routes';
import { userUseCases } from 'modules/User/providers';

import RequestConfirmedPopUp from './fragments/RequestConfirmedPopUp';

const RequestPasswordReset = ({ isAuthenticated }) => {
  const translate = useLangContext();
  const [showRequestConfirmed, setShowRequestConfirmed] = useState(false);

  const RequestPasswordResetSchema = Yup.object().shape({
    username: Yup.string()
      .test(
        'validateUsername',
        translate('EMAIL_OR_DOCUMENT_REQUIRED'),
        emailOrDocumentValidation
      )
      .required(translate('USER_REQUIRED'))
  });

  const onSubmitHandler = async data => {
    const { username } = data;
    try {
      await userUseCases.resetPassword(username);
    } catch (e) {
      console.error(e);
    }

    setShowRequestConfirmed(true);
  };

  if (isAuthenticated) {
    return <Redirect to={APP_ROUTES.BASE_ROUTE} />;
  }

  return (
    <>
      <RequestConfirmedPopUp show={showRequestConfirmed} />

      <PublicContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" color="primary">
              {translate('TITLE')}
            </Typography>
          </Grid>
        </Grid>
        <Form onSubmit={onSubmitHandler} schema={RequestPasswordResetSchema}>
          {formMethods => (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="username"
                  label={translate('USER')}
                  placeholder={translate('USER_PLACEHOLDER')}
                  formMethods={formMethods}
                />
              </Grid>

              <Grid container item justifyContent="flex-end">
                <Grid item>
                  <Button type="submit">{translate('CONFIRM')}</Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Form>
      </PublicContainer>
    </>
  );
};

const mapState = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

const connected = connect(mapState)(RequestPasswordReset);
const globalized = globalization(connected, 'REQUEST_PASSWORD_RESET');

export default router(globalized, {
  path: [USER_ROUTES.REQUEST_PASSWORD_RESET]
});
