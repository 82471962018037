/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { globalization, useLangContext } from 'components/Globalization';

import { Container, PasswordValidation } from './style';

const PasswordValidationDisplay = ({
  hasLowerCase,
  hasUpperCase,
  hasNumber,
  hasSpecialCharacter,
  minLength
}) => {
  const translate = useLangContext();

  const toggleIcon = useCallback(
    condition => (condition ? <FaCheck /> : <FaTimes />),
    []
  );

  return (
    <Container>
      <PasswordValidation isValid={hasLowerCase}>
        {toggleIcon(hasLowerCase)}
        {translate('VALIDATION_MUST_HAVE_LOWERCASE')}
      </PasswordValidation>
      <PasswordValidation isValid={hasUpperCase}>
        {toggleIcon(hasUpperCase)}
        {translate('VALIDATION_MUST_HAVE_UPPERCASE')}
      </PasswordValidation>
      <PasswordValidation isValid={hasNumber}>
        {toggleIcon(hasNumber)}
        {translate('VALIDATION_MUST_HAVE_NUMBER')}
      </PasswordValidation>
      <PasswordValidation isValid={hasSpecialCharacter}>
        {toggleIcon(hasSpecialCharacter)}
        {translate('VALIDATION_MUST_HAVE_SPECIAL_CHARACTER')}
      </PasswordValidation>
      <PasswordValidation isValid={minLength}>
        {toggleIcon(minLength)}
        {translate('VALIDATION_MUST_HAVE_MIN_LENGTH')}
      </PasswordValidation>
    </Container>
  );
};

const globalized = globalization(
  PasswordValidationDisplay,
  'PASSWORD_VALIDATION'
);

export default globalized;
