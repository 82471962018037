import React from 'react';

import { BorderColor } from '@mui/icons-material';

import { useLangContext } from 'components/Globalization';

import { SummaryItem } from 'modules/V2_RequestAccess/submodules/RequestCompleted/styles';

import {
  EditButton,
  SummarySectionTitle,
  Section,
  SectionTitleWrapper,
  Separator,
  SummaryInfo
} from '../styles';

const SectionComponent = ({ info, handlePressEdit }) => {
  const translate = useLangContext();

  return (
    <Section>
      <SectionTitleWrapper>
        <SummarySectionTitle>
          {translate(`REQUEST_ACCESS:${info.sectionTitle}`)}
        </SummarySectionTitle>
        <EditButton onClick={handlePressEdit}>
          <BorderColor
            color="primary"
            style={{
              fontSize: '16px',
              cursor: 'pointer'
            }}
          />
        </EditButton>
      </SectionTitleWrapper>
      <Separator />
      {info.items.map(item => (
        <>
          <SummaryItem key={item}>{item.label}</SummaryItem>
          <SummaryInfo>{item.value}</SummaryInfo>
        </>
      ))}
    </Section>
  );
};

export default SectionComponent;
