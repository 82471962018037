export const MASKS = {
  EDITAL_CODE: '9999',
  YEAR: '9999',
  ITEMS_LIMIT: '999',
  DATE: '99/99/9999',
  CPF: '999.999.999-99',
  CNPJ: '99.999.999/9999-99',
  TELEPHONE: '(99) 99999-9999',
  TELEPHONE_NO_DDD: '99999-9999',
  LEGACY_TELEPHONE: '(99) 9999-9999',
  LEGACY_TELEPHONE_NO_DDD: '9999-9999',
  DDD: '99',
  EXENSION_LINE: '9999',
  CODE_OF_CATEGORY: '99',
  OBJECT_CODE: '99'
};
