import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';

import { useQueryParams } from 'hooks/useQueryParams';
import PropTypes from 'prop-types';
import { authService } from 'services/providers/auth';

import history from 'framework/history';
import { mapRoutesPath } from 'helpers/mapRoutes';

import AuthProvider from 'modules/Auth/fragments/AuthProvider';
import NotificationList from 'modules/Notification/fragments/NotificationList';

import PrivateRoutes from './fragments/PrivateRoutes';
import PublicRoutes from './fragments/PublicRoutes';
import { PUBLIC_ROUTES } from './fragments/PublicRoutes/routes';
import {
  adminRouteProvider,
  defaultRouteProvider,
  teacherRouteProvider,
  studentRouteProvider
} from './routes';
import { AppContainer } from './style';

const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_TEACHER: 'ROLE_TEACHER',
  ROLE_STUDENT: 'ROLE_STUDENT'
};

const publicRoutes = mapRoutesPath(PUBLIC_ROUTES);

const App = ({ role, isAuthenticated, meFailed, isWaitingGovBrLogout }) => {
  const { search, pathname } = useLocation();
  const query = useQueryParams();

  let routeProvider = {
    routes: [],
    links: []
  };

  switch (role) {
    case ROLES.ROLE_ADMIN:
      routeProvider = adminRouteProvider;
      break;
    case ROLES.ROLE_TEACHER:
      routeProvider = teacherRouteProvider;
      break;
    case ROLES.ROLE_STUDENT:
      routeProvider = studentRouteProvider;
      break;
    default:
      routeProvider = defaultRouteProvider;
      break;
  }

  const renderRedirect = useCallback(() => {
    const meFailedFlag = authService.getMeFailedFlag();
    const isWaitingGovBrFlag = authService.getIsWaitingGovBrFlag();

    if (
      (meFailedFlag || meFailed) &&
      (isWaitingGovBrFlag || isWaitingGovBrLogout)
    ) {
      return <Redirect to={PUBLIC_ROUTES[3].path} />;
    }

    if (meFailedFlag) {
      return <Redirect to={PUBLIC_ROUTES[1].path} />;
    }

    if (!isAuthenticated || routeProvider.routes.length === 0) {
      return (
        <Redirect
          to={{
            pathname: PUBLIC_ROUTES[0].path,
            search: query.redirectTo
              ? `?redirectTo=${encodeURIComponent(query.redirectTo)}`
              : `?redirectTo=${encodeURIComponent(pathname + search)}`
          }}
        />
      );
    }

    return <Redirect to={routeProvider.routes[0].path} />;
  }, [
    meFailed,
    isWaitingGovBrLogout,
    isAuthenticated,
    routeProvider.routes,
    query.redirectTo,
    pathname,
    search
  ]);

  return (
    <AuthProvider>
      <AppContainer>
        <NotificationList />

        <Router history={history}>
          <Switch>
            {isAuthenticated ? (
              <Route
                path={mapRoutesPath(routeProvider.routes)}
                render={routeParams => (
                  <PrivateRoutes {...routeProvider} {...routeParams} />
                )}
              />
            ) : null}

            <Route path={publicRoutes} component={PublicRoutes} />

            {renderRedirect()}
          </Switch>
        </Router>
      </AppContainer>
    </AuthProvider>
  );
};

App.defaultProps = {
  role: undefined,
  isAuthenticated: false
};

App.propTypes = {
  role: PropTypes.string,
  isAuthenticated: PropTypes.bool
};

const mapState = state => ({
  role: state.auth.role,
  isAuthenticated: state.auth.isAuthenticated,
  meFailed: state.auth.meFailed,
  isWaitingGovBrLogout: state.auth.isWaitingGovBrLogout
});

export default connect(mapState)(App);
