export const REQUEST_ACCESS = {
  GET_CHECK_TEACHER_ACCESS_REQUEST:
    '/educational-network-teacher-request/validate/teacher-cpf/{cpf}/educational-institution/{inep}/learning-level/{learningLevel}',
  GET_STATE_LIST: '/address/state',
  GET_CITY_LIST: '/address/city/{initials}',
  GET_SCHOOL_LIST: '/educational-institution/{ibge}',
  GET_LEARNING_LEVEL_LIST: '/educational-institution/{inep}/learning-level',
  POST_TEACHER_REQUEST: '/educational-network-teacher-request',
  POST_SELECT_PROFILE: '/student-educational-institution',
  POST_ACCEPT_TERMS_OF_USE: '/register-terms-of-use',
  PUT_UPDATE_TERMS_OF_USE: '/register-terms-of-use',
  GET_TERMS_OF_USE_UPDATED: '/register-terms-of-use'
};

export const TEACHER_REQUEST_ACCESS = {
  GET_TEACHER_REQUEST_ACCESS: '/request-access-teacher',
  PUT_TEACHER_REQUEST_ACCESS:
    '/request-access-teacher/{requestAccessTeacherId}',
  POST_TEACHER_REQUEST_ACCESS: '/request-access-teacher',
  PUT_TEACHER_REQUEST_ACCESS_FINISH:
    '/request-access-teacher/{requestAccessTeacherId}/finish-request',
  DELETE_TEACHER_REQUEST_ACCESS:
    '/request-access-teacher/{requestAccessTeacherId}'
};
