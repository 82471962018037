import styled from 'styled-components';

export const TokenWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.palette.primary.main};
  background: linear-gradient(
    45deg,
    ${props => props.theme.palette.primary.dark} 0%,
    ${props => props.theme.palette.primary.light} 100%
  );
`;

export const TokenLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.palette.primary.light};
  width: 100%;
  height: 100%;

  span {
    margin-top: 20px;
    font-size: 20px;
  }
`;
