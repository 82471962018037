/* eslint-disable no-return-await */
export default class UserUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('./service').default} props.userService
   */
  constructor({ store, userService }) {
    this.store = store;
    this.userService = userService;
  }

  async createAccount({ email, firstname, lastname, cpf }) {
    return await this.userService.createAccount({
      cpf,
      email,
      name: `${firstname} ${lastname}`
    });
  }

  async resetPassword(cpfOrEmail) {
    return await this.userService.resetPassword(cpfOrEmail);
  }
}
