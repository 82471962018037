import React from 'react';
import { useLocation } from 'react-router-dom';

import { formatCPF } from 'utils/textFormatUtils';

import { Button, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import history from 'framework/history';
import { authActions } from 'framework/store/slices/auth.slice';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { APP_ROUTES } from 'modules/App/constants/route';
import { notificationUseCases } from 'modules/Notification/providers';
import { UPDATE_REGISTER } from 'modules/UpdateRegister/constants/routes';
import { registerUpdateUseCases } from 'modules/UpdateRegister/providers';

import { StyledContainer, Title } from '../UpdateRegister/styles';
import { CheckData, ConfirmRegisterButton, EditIcon } from './styles';

const ConfirmRegisterBody = ({ userNeedsUpdate, handleUserUpdate }) => {
  const translate = useLangContext();
  const { state } = useLocation();

  const handleEdit = () => {
    history.push({
      pathname: UPDATE_REGISTER.REGISTER,
      state: {
        ...state
      }
    });
  };

  const { cpf, school, learningLevel } = state;

  if (!userNeedsUpdate) {
    history.push(APP_ROUTES.BASE_ROUTE);
  }

  const confirmUpdate = async () => {
    try {
      const payload = {
        cpf,
        inep: school.inep,
        learningPhaseId: learningLevel.id
      };
      await registerUpdateUseCases.confirmUpdate(payload);
      notificationUseCases.success('UPDATED_SUCCESS');
      handleUserUpdate(false);
      history.push(APP_ROUTES.BASE_ROUTE);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Grid textAlign="center" padding="28px">
        <CheckData variant="h1">{translate('CHECK_THE_DATA')}</CheckData>
      </Grid>
      <StyledContainer>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h6" component="h2" fontWeight="bold">
              {translate('CONFIRM_THE_DATA')}
            </Typography>
          </Grid>
        </Grid>

        <Box mt={5}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="h6" component="h3" fontWeight="bold">
                {translate('PERSONAL_INFORMATION')}
              </Typography>
            </Grid>
            <Grid item>
              <EditIcon />
            </Grid>
          </Grid>
        </Box>
        <Box mt={1} mb={1}>
          <Divider />
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Title variant="subtitle2" component="h4">
              {translate('COMMONS:CPF')}
            </Title>
            <Typography variant="body1">{formatCPF(cpf)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Title variant="subtitle2" component="h4">
              {translate('SCHOOL')}
            </Title>
            <Typography variant="body1">
              {`${school.schoolName} - ${translate('INEP')} ${school.inep}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Title variant="subtitle2" component="h4">
              {translate('YEAR')}
            </Title>
            <Typography variant="body1">{learningLevel.description}</Typography>
          </Grid>
        </Grid>
        <Box mt={4}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button type="button" variant="text" onClick={handleEdit}>
                {translate('COMMONS:EDIT')}
              </Button>
            </Grid>

            <Grid item>
              <ConfirmRegisterButton
                variant="contained"
                onClick={confirmUpdate}
              >
                {translate('COMMONS:CONFIRM')}
              </ConfirmRegisterButton>
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>
    </>
  );
};

export default wrapper(ConfirmRegisterBody, {
  namespace: 'CONFIRM_REGISTER',
  route: {
    path: UPDATE_REGISTER.CONFIRM_REGISTER,
    breadcrumb: 'CONFIRM_REGISTER'
  },
  mapState: state => ({
    userNeedsUpdate: state.auth.userNeedsUpdate
  }),
  mapDispatch: dispatch => ({
    handleUserUpdate: boolean => {
      dispatch(authActions.userNeedsUpdate(boolean));
    }
  })
});
