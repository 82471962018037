import { createSlice } from '@reduxjs/toolkit';

export const WARNING_REDUCER = 'warning';

const warning = createSlice({
  name: WARNING_REDUCER,
  initialState: {
    warnings: []
  },
  reducers: {
    addWarning: (state, { payload }) => {
      state.warnings.push(payload);
    },
    clearWarnings: () => ({
      warnings: []
    })
  }
});

export const { addWarning, clearWarnings } = warning.actions;

export default warning.reducer;
