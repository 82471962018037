import styled from 'styled-components';

export const Content = styled.div`
  align-items: center;
  background: ${props => props.theme.palette.info.light};
  display: flex;
  justify-content: space-between;
  height: 72px;
  left: 0;
  padding: 0 48px;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    height: 120px;
  }
`;

export const WrapText = styled.div`
  align-items: flex-start;
  color: ${props => props.theme.palette.text.primary};
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    font-size: 14px;
    display: inline-block;
    width: 60%;
  }
`;
