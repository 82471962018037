import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useStepperContext } from 'hooks/useStepper/StepperContext';

import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, Typography } from '@mui/material';

import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import SelectSchoolModal from 'components/SelectSchoolModal';

import { useRequestAccessContext } from '../../../../Context';
import { requestAccessUseCases } from '../../../../provider';
import { SchoolName, StyledBox } from '../../styles';
import ActionsButtons from '../ActionsButtons';

const EducationUnit = () => {
  const translate = useLangContext();
  const { fnNext } = useStepperContext();
  const { requestData, setRequestData } = useRequestAccessContext();
  const { resetEducationUnit } = requestData;

  const formMethods = useForm({});

  const [openModal, setOpenModal] = useState(false);
  const [school, setSchool] = useState({
    name: requestData?.school,
    id: requestData?.inep
  });

  const closeModal = selectedSchool => {
    setOpenModal(false);
    setSchool({ name: selectedSchool.schoolName, id: selectedSchool.inep });
  };

  const submitEducationUnit = useCallback(async () => {
    const isDirty = requestData.inep !== school.id;

    if (isDirty) {
      try {
        const resp = await requestAccessUseCases.putRequestAccessTeacher({
          payload: {
            ...requestData,
            inep: school.id,
            school: school.name,
            learningLevel: null,
            learningLevelName: null
          },
          requestAccessTeacherId: requestData.id
        });

        setRequestData(resp);
        fnNext();
      } catch (error) {
        console.error(error);
      }
    } else {
      fnNext();
    }
  }, [fnNext, setRequestData, school, requestData]);

  useEffect(() => {
    if (resetEducationUnit) setSchool(null);
  }, [resetEducationUnit]);

  return (
    <StyledBox>
      <SelectSchoolModal
        open={openModal}
        handleModal={closeModal}
        school={school}
        filterBy={{ ibge: requestData?.city }}
      />
      <Typography component="h3" variant="h6">
        {translate('SELECT_EDUCATION_UNIT')}
      </Typography>
      <Form formMethods={formMethods} onSubmit={submitEducationUnit}>
        <Box mt={3} mb={3}>
          <Grid container>
            <Grid item md={11} xs={10}>
              <SchoolName onClick={() => setOpenModal(true)}>
                <Typography component="h4" variant="body1">
                  {!school?.name ? translate('SEARCH_SCHOOL') : school.name}
                </Typography>
              </SchoolName>
            </Grid>
            <Grid item xs>
              <Box mt={1}>
                <IconButton
                  variant="outlined"
                  onClick={() => setOpenModal(true)}
                >
                  <SearchIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ActionsButtons confirmButtonDisable={!school?.id} />
      </Form>
    </StyledBox>
  );
};

export default EducationUnit;
