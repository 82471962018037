/* eslint-disable react/prop-types */
import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { CustomLink } from '../../style';
import { Content, WrapText } from './style';

const AcceptPrivacityPolice = ({ hide, close }) => {
  const translate = useLangContext();
  if (!hide) return null;

  return (
    <Content container>
      <WrapText item xs={12}>
        <Trans
          t={translate}
          i18nKey="PRIVACY_POLICY"
          components={{
            Link: (
              <CustomLink
                to={
                  '' /* Add rota para os termos de uso da aplicação conforme necessidade */
                }
                style={{ marginLeft: '5px' }}
              />
            )
          }}
        />
      </WrapText>
      <Button variant="contained" onClick={close}>
        {translate('PRIVACY_POLICY_PROCEED')}
      </Button>
    </Content>
  );
};

export default wrapper(AcceptPrivacityPolice, {
  namespace: 'LOGIN'
});
