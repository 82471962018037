import styled from 'styled-components';

import { Button, Grow } from '@mui/material';

export const Container = styled.div`
  display: flex;
`;

export const ProfileToggler = styled(Button)`
  padding: 8px 16px;
  margin: 0px 10px;
  border-radius: 25px;

  span {
    padding: 0px 5px;
  }
`;

export const ProfileOptionList = styled(Grow)`
  transform-origin: right bottom;
`;

export const ImgUser = styled.button`
  align-items: center;
  border: none;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  font-weight: 900;
  font-size: 16px;
  margin: 0 5px 0 10px;
  cursor: pointer;
`;
