import styled from 'styled-components';

import { Typography } from '@mui/material';

export const UserName = styled(Typography)`
  &.MuiTypography-root {
    font-size: 34px;
    margin-bottom: 20px;
    text-align: left;
    line-height: 40px;
    width: 462px;

    ${props => props.theme.breakpoints.down('md')} {
      font-size: 28px;
      line-height: 30px;
      width: 100%;
    }
  }
`;
