export const educationResources = [
  {
    title: 'LITERARY_DIDACTIC_WORKS:PNLD_2021',
    subtitle: 'LITERARY_DIDACTIC_WORKS:EDUCATION_COLLAGE',
    submenu: [
      {
        title: 'LITERARY_DIDACTIC_WORKS:OBJECT_2',
        link: 'https://eduplay.rnp.br/portal/channel/pnld2021objeto2'
      },
      {
        title: 'LITERARY_DIDACTIC_WORKS:OBJECT_4_DIGITAL_RESOURCES',
        link: 'https://eduplay.rnp.br/portal/channel/pnld2021objeto4'
      }
    ]
  },
  {
    title: 'LITERARY_DIDACTIC_WORKS:PNLD_2022',
    subtitle: 'LITERARY_DIDACTIC_WORKS:EDUCATION_INFANT',
    submenu: [
      {
        title: 'LITERARY_DIDACTIC_WORKS:MATERIALS',
        link: 'https://eduplay.rnp.br/portal/channel/pnld2022'
      }
    ]
  },
  {
    title: 'LITERARY_DIDACTIC_WORKS:PNLD_2023',
    subtitle: 'LITERARY_DIDACTIC_WORKS:EDUCATION_ELEMENTARY',
    submenu: [
      {
        title: 'LITERARY_DIDACTIC_WORKS:OBJECT_4_REDS',
        link: 'https://eduplay.rnp.br/portal/channel/pnld2023objeto4'
      }
    ]
  },
  {
    title: 'LITERARY_DIDACTIC_WORKS:PNLD_2024',
    subtitle: 'LITERARY_DIDACTIC_WORKS:EDUCATION_FINAL_YEARS',
    submenu: [
      {
        title: 'LITERARY_DIDACTIC_WORKS:OBJECT_1',
        link: 'https://eduplay.rnp.br/portal/channel/pnld2024objeto1'
      }
    ]
  }
];
