import React from 'react';

import { Container, Grid, Typography } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { APP_ROUTES } from 'modules/App/constants/route';

import {
  CardContainer,
  CardTable,
  ContainerBackground,
  List,
  FinalityTable
} from '../style';

const PrivacyPolicy = () => {
  const translate = useLangContext();

  return (
    <Container>
      <Grid container spacing={3} py={3}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            {translate('TITLE')}
          </Typography>
        </Grid>
        <Grid borderRadius={12} item xs={12}>
          <ContainerBackground borderRadius={4}>
            <p>
              Em atenção às diretrizes previstas na Lei Federal nº 13.709/2018,
              o presente Aviso de Privacidade (“Aviso”) possui o objetivo de
              informar como são tratados seus Dados Pessoais no{' '}
              <b>Portal do Livro Digital</b> e no <b>Leitor Mobile</b> (ambos
              aqui tratados como “Portal”) disponibilizados pelo Fundo Nacional
              de Desenvolvimento da Educação (“FNDE”), autarquia federal
              vinculada ao Ministério da Educação, no âmbito de sua competência
              de operacionalizar o{' '}
              <b>Programa Nacional do Livro e do Material Didático</b> (“PNLD”).
            </p>
            <br />
            <p>
              <b>1. Termos Utilizados neste Aviso</b>
            </p>
            <br />
            <p>
              Para facilitar a sua leitura, apresentamos a seguir a definição de
              alguns termos utilizados neste Aviso:
            </p>
            <CardContainer>
              <CardTable>
                <tr>
                  <td>Autoridade Nacional de Proteção de Dados (ANPD)</td>
                  <td>
                    É a autarquia responsável por zelar, implementar e
                    fiscalizar o cumprimento da LGPD.
                  </td>
                </tr>
                <tr>
                  <td>Controlador</td>
                  <td>
                    É a pessoa natural ou jurídica, de direito público ou
                    privado, a quem competem as decisões referentes ao
                    tratamento de dados pessoais.
                  </td>
                </tr>
                <tr>
                  <td>Dado Pessoal</td>
                  <td>
                    É qualquer informação que possa levar à identificação de uma
                    pessoa física, como, por exemplo, nome, documentos de
                    identificação e informações de contato.
                  </td>
                </tr>
                <tr>
                  <td>Encarregado</td>
                  <td>
                    É a pessoa indicada pelo FNDE para atuar como ponto focal em
                    questões relacionadas à proteção de Dados Pessoais.
                  </td>
                </tr>
                <tr>
                  <td>LGPD</td>
                  <td>
                    Lei 13.709/18 ou Lei Geral de Proteção de Dados, a norma que
                    regula o tema no Brasil.
                  </td>
                </tr>
                <tr>
                  <td>Titular</td>
                  <td>
                    É a pessoa a quem se referem os dados pessoais, por exemplo,
                    você.
                  </td>
                </tr>
                <tr>
                  <td>Tratamento</td>
                  <td>
                    É qualquer operação realizada com dados pessoais, como a
                    coleta, armazenamento, compartilhamento e eliminação.
                  </td>
                </tr>
              </CardTable>
            </CardContainer>
            <br />
            <p>
              <b>2. Informações sobre o Controlador</b>
            </p>
            <br />
            <p>
              O Controlador dos dados pessoais tratados no âmbito do Portal é o
              Fundo Nacional de Desenvolvimento da Educação (FNDE), autarquia
              federal vinculada ao Ministério da Educação. Em caso de dúvidas
              relacionadas ao presente Aviso, envie uma mensagem ao nosso
              Encarregado pelo Tratamento de Dados:
            </p>
            <br />
            <p>Nome: Evânio Antônio de Araújo Júnior</p>
            <br />
            <p>
              Canal de contato:{' '}
              <a
                href="http://falabr.cgu.gov.br/publico/Manifestacao/SelecionarTipoManifestacao.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Plataforma Fala.BR
              </a>
            </p>
            <br />
            <p>
              <b>3. Dados coletados</b>
            </p>
            <br />
            <p>
              Seus Dados Pessoais são coletados ao criar um cadastro no Portal e
              ao utilizá-lo. A seguir, detalhamos as categorias de Dados
              Pessoais utilizados:
            </p>
            <br />
            <List>
              <li>
                <p>
                  <b>Dados cadastrais,</b> tais como nome completo, CPF, data de
                  nascimento, e-mail, telefone e grau de relacionamento com o
                  estudante (quando o usuário é pai/responsável legal).
                </p>
              </li>
              <li>
                <p>
                  <b>Dados profissionais ou acadêmicos,</b> tais como função,
                  unidade de ensino, UF e município da unidade de ensino,
                  ano/série, etapa da educação de atuação.
                </p>
              </li>
              <li>
                <p>
                  <b>Dados sensíveis,</b> relacionados à existência e tipo de
                  deficiência de estudante.
                </p>
              </li>
              <li>
                <p>
                  <b>Registros eletrônicos e de navegação,</b> como endereço de
                  IP, sistema operacional, tipo de navegador e informações
                  relacionadas ao comportamento de navegação (por exemplo, áreas
                  visitadas e recursos utilizados).
                </p>
              </li>
            </List>
            <br />
            <p>
              <b>4. Finalidades</b>
            </p>
            <br />
            <CardContainer>
              <FinalityTable>
                <tr>
                  <th>Finalidade</th>
                  <th>Categorias de Dados Pessoais utilizados</th>
                </tr>
                <tr>
                  <td>
                    Autenticar sua identidade em nossos ambientes eletrônicos,
                    permitindo o uso do Portal para cada tipo de usuário e o
                    acesso a livros e materiais didáticos.
                  </td>
                  <td>Dados cadastrais e dados profissionais ou acadêmicos</td>
                </tr>
                <tr>
                  <td>
                    Adotar mecanismos para promover acessibilidade no PNLD para
                    pessoas com deficiência.
                  </td>
                  <td>Dados sensíveis</td>
                </tr>
                <tr>
                  <td>
                    Recepcionar suas solicitações ou demandas em um dos nossos
                    canais de comunicação.
                  </td>
                  <td>Dados cadastrais</td>
                </tr>
                <tr>
                  <td>
                    Permitir a melhoria técnica contínua e o funcionamento
                    adequado do Portal, analisando o seu desempenho, corrigindo
                    erros e realizando adaptações.
                  </td>
                  <td>
                    Dados cadastrais e registros eletrônicos e de navegação
                  </td>
                </tr>
              </FinalityTable>
            </CardContainer>
            <br />
            <p>
              Além das finalidades específicas acima descritas, as informações
              coletadas no Portal podem ser utilizadas para assegurar o
              cumprimento de obrigações legais e/ou regulatórias, o atendimento
              de requisições de autoridades competentes e/ou o exercício de
              direitos assegurados ao FNDE.
            </p>
            <br />
            <p>
              <b>5. Compartilhamento dos Dados</b>
            </p>
            <br />
            <p>
              O compartilhamento dos Dados Pessoais tratados no escopo do Portal
              é limitado a casos de estrita necessidade, observando as
              justificativas legais e os limites aplicáveis. Nesse sentido, as
              informações podem vir a ser compartilhadas com autoridades
              competentes em caso de recebimento de requisições oficiais ou para
              atendimento de obrigações legais ou regulatórias específicas, bem
              como terceiros/prestadores de serviços, para permitir a execução
              das finalidades relacionadas neste Aviso, como a garantia de
              funcionamento adequado e correção de erros do Portal.
            </p>
            <br />
            <p>
              <b>6. Tempo de Armazenamento</b>
            </p>
            <br />
            <p>
              O FNDE mantém os seus Dados Pessoais apenas pelo tempo necessário
              para satisfazer as finalidades descritas neste Aviso. Em alguns
              casos, as informações poderão ser mantidas por período
              complementar caso seja necessário para o cumprimento de obrigações
              legais e regulatórias ou para resguardar o exercício de direitos
              em demandas administrativas, judiciais ou arbitrais. A eliminação
              dos Dados Pessoais observará as regras de término de Tratamento e
              hipóteses de conservação de dados previstas na LGPD e outras
              normas aplicáveis.
            </p>
            <br />
            <p>
              <b>7. Segurança da Informação</b>
            </p>
            <br />
            <p>
              Os Dados Pessoais são mantidos de forma segura e confidencial.
              Nesse sentido, adotamos medidas de segurança técnicas e
              organizacionais razoáveis para proteger os seus Dados Pessoais
              contra acessos não autorizados e/ou situações acidentais ou
              ilícitas de destruição, perda, alteração, comunicação ou difusão,
              de acordo com nossas Políticas de Segurança da Informação.
            </p>
            <br />
            <p>
              <b>8. Direitos do Titular</b>
            </p>
            <br />
            <p>
              A LGPD traz direitos aos Titulares de Dados Pessoais, os quais
              podem ser exercidos se respeitadas as limitações técnicas e
              jurídicas eventualmente aplicáveis, bem como as condições de
              validação de identidade, quando o caso.
            </p>
            <br />
            <p>
              O exercício destes direitos será viabilizado em consonância com as
              demais disposições legais e regulatórias aplicáveis, estando
              sujeito, inclusive, à observância de procedimentos aplicáveis no
              âmbito da Administração Pública Federal.
            </p>
            <br />
            <p>São direitos dos titulares relacionados à Plataforma:</p>
            <br />
            <CardContainer>
              <CardTable>
                <tr>
                  <td>Confirmação do tratamento e acesso aos dados</td>
                  <td>
                    O Titular pode requerer a confirmação da existência de
                    Tratamento de seus Dados Pessoais no âmbito da Plataforma,
                    bem como solicitar o acesso aos seus Dados Pessoais
                    eventualmente utilizados.
                  </td>
                </tr>
                <tr>
                  <td>Correção ou atualização</td>
                  <td>
                    O Titular pode solicitar a correção e/ou atualização de seus
                    Dados Pessoais, caso estejam incorretos, imprecisos ou
                    desatualizados.
                  </td>
                </tr>
                <tr>
                  <td>Eliminação, anonimização e/ou bloqueio</td>
                  <td>
                    O Titular pode solicitar eliminação, anonimização ou
                    bloqueio de seus Dados Pessoais, desde que processados de
                    forma excessiva, desnecessária e/ou em desconformidade com a
                    LGPD.
                  </td>
                </tr>
                <tr>
                  <td>Oposição</td>
                  <td>
                    O Titular pode solicitar a oposição ao Tratamento de seus
                    Dados Pessoais, observados os limites e imposições
                    legalmente previstos.
                  </td>
                </tr>
                <tr>
                  <td>Portabilidade</td>
                  <td>
                    Uma vez regulamentada pela Autoridade Nacional de Proteção
                    de Dados, o Titular poderá solicitar a portabilidade de seus
                    Dados Pessoais, quando tecnicamente viável.
                  </td>
                </tr>
                <tr>
                  <td>Informações sobre o compartilhamento</td>
                  <td>
                    Sem prejuízo das informações fornecidas nesse Aviso, o
                    Titular pode solicitar esclarecimentos adicionais sobre o
                    compartilhamento de seus Dados Pessoais.
                  </td>
                </tr>
                <tr>
                  <td>Informações sobre o consentimento</td>
                  <td>
                    Quando aplicável, o Titular pode solicitar informações sobre
                    possíveis consequências caso não autorize o Tratamento de
                    seus Dados Pessoais.
                  </td>
                </tr>
                <tr>
                  <td>Revogação do consentimento e eliminação de dados</td>
                  <td>
                    Nos casos em que o Tratamento dos Dados Pessoais ocorrer
                    mediante a obtenção do consentimento, o Titular pode
                    solicitar revogação dessa autorização e/ou a eliminação de
                    Dados Pessoais tratados com base nessa autorização.
                  </td>
                </tr>
                <tr>
                  <td>Revisão de decisões automatizadas</td>
                  <td>
                    O Titular pode solicitar a revisão de decisões tomadas de
                    forma automática (sem participação humana) e/ou solicitar
                    informações sobre os critérios adotados para a tomada dessa
                    decisão.
                  </td>
                </tr>
                <tr>
                  <td>Solicitação junto à autoridade competente</td>
                  <td>
                    Caso o Titular, após realizar requisição sobre o Tratamento
                    de seus Dados Pessoais junto ao FNDE, ainda entenda que a
                    sua solicitação não foi processada adequadamente, poderá
                    peticionar junto à Autoridade Nacional de Proteção de Dados
                    (ANPD).
                  </td>
                </tr>
              </CardTable>
            </CardContainer>
            <br />
            <p>
              As solicitações referentes ao exercício destes direitos devem ser
              direcionadas ao canal:{' '}
              <a
                href="http://falabr.cgu.gov.br/publico/Manifestacao/SelecionarTipoManifestacao.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Plataforma Fala.BR
              </a>
              .
            </p>
            <br />
            <p>
              <b>9. Atualizações</b>
            </p>
            <br />
            <p>
              O presente Aviso pode sofrer atualizações e ajustes a qualquer
              tempo.
            </p>
            <br />
            <p>Data da última atualização: 16/10/2024</p>
          </ContainerBackground>
        </Grid>
      </Grid>
    </Container>
  );
};

export default wrapper(PrivacyPolicy, {
  namespace: 'PRIVACY_POLICY',
  route: {
    path: APP_ROUTES.PRIVACY_POLICY
  }
});
