import React from 'react';
import { useController } from 'react-hook-form';

import { get } from 'lodash';
import PropTypes from 'prop-types';

import { useLangContext } from 'components/Globalization';
import { UncontrolledTextField } from 'components/TextField';

const MaskedTextField = ({
  mask,
  name,
  id,
  formMethods,
  defaultValue,
  isDisabled = false,
  onChange: onChangeProp = () => {},
  ...rest
}) => {
  const translate = useLangContext();

  const { control, errors } = formMethods;
  const {
    field: { ref, value, onChange, ...inputProps }
  } = useController({
    name,
    control,
    defaultValue
  });

  let errorProps = {};
  const error = get(errors, name);
  if (error) {
    errorProps = {
      error: true,
      helperText:
        typeof error.message === 'string'
          ? translate(error.message)
          : translate(error.message.key, error.message.params)
    };
  }

  const maskedValue =
    typeof value !== 'undefined' && value !== null ? `${value}` : '';
  return (
    <UncontrolledTextField
      ref={ref}
      value={maskedValue}
      onChange={e => {
        onChangeProp(e);
        onChange(e?.target?.value || e);
      }}
      disabled={isDisabled}
      InputProps={{
        inputProps: {
          mask,
          id: id || name
        }
      }}
      {...inputProps}
      {...rest}
      {...errorProps}
    />
  );
};

MaskedTextField.defaultProps = {
  id: undefined,
  defaultValue: '',
  label: '',
  mask: undefined
};

MaskedTextField.propTypes = {
  id: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  formMethods: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  mask: PropTypes.string,
  defaultValue: PropTypes.string
};

export default MaskedTextField;
