import styled from 'styled-components';

import { Box } from '@mui/material';

export const ContainerBackground = styled(Box)`
  background: ${props => props.theme.palette.background.light};
  border: ${props => `1px solid ${props.theme.palette.border.light}`};
  padding: 24px !important;
  border-radius: 1%;
  p {
    color: ${props => props.theme.palette.text.primary};
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
    text-justify: inter-word;
  }
`;

export const Table = styled.table`
  font-size: 14px;
  border-collapse: collapse;
  margin: 0 auto;
  margin-top: 16px;
  width: 100%;
`;

export const FinalityTable = styled(Table)`
  th {
    background: ${props => props.theme.palette.background.default};
    font-weight: bold;
    padding: 12px;
  }
  td {
    width: 50%;
    padding: 12px;
    border: 1px solid ${props => props.theme.palette.text.primary};

    &:nth-child(2) {
      text-align: center;
    }
  }
`;

export const CardTable = styled(Table)`
  justify-content: space-around;
  td {
    border: 1px solid ${props => props.theme.palette.text.primary};
    padding: 12px;

    &:first-child {
      background: ${props => props.theme.palette.background.default};
      font-weight: bold;
      text-align: center;
    }
  }
`;

export const CardContainer = styled.div``;
export const CardHeader = styled.div`
  align-items: center;
  bottom: 10%;
  background: ${props => props.theme.palette.background.default};
  font-weight: bold;
  justify-content: center;
  padding: 2%;
`;
export const CardText = styled.div`
  border: 1px solid;
  margin: 1%;
  max-height: 344px;
  max-width: 50%;
  padding-bottom: 50%;
  ul {
    padding: 2%;
    li {
      padding: 1%;
    }
  }
`;

export const List = styled.ul`
  list-style-type: disc;
  padding-left: 24px;
  li {
    margin-bottom: 8px;
  }
`;
