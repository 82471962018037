/* eslint-disable no-return-await */
export default class UserUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('./user.service').default} props.userService
   */
  constructor({ store, userService }) {
    this.store = store;
    this.userService = userService;
  }

  async createAccount({ email, firstname, lastname, cpf }) {
    return await this.userService.createAccount({
      cpf,
      email,
      name: `${firstname} ${lastname}`
    });
  }

  async resetPassword(cpfOrEmail) {
    return await this.userService.resetPassword(cpfOrEmail);
  }

  async getFndeUserList() {
    return await this.userService.getFndeUserList();
  }

  async patchChangeStatus(payload) {
    return await this.userService.patchChangeStatus(payload);
  }

  async getRoles() {
    return await this.userService.getRoles();
  }

  async getProfilesOptions() {
    return await this.userService.getProfilesOptions();
  }

  async needsUpdate() {
    return await this.userService.needsUpdate();
  }
}
