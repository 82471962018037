/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect } from 'react';
import { PulseLoader } from 'react-spinners';

import { authService, authUseCases } from 'services/providers/auth';
import { ThemeContext } from 'styled-components';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { AUTH_ROUTES } from 'modules/Auth/constants/routes';

import { LogoRNP } from '../../../../components/Image';
import { LogoContainer } from '../../../../components/PublicContainer/style';
import { Container } from '../../../App/submodules/NoAccess/style';
import { TokenLoaderContainer, TokenWrapperContainer } from './style';

const Logout = () => {
  const theme = useContext(ThemeContext);
  const translate = useLangContext();

  const handleLogout = async () => {
    authUseCases
      .forceLogout()
      .then(() => {
        authService.unsetIsWaitingGovBrAuthLogout();
        window.location.href = `${window.env.REACT_APP_GOV_BR_URI}/logout?post_logout_redirect_uri=${window.env.REACT_APP_FRONTEND_URL}`;
      })
      .catch(err => {
        console.error(err);
      });
  };

  const autoLogout = useCallback(() => {
    authUseCases.signOut(false).then(() => {
      handleLogout();
    });
  }, []);

  useEffect(() => {
    autoLogout();
  }, [autoLogout]);

  return (
    <Container>
      <TokenWrapperContainer>
        <TokenLoaderContainer>
          <PulseLoader
            size="10px"
            margin="2px"
            color={theme.palette.primary.light}
          />
          <span>{translate('WAIT')}</span>
        </TokenLoaderContainer>

        <LogoContainer>
          <LogoRNP />
        </LogoContainer>
      </TokenWrapperContainer>
    </Container>
  );
};

export default wrapper(Logout, {
  namespace: 'LOGOUT',
  route: {
    path: AUTH_ROUTES.LOGOUT
  },
  mapState: state => ({
    isAuthenticated: state.auth.isAuthenticated
  })
});
