import React, { useCallback, useState } from 'react';

import { useStepperContext } from 'hooks/useStepper/StepperContext';
import * as uuid from 'uuid';

import { Button, Grid } from '@mui/material';

import { AsyncLoad } from 'components/AsyncLoad';
import { useLangContext } from 'components/Globalization';
import SelectField from 'components/SelectField';
import wrapper from 'components/Wrapper';

import { requestAccessUseCases } from '../../../../provider';
import { Box } from '../../../../styles';

const SelectUF = () => {
  const translate = useLangContext();
  const { fnNext, fnSetDataByKey } = useStepperContext();
  const [ufOptions, setUfOptions] = useState();
  const [uf, setUF] = useState();

  const fetchData = useCallback(async () => {
    try {
      const response = await requestAccessUseCases.getStateList();
      setUfOptions(response);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleSelectedUF = () => {
    const { id, name, initials } = ufOptions.find(item => item.id === uf);
    fnSetDataByKey('uf', { id, name, initials });
    fnSetDataByKey('id', uuid.v4());
    fnNext();
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AsyncLoad promiseFn={fetchData}>
            <SelectField
              name="uf"
              idKey="id"
              label={translate('UF')}
              placeholder={translate('UF_PLACEHOLDER')}
              displayEmpty
              options={ufOptions}
              onChange={evt => {
                setUF(evt.target.value);
              }}
              style={{ width: '100%' }}
            />
          </AsyncLoad>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            variant="contained"
            disabled={!uf}
            onClick={() => handleSelectedUF()}
          >
            {translate('COMMONS:CONTINUE')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default wrapper(SelectUF, {
  namespace: 'REQUEST_ACCESS'
});
