import React, { useCallback, useContext } from 'react';

import { useLocalStorageState } from 'hooks/useStorageState';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { useAppTheme } from 'styles/useAppTheme';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

export const ThemeContext = React.createContext({
  type: 'light',
  toggleType: () => {}
});

const Theme = ({ children }) => {
  const theme = useAppTheme();
  return (
    <MuiThemeProvider theme={theme}>
      <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
    </MuiThemeProvider>
  );
};

const ThemeProvider = ({ children }) => {
  const [type, setType] = useLocalStorageState('themeType', 'light');
  const toggleType = useCallback(() => {
    setType(type === 'light' ? 'dark' : 'light');
  }, [setType, type]);

  return (
    <ThemeContext.Provider value={{ type, toggleType, setType }}>
      <Theme>{children}</Theme>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);

export default ThemeProvider;
