import { http } from 'adapters';

import RegisterUpdateService from './registerUpdate.service';
import RegisterUpdateUseCases from './registerUpdate.useCases';

export const registerUpdateService = new RegisterUpdateService({ http });

export const registerUpdateUseCases = new RegisterUpdateUseCases({
  registerUpdateService
});
