import React from 'react';
import { FaTimes } from 'react-icons/fa';

import styled from 'styled-components';

import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  IconButton,
  Typography
} from '@mui/material';

export const Dialog = styled(MuiDialog)``;
export const DialogActions = styled(MuiDialogActions)``;

export const DialogContent = styled(MuiDialogContent)`
  padding-bottom: 16px;
`;

export const DialogTitle = styled(({ children, onClose, ...props }) => (
  <Typography variant="h6" {...props}>
    {children}
    {onClose ? (
      <div>
        <IconButton
          data-testid="btnClose"
          size="small"
          color="primary"
          aria-label="close"
          onClick={onClose}
        >
          <FaTimes />
        </IconButton>
      </div>
    ) : null}
  </Typography>
))`
  display: flex;
  h6 {
    flex: 1;
  }
`;
