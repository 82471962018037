import { formatString } from 'helpers/formatString';

import { REQUEST_LIST } from '../constants/api';

export default class RequestListService {
  /**
   * @param {object} props
   * @param {import('adapters/axios').Axios} props.http
   */

  constructor({ http }) {
    this.http = http;
  }

  async getTeacherRequestList() {
    return this.http.get(REQUEST_LIST.GET_TEACHER_REQUEST_LIST, {
      skipErrorHandling: true
    });
  }

  async deleteRequest(requestId) {
    const url = formatString(REQUEST_LIST.DELETE_REQUEST, { requestId });
    return this.http.delete(url);
  }

  async getStateList() {
    return this.http.get(REQUEST_LIST.GET_STATE_LIST);
  }

  async getCityList(initials) {
    const url = formatString(REQUEST_LIST.GET_CITY_LIST, {
      initials
    });
    return this.http.get(url);
  }

  async getSchoolList(ibge) {
    const url = formatString(REQUEST_LIST.GET_SCHOOL_LIST, {
      ibge
    });
    return this.http.get(url);
  }

  async getLearningLevelList(inep) {
    const url = formatString(REQUEST_LIST.GET_LEARNING_LEVEL_LIST, {
      inep
    });
    return this.http.get(url);
  }

  async postTeacherRequest(payload) {
    return this.http.post(REQUEST_LIST.POST_TEACHER_REQUEST, payload, {
      skipErrorHandling: true
    });
  }
}
