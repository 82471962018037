import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import wrapper from 'components/Wrapper';

import { AUTH_ROUTES } from 'modules/Auth/constants/routes';

import Breadcrumbs from './fragments/Breadcrumbs';
import PrivateFooter from './fragments/PrivateFooter';
import PrivateHeader from './fragments/PrivateHeader';
import { ContentWrapper } from './styles';

const PrivateRoutes = ({ routes, links, menus, isAuthenticated, user }) => {
  const location = useLocation();

  useEffect(() => {
    // TODO: Chamar o first accesss se tiver alguma implementação de primeiro acesso na aplicacao
    // async function checkFistAccess() {
    //   if (isAuthenticated && !user.firstAccess) {
    //     await userService.setFirstAccess();
    //   }
    // }
    // checkFistAccess();
  }, [user, isAuthenticated]);

  if (!isAuthenticated) {
    return <Redirect to={AUTH_ROUTES.LOGIN} />;
  }

  const firstRoute = routes[0].path;

  return (
    <>
      <PrivateHeader links={links} menus={menus} location={location} />
      <ContentWrapper>
        <Breadcrumbs />
        <Switch>
          {routes.map(route => (
            <Route key={route.path} {...route} />
          ))}

          <Redirect to={firstRoute} />
        </Switch>
      </ContentWrapper>

      <PrivateFooter />
    </>
  );
};

export default React.memo(
  wrapper(PrivateRoutes, {
    mapState: state => ({
      isAuthenticated: state.auth.isAuthenticated,
      user: state.auth.user
    })
  })
);
