/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Redirect } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';

import CheckBox from 'components/Checkbox';
import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { APP_ROUTES } from 'modules/App/constants/route';
import { authUseCases } from 'modules/Auth/providers';
import { USER_ROUTES } from 'modules/User/constants/routes';
import { userService } from 'modules/User/providers';

import formSchema from './formSchema';
import PdfViewer from './fragments/pdfViewer';
import {
  Container,
  ScrollableContainer,
  DataProcessingContainer
} from './style';

const SelectSecurityQuestions = ({
  dataProcessingAgreement,
  isAuthenticated
}) => {
  const translate = useLangContext();

  const [showConfirmAgreement, setShowConfirmAgreement] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [checkPdfLoaded, setCheckPdfLoaded] = useState(false);

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(formSchema),
    validationContext: {
      abortEarly: false
    }
  });

  const { control } = formMethods;

  const dataProcessingAgreementValue = useWatch({
    control,
    name: 'dataProcessingAgreement',
    defaultValues: false
  });

  const onSubmitHandler = useCallback(async () => {
    try {
      await userService.dataProcessingAgreement();
      setShowConfirmAgreement(true);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const isPdfLoaded = test => {
    setCheckPdfLoaded(test);
  };

  const onDeleteUser = useCallback(async () => {
    await authUseCases.updateCurrentUserInformation();
  }, []);

  if (dataProcessingAgreement || !isAuthenticated) {
    return <Redirect to={APP_ROUTES.BASE_ROUTE} />;
  }

  return (
    <Container>
      <div
        // ContentContainer
        style={{ maxWidth: '700px' }}
      >
        <h2>{translate('TITLE')}</h2>
        <DataProcessingContainer>
          <Form onSubmit={onSubmitHandler} formMethods={formMethods}>
            <ScrollableContainer style={{ border: '1px solid #F2F2F2' }}>
              <PdfViewer isPdfLoaded={test => isPdfLoaded(test)} />
            </ScrollableContainer>
            <Container style={{ justifyContent: 'left', fontWeight: 'bold' }}>
              <CheckBox
                label={translate('READ_AND_ACCEPT')}
                name="dataProcessingAgreement"
                formMethods={formMethods}
              />
            </Container>
            {/* {checkPdfLoaded ? ( */}
            <Button
              type="submit"
              disabled={!dataProcessingAgreementValue}
              style={{ alignSelf: 'flex-end', padding: '11px 20px' }}
            >
              {translate('CONTINUE')}
            </Button>
            {/* ) : null} */}
          </Form>
        </DataProcessingContainer>
      </div>

      <Dialog open={showConfirmAgreement}>
        <DialogContent>
          <DialogContentText>{translate('SUCCESS')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onDeleteUser}
            style={{ margin: '0 auto', display: 'block' }}
          >
            {translate('CONTINUE')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default wrapper(SelectSecurityQuestions, {
  namespace: 'DATA_PROCESSING_AGREEMENT',
  mapState: state => ({
    securityQuestionsAnswered: state.auth.securityQuestionsAnswered,
    dataProcessingAgreement: state.auth.dataProcessingAgreement,
    isAuthenticated: state.auth.isAuthenticated
  }),
  route: {
    path: USER_ROUTES.DATA_PROCESSING_AGREEMENT
  }
});
