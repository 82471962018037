import React from 'react';

import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { REQUEST_STATUS } from 'modules/V2_RequestAccess/constants/requestStatusEnum';

const EditButton = ({ row, redirect }) => {
  const { requestStatus } = row;
  if (requestStatus === REQUEST_STATUS.INCOMPLETE) {
    return (
      <IconButton onClick={() => redirect(row)}>
        <Edit />
      </IconButton>
    );
  }
  return null;
};

export default EditButton;
