import styled from 'styled-components';

import { NavigateNext } from '@mui/icons-material';
import { Link, Typography } from '@mui/material';

export const StyledText = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
`;

export const StyledLink = styled(Link)`
  &.MuiLink-root {
    color: ${props => props.theme.palette.interactive.main};
    text-decoration: none;
  }
`;

export const StyledNavigateNext = styled(NavigateNext)`
  color: ${props => props.theme.palette.border.light};
`;
