import * as yup from 'yup';

export const Schema = yup.object().shape({
  inep: yup.string().required(),
  segmentId: yup
    .string()
    .when('$isRoleStudent', (isRoleStudent, schema) =>
      !isRoleStudent ? schema.required() : schema
    ),
  seriesId: yup
    .string()
    .when('$isRoleStudent', (isRoleStudent, schema) =>
      !isRoleStudent ? schema.required() : schema
    ),
  typeLiterary: yup
    .string()
    .when('$isRoleStudent', (isRoleStudent, schema) =>
      !isRoleStudent ? schema.required() : schema
    )
});
