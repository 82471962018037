import React from 'react';

import { Grid } from '@mui/material';

import { useLangContext } from 'components/Globalization';

import DownloadApp from './fragments/DownloadApp';
import DownloadDesktop from './fragments/DownloadDesktop';
import { CustomTitle, WrapperTab } from './styles';

const LiteraryDidacticWorksTab = () => {
  const translate = useLangContext();

  return (
    <WrapperTab>
      <CustomTitle variant="h5">{translate('TEXT_DOWNLOAD')}</CustomTitle>
      <Grid container spacing={3}>
        <Grid item container md={12} lg={6}>
          <DownloadApp />
        </Grid>
        <Grid item container md={12} lg={6}>
          <DownloadDesktop />
        </Grid>
      </Grid>
    </WrapperTab>
  );
};

export default LiteraryDidacticWorksTab;
