import React, { useCallback, useMemo } from 'react';

import { useStepper } from 'hooks/useStepper';

import { Grid } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_ROUTES } from '../../constants/routes';
import { useRequestAccessContext } from '../../Context';
import EducationUnit from './fragments/EducationUnit';
import LearningLevel from './fragments/LearningLevel';
import Locality from './fragments/Locality';
import Summary from './fragments/Summary';

const RequestAccessTeacher = () => {
  const translate = useLangContext();

  const { requestData } = useRequestAccessContext();

  const stepPages = useMemo(
    () => [
      {
        title: translate('STEPS.LOCALITY'),
        Content: Locality
      },
      {
        title: translate('STEPS.EDUCATION_UNIT'),
        Content: EducationUnit
      },

      {
        title: translate('STEPS.LEARNING_PHASE'),
        Content: LearningLevel
      },
      {
        title: translate('STEPS.FINISH'),
        Content: Summary
      }
    ],
    [translate]
  );

  const handleFinish = useCallback(() => {}, []);

  const [Stepper] = useStepper({
    stepPages,
    onFinish: handleFinish,
    initialStep: requestData?.initialStep,
    asyncData: true
  });

  return (
    <Grid container spacing={3} py={3}>
      <Grid item xs={12}>
        <Stepper />
      </Grid>
    </Grid>
  );
};

export default wrapper(RequestAccessTeacher, {
  namespace: 'REGISTER_TEACHER',
  route: { path: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS_TEACHER }
});
