import React, { useCallback, useState, useEffect } from 'react';

import { usePagination } from 'hooks/usePagination';

import CheckIcon from '@mui/icons-material/Check';
import {
  Button,
  DialogActions,
  DialogContent,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import { Dialog, DialogTitle } from 'components/Dialog';
import FormLoader from 'components/Form/fragments/FormLoader';
import { useLangContext } from 'components/Globalization';
import InputSearch from 'components/InputSearch';
import wrapper from 'components/Wrapper';

import { registerUpdateUseCases } from 'modules/UpdateRegister/providers';

import { CustomBox } from './style';

const SelectSchoolModal = ({ open, handleModal, school, filterBy }) => {
  const handleKeyId = row => row?.inep;
  const [term, setTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getSchools = useCallback(
    async ({ size, page }) => {
      setIsLoading(true);
      try {
        const response = await registerUpdateUseCases.schoolsList({
          term,
          size,
          page,
          ...filterBy
        });

        return response;
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }

      return [];
    },
    [filterBy, term]
  );

  const [Pagination, rows] = usePagination({
    fetchData: getSchools,
    onKeyId: handleKeyId
  });

  const translate = useLangContext();

  useEffect(() => {
    setTerm('');
  }, [open]);

  return (
    <Dialog maxWidth="lg" fullWidth onClose={handleModal} open={open}>
      <DialogTitle style={{ padding: '24px' }}>
        {translate('MODAL_TITLE')}
      </DialogTitle>

      <DialogContent>
        <CustomBox mb={3}>
          <InputSearch
            name="term"
            placeholder={translate('SEARCH_PLACEHOLDER')}
            onSearch={e => setTerm(e)}
          />
        </CustomBox>
        {isLoading && <FormLoader />}
        <Pagination>
          <TableHead>
            <TableRow>
              <TableCell align="left">{translate('UF')}</TableCell>
              <TableCell align="left">{translate('CITY')}</TableCell>
              <TableCell align="left">{translate('EDUCATION_UNIT')}</TableCell>
              <TableCell align="left">{translate('INEP')}</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.inep}>
                <TableCell align="left">{row.uf}</TableCell>
                <TableCell align="left">{row.city}</TableCell>
                <TableCell align="left">{row.schoolName}</TableCell>
                <TableCell align="left">{row.inep}</TableCell>
                <TableCell align="right" width="240">
                  <Button variant="contained" onClick={() => handleModal(row)}>
                    {school?.inep !== row.inep ? (
                      translate('SELECT_THIS_SCHOOL')
                    ) : (
                      <>
                        <CheckIcon /> {translate('SELECTED_SCHOOL')}
                      </>
                    )}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Pagination>
      </DialogContent>
      <DialogActions style={{ padding: '24px' }}>
        <Button onClick={() => handleModal(school || null)} variant="outlined">
          {translate('COMMONS:CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default wrapper(SelectSchoolModal, { namespace: 'SELECT_SCHOOL_MODAL' });
