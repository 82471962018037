import React, { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useStepperContext } from 'hooks/useStepper/StepperContext';

import { Box, Grid, Typography } from '@mui/material';

import { AsyncLoad } from 'components/AsyncLoad';
import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import SelectField from 'components/SelectField';

import { useRequestAccessContext } from '../../../../Context';
import { requestAccessUseCases } from '../../../../provider';
import { StyledBox } from '../../styles';
import ActionsButtons from '../ActionsButtons';

const LearningLevel = () => {
  const translate = useLangContext();
  const { fnNext } = useStepperContext();

  const [learningLevelList, setLearningLevelList] = useState([]);
  const { requestData, setRequestData } = useRequestAccessContext();

  const formMethods = useForm({
    defaultValues: {
      learningLevel: requestData?.learningLevel
    }
  });

  const {
    control,
    reset,
    formState: { isDirty }
  } = formMethods;

  const submitLearningLevel = useCallback(
    async formData => {
      if (isDirty) {
        const { learningLevel } = formData;

        try {
          const resp = await requestAccessUseCases.putRequestAccessTeacher({
            payload: { ...requestData, learningLevel },
            requestAccessTeacherId: requestData.id
          });

          setRequestData(resp);
        } catch (error) {
          console.error(error);
        }
      }
      return fnNext();
    },
    [isDirty, requestData, fnNext, setRequestData]
  );

  const getLearningLevelList = useCallback(async () => {
    if (!requestData?.inep) return;
    try {
      const resp = await requestAccessUseCases.getLearningLevelList(
        requestData.inep
      );

      setLearningLevelList(resp);

      if (
        !resp.find(
          learningLevel => learningLevel.id === requestData.learningLevel
        )
      ) {
        reset('learningLevel');
      }
    } catch (error) {
      console.error(error);
    }
  }, [requestData.inep, requestData.learningLevel, reset]);

  const learningLevel = useWatch({
    control,
    name: 'learningLevel'
  });

  return (
    <StyledBox>
      <Typography component="h3" variant="h6">
        {translate('SELECT_LEARNING_PHASE')}
      </Typography>
      <Form formMethods={formMethods} onSubmit={submitLearningLevel}>
        <Grid item xs={12}>
          <Box mt={3}>
            <AsyncLoad promiseFn={getLearningLevelList}>
              <SelectField
                name="learningLevel"
                label={translate('SELECT_LEARNING_PHASE_PLACEHOLDER')}
                placeholder={translate('COMMONS:SELECT')}
                optionRenderer={option => option.description}
                displayEmpty
                options={learningLevelList}
                formMethods={formMethods}
              />
            </AsyncLoad>
          </Box>
        </Grid>

        <ActionsButtons confirmButtonDisable={!learningLevel} />
      </Form>
    </StyledBox>
  );
};

export default LearningLevel;
