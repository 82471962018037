import { formatString } from 'helpers/formatString';

import { DOWNLOAD_WORK } from '../constants/api';

export default class DownloadWorkService {
  /**
   * @param {object} props
   * @param {import('adapters/axios').Axios} props.http
   */

  constructor({ http }) {
    this.http = http;
  }

  async getFileUrl(documentId) {
    const url = formatString(DOWNLOAD_WORK.GET_FILE_URL, {
      documentId
    });

    return this.http.get(url);
  }

  async getVolume(subscriptionVolumeId) {
    const url = formatString(DOWNLOAD_WORK.GET_VOLUME, {
      subscriptionVolumeId
    });
    return this.http.get(url);
  }

  async getCollections(subscriptionCollectionId) {
    const url = formatString(DOWNLOAD_WORK.GET_COLLECTIONS, {
      subscriptionCollectionId
    });
    return this.http.get(url);
  }
}
