import { createSlice } from '@reduxjs/toolkit';

export const INSTITUTION_REDUCER = 'institution';

const institution = createSlice({
  name: INSTITUTION_REDUCER,
  initialState: {
    history: { pageable: {} }
  },
  reducers: {
    setHistory: (state, { payload }) => {
      state.history = payload;
    }
  }
});

export const institutionActions = institution.actions;

export default institution.reducer;
