import React from 'react';

import { Box, Typography } from '@mui/material';

import wrapper from 'components/Wrapper';

import { INITIAL_PAGE_ROUTES } from './constants/routes';

const InitialPage = () => (
  <Box mt={10}>
    <Typography variant="h4" align="center">
      Bem vindo ao projeto digidistribuicao
    </Typography>
  </Box>
);

export default wrapper(InitialPage, {
  route: { path: INITIAL_PAGE_ROUTES.INITIAL_PAGE }
});
