import React, { useCallback, useEffect, useState } from 'react';

import { isArray, merge, orderBy } from 'lodash';
import PropTypes from 'prop-types';

import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  ListSubheader
} from '@mui/material';

import { useLangContext } from 'components/Globalization';
import InputSearch from 'components/InputSearch';
import TextField, { UncontrolledTextField } from 'components/TextField';

import {
  CustomBox,
  CustomListItemText,
  SelectAllBox,
  StyledFirstName,
  StyledMenuItem,
  StyledSecondName
} from './styles';

const ITEM_HEIGHT = 54;
const DEFAULT_LIMIT = 5;
const DEFAULT_OFFSET = 0;

const createOptionRenderer = labelKey => option => option[labelKey];
const groupOptions = (options, multiple) => {
  if (multiple) {
    const [first, ...rest] = options;
    return `${first}${rest.length ? ` + (${rest.length})` : ''}`;
  }
  return options[0];
};

const containsText = (text = '', searchText = '') => {
  if (searchText.trim() === '') {
    return true;
  }
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
};

const SelectField = ({
  options,
  optionRenderer: optionRendererProp,
  placeholder,
  idKey,
  labelKey,
  multiple,
  defaultValue,
  formMethods,
  menuProps,
  displayEmpty,
  grouped,
  isDisabled = false,
  enableFilter = false,
  renderDynamically = true,
  selectAll = false,
  hasMultiStyles = false,
  width = '100%',
  ...rest
}) => {
  const [searchText, setSearchText] = useState('');
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [selectRef, setSelectRef] = useState(null);
  const [selectAllInputValue, setSelectAllInputValue] = useState(false);
  const [values, setValues] = useState([]);
  const [optionsList, setOptionsList] = useState(options);
  const [displayedOptions, setDisplayedOptions] = useState(options);

  const translate = useLangContext();

  const Component = formMethods ? TextField : UncontrolledTextField;

  useEffect(() => {
    setOptionsList(options);

    if (grouped) {
      const orderByProfile = orderBy(options, ['profile'], ['asc']);
      return setOptionsList(orderByProfile);
    }

    return setDisplayedOptions(options);
  }, [grouped, options]);

  useEffect(() => {
    if (selectRef) {
      selectRef.scrollTop = 0;
      setLimit(DEFAULT_LIMIT);
    }
  }, [selectRef, searchText]);

  const stop = e => e.stopPropagation();

  const optionRenderer = optionRendererProp || createOptionRenderer(labelKey);

  const commonProps = {
    placeholder,
    multiple,
    defaultValue: defaultValue || (multiple ? [] : '')
  };

  const updateLimit = () => {
    if (displayedOptions?.length < options?.length) {
      setLimit(limit + DEFAULT_LIMIT);
    }
  };

  const MenuProps = {
    variant: 'menu',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null,
    MenuListProps: { disablePadding: true },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3,
        width: 250,
        padding: 0
      },
      onScroll: e => {
        const { scrollHeight, scrollTop, clientHeight } = e.target;
        setSelectRef(e.target);
        if (
          scrollHeight - Math.round(scrollTop) <= clientHeight &&
          renderDynamically
        ) {
          updateLimit();
        }
      }
    }
  };

  const formatSecondName = option => {
    if (!option) return '';
    return option.split('(')[1];
  };

  const formatFirstName = option => {
    if (!option) return '';
    return option.split('(')[0];
  };

  const selectProps = {
    multiple,
    displayEmpty,
    MenuProps: merge(MenuProps, menuProps),

    renderValue: selected => {
      if (selected === '' || selected) {
        const selectedArray = [].concat(selected);
        const selectedOptions = options.filter(
          option => selectedArray.indexOf(option[idKey]) >= 0
        );
        return !selectedOptions.length
          ? placeholder
          : groupOptions(
              selectedOptions.map(option => optionRenderer(option)),
              multiple
            );
      }
      return placeholder;
    },
    onOpen: async () => {
      setSearchText('');
      setLimit(DEFAULT_LIMIT);
    },
    onClose: () => {
      setSearchText('');
      setLimit(DEFAULT_LIMIT);
    }
  };

  const isChecked = useCallback(
    ({ value, option }) => isArray(value) && value.includes(option[idKey]),
    [idKey]
  );

  const handleSelectAll = useCallback(
    boolean => {
      if (!formMethods) {
        return;
      }

      const { setValue } = formMethods;

      setSelectAllInputValue(boolean);
      let filteredValues = [];

      if (boolean) {
        filteredValues = optionsList.map(item => item.id);
      } else if (values?.length !== optionsList.length) {
        filteredValues = options;
        setSelectAllInputValue(false);
      } else {
        filteredValues = [];
        setSelectAllInputValue(false);
      }

      setValue(rest?.name, filteredValues);
    },
    [formMethods, options, optionsList, rest?.name, values?.length]
  );

  useEffect(() => {
    const filteredOptions = options.filter(option =>
      containsText(option[labelKey], searchText)
    );

    if (renderDynamically && !selectAll) {
      const slicedOptions = filteredOptions.slice(DEFAULT_OFFSET, limit);
      setOptionsList(slicedOptions);
      setDisplayedOptions(slicedOptions);
    } else {
      setOptionsList(filteredOptions);
      setDisplayedOptions(filteredOptions);
    }
  }, [options, searchText, limit, renderDynamically, labelKey, selectAll]);

  useEffect(() => {
    if (!formMethods || !multiple) {
      return;
    }

    const { getValues, setValue } = formMethods;

    const valueContext = getValues()[rest?.name];

    if (rest?.name) {
      if (!valueContext || !valueContext?.length) {
        return;
      }
      setValues(getValues()[rest?.name]);

      const filteredValues = valueContext.reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);

      if (filteredValues.length) {
        setValue(rest?.name, filteredValues);
      }

      if (optionsList.length === filteredValues.length) {
        setSelectAllInputValue(true);
      } else {
        setSelectAllInputValue(false);

        setValue(rest?.name, filteredValues);
      }
    }
  }, [formMethods, rest?.name, multiple, optionsList]);

  return (
    <Component
      select
      {...rest}
      {...commonProps}
      {...(formMethods ? { formMethods } : null)}
      SelectProps={selectProps}
      disabled={isDisabled}
      style={{ width }}
    >
      {value =>
        options.length > 0 && [
          enableFilter && (
            <ListSubheader
              style={{ padding: '16px', background: '#fff' }}
              onClick={stop}
              key="searchField"
            >
              <InputSearch
                name="term"
                placeholder={translate('COMMONS:SELECT_PLACEHOLDER')}
                onSearch={setSearchText}
                select
              />
            </ListSubheader>
          ),
          selectAll && optionsList.length > 0 && !searchText && (
            <SelectAllBox>
              <MenuItem dense>
                <FormControlLabel
                  label={translate('COMMONS:SELECT_ALL')}
                  control={
                    <Checkbox
                      onChange={e => handleSelectAll(e.target.checked)}
                      checked={selectAllInputValue}
                    />
                  }
                />
              </MenuItem>
            </SelectAllBox>
          ),
          optionsList &&
            optionsList.map((option, idx) => (
              <StyledMenuItem
                key={option[idKey]}
                value={option[idKey]}
                disabled={isDisabled}
                flexDirection={grouped ? 'column' : 'row'}
                dense
              >
                {multiple && (
                  <Checkbox checked={isChecked({ value, option })} />
                )}
                {grouped &&
                  optionsList[idx - 1 ?? 0]?.profile !== option?.profile && (
                    <>
                      <CustomListItemText
                        marginTop="16px"
                        variant="subtitle2"
                        disabled
                      >
                        <b>{option.profile}</b>
                      </CustomListItemText>
                      <CustomBox />
                    </>
                  )}
                {hasMultiStyles ? (
                  <Grid container direction="row" alignItems="center">
                    <CustomListItemText flex="0 0 auto">
                      <StyledFirstName>
                        {formatFirstName(option.name)}
                      </StyledFirstName>
                    </CustomListItemText>
                    <CustomListItemText>
                      <StyledSecondName>
                        ({formatSecondName(option.name)}
                      </StyledSecondName>
                    </CustomListItemText>
                  </Grid>
                ) : (
                  <CustomListItemText
                    primary={optionRenderer(option)}
                    primaryTypographyProps={{
                      noWrap: false
                    }}
                  />
                )}
              </StyledMenuItem>
            ))
        ]
      }
    </Component>
  );
};

SelectField.defaultProps = {
  options: [],
  optionRenderer: null,
  label: '',
  placeholder: '',
  multiple: false,
  idKey: 'id',
  labelKey: 'name',
  defaultValue: undefined,
  formMethods: undefined,
  menuProps: {},
  enableFilter: false,
  renderDynamically: true
};

SelectField.propTypes = {
  menuProps: PropTypes.shape({}),
  formMethods: PropTypes.shape({}),
  options: PropTypes.instanceOf(Array),
  optionRenderer: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  idKey: PropTypes.string,
  labelKey: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  enableFilter: PropTypes.bool,
  renderDynamically: PropTypes.bool
};

export default SelectField;
