import React from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/styles';

import { useLangContext } from 'components/Globalization';

const TabContainer = ({
  tabs,
  currentTab,
  setCurrentTab,
  fullWidth,
  tabProps = {}
}) => {
  const translate = useLangContext();

  const CustomTab = styled(Tab)(({ theme }) => ({
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.white,
      color: theme.palette.common.white
    }
  }));

  return (
    <TabContext value={currentTab}>
      <TabList
        indicatorColor="primary"
        onChange={(_, key) => {
          setCurrentTab(key);
        }}
      >
        {tabs.map(tab => (
          <CustomTab
            key={tab.key}
            label={translate(tab.label)}
            value={tab.key}
            icon={tab.icon}
          />
        ))}
      </TabList>
      <hr />
      {tabs.map(tab => (
        <TabPanel
          sx={{
            py: 2,
            px: 0
          }}
          key={tab.key}
          value={tab.key}
          className={fullWidth}
        >
          <tab.Component {...tabProps} />
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default TabContainer;
