import { LocalStorage } from 'adapters/localStorage';
import { AwsTokenManager } from 'managers/awsTokenManager';
import { TokenManager } from 'managers/tokenManager';

const localStorage = new LocalStorage();

const tokenManager = new TokenManager({
  storage: localStorage,
  storageKey: 'ngStorage-jwt'
});

const awsTokenManager = new AwsTokenManager({
  storage: localStorage,
  storageKey: 'ngStorage-jwt'
});

export { tokenManager, awsTokenManager };
