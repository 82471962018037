import { authActions } from 'framework/store/slices/auth.slice';

export default class AuthUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('modules/User/providers/user.service').default} props.userService
   * @param {import('modules/Auth/providers/auth.service').default} props.authService
   */
  constructor({ store, authService, userService }) {
    this.store = store;
    this.authService = authService;
    this.userService = userService;
  }

  // eslint-disable-next-line no-unused-vars
  async login({ username, password, token }) {
    // await this.authService.validateRecaptcha(token)

    const user = await this.authService.signIn(username, password);

    if (user.challengeName) {
      await this.store.dispatch(authActions.challengeUser(user));
    } else {
      await this.signIn(user.getSignInUserSession());
    }

    return user;
  }

  async signIn(session) {
    try {
      const user = await this.userService.me();
      this.store.dispatch(authActions.signIn({ user, session }));
    } catch (error) {
      console.error('SignIn Failed!', error);
    }
  }

  async updateCurrentUserInformation() {
    const user = await this.userService.me();

    this.store.dispatch(authActions.me(user));
  }

  async signOut() {
    this.store.dispatch(authActions.signOut());
  }

  async restoreCurrentSession() {
    try {
      const session = await this.authService.restoreUserSession();

      await this.signIn(session);
    } catch (error) {
      this.signOut();
    }
  }
}
