import styled from 'styled-components';

import { Button, Grid, Typography } from '@mui/material';

export const TitlePage = styled.h1`
  color: ${props => props.theme.bgColors.colorSecondary08};
  margin: 32px 0;
`;

export const TitleStep = styled.h1`
  color: ${props => props.theme.palette.text.primary};
  font-weight: normal;
  line-height: 40px;
  margin: 0 0 24px;
`;

export const Box = styled.div`
  background: ${props => props.theme.palette.background.white};
  border: 1px solid ${props => props.theme.palette.border.primary};
  border-radius: 4px;
  box-shadow: 4px 4px 4px rgba(0; 0, 0, 0.1);
  padding: 24px;
  margin: 48px 0;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 44px;
  position: relative;
  margin-bottom: 48px;

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledActionButton = styled(Button)`
  padding: 8px 24px;
  font-size: 16px;
  line-height: 20px;
`;

export const StyledCancelButton = styled(StyledActionButton)`
  margin-right: 8px;
`;

export const Card = styled(Grid)`
  background: ${props => props.theme.palette.background.white};
  align-items: center;
  padding: 16px;
  display: flex;
  height: 458px;

  box-shadow: 0px 1px 6px rgba(51, 51, 51, 0.16);

  ${props => props.theme.breakpoints.down('sm')} {
    height: auto;
    flex-direction: column;
    width: 80%;
  }
`;

export const TitleCard = styled.p`
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-top: 40px;
  font-family: ${props => props.theme.typography.fontFamily};
`;

export const DescriptionCard = styled.span`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
  width: 60%;
  font-family: ${props => props.theme.typography.fontFamily};
`;

export const StyledImage = styled.img`
  width: 85%;

  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  color: ${props => props.theme.palette.primary.main};
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  padding: 8px 24px;
  border: 1px solid ${props => props.theme.palette.primary.main};
  border-radius: 20px;
  background-color: ${props => props.theme.palette.background.white};
  font-family: ${props => props.theme.typography.fontFamily};
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  opacity: ${props => props.disabled && `0.35`};

  ${props => props.theme.breakpoints.down('md')} {
    position: relative;
    margin-top: 42px;
  }
`;

export const FormTitle = styled(Typography)`
  line-height: 24px;
  font-weight: 700;
`;

export const SummaryTitle = styled.h1`
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const SummarySectionTitle = styled.h2`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  margin-right: 8px;
`;

export const SummaryItem = styled.p`
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 6px;
`;

export const Section = styled.div`
  width: 100%;
`;

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const EditButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: ${props => props.theme.palette.background.white};
  padding: 32px;
  box-shadow: 0px 1px 6px rgba(51; 51; 51; 0.16);
  width: 462px;
  flex-direction: column;
  margin-bottom: 120px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.palette.border.light};
  margin: 8px 0 16px 0;
`;

export const SuccessRequestImage = styled.img`
  height: 236px;
  width: 205px;
`;

export const RequestCompletedTitle = styled.h1`
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 28px;
  font-weight: 400;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }
`;

export const RequestCompletedDescription = styled.h2`
  margin-bottom: 16px;
  max-width: 704px;
  font-weight: 400;
  font-size: 16px;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    font-size: 14px;
    text-align: center;
  }
`;

export const RequestCompletedInfo = styled.h2`
  font-size: 16px;
  margin-bottom: 52px;
  font-weight: 400;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    font-size: 14px;
    text-align: center;
    margin-bottom: 32px;
  }
`;

export const ButtonRequestCompletedWrapper = styled(Grid)`
  width: 92%;
  display: flex;
  justify-content: end;
  gap: 16px;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    flex-direction: column;
    height: 80px;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ButtonDoneOutlined = styled(Button)`
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const ButtonDoneContained = styled(Button)`
  width: 252px;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;
