import React from 'react';

import { Grid } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import PublicContainer from 'components/PublicContainer';
import wrapper from 'components/Wrapper';

import { AUTH_ROUTES } from 'modules/Auth/constants/routes';

import passwordBookSteps from 'assets/img/manual_passo_a_passo.jpg';

import { LeftContainer, StyledImage } from './style';

function StepPasswordBook() {
  const translate = useLangContext();

  return (
    <PublicContainer>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <LeftContainer>
            <StyledImage
              src={passwordBookSteps}
              alt={translate('BOOK_PASSWORD:ALT_IMAGE_STEP')}
            />
          </LeftContainer>
        </Grid>
      </Grid>
    </PublicContainer>
  );
}

export default wrapper(StepPasswordBook, {
  namespace: 'BOOK_PASSWORD',
  route: {
    path: AUTH_ROUTES.BOOK_PASSWORD
  }
});
