/* eslint-disable class-methods-use-this */
import { Auth, Hub } from 'aws-amplify';

import { clearWarnings } from 'framework/store/slices/warnings.slice';

import { AUTH_API } from '../constants/api';

export default class AuthService {
  /**
   * @param {object} props
   * @param {import('adapters/axios').Axios} props.http
   */
  constructor({ http, store }) {
    this.http = http;
    this.store = store;
  }

  async signIn(username, password) {
    // await Auth.signOut({ global: true });
    const user = await Auth.signIn(username, password);
    return user;
  }

  async signOut() {
    sessionStorage.clear();
    this.store.dispatch(clearWarnings());
    await Auth.signOut();
  }

  async completeNewPassword({ challenge, password }) {
    await Auth.completeNewPassword(
      challenge,
      password,
      null,
      challenge.challengeParam.userAttributes
    );
  }

  async restoreUserSession() {
    const userSession = await Auth.currentAuthenticatedUser();
    const session = await userSession.getSignInUserSession();

    return session;
  }

  async validateRecaptcha(token) {
    const response = await this.http.post(AUTH_API.RECAPTCHA_VALIDATION, {
      gRecaptchaResponse: token,
      gRecaptchaVersion: 'v2'
    });
    return response;
  }

  async transactionToken() {
    const response = await this.http.get(AUTH_API.TRANSACTION_TOKEN);
    return response;
  }

  async getUserToken() {
    try {
      const currentSession = await Auth.currentSession();

      if (!currentSession.isValid()) return null;

      return currentSession.getIdToken().getJwtToken();
    } catch (error) {
      return null;
    }
  }

  registerListener(event, listener) {
    Hub.listen(event, listener);
  }
}
