import React, { useState, useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useStepperContext } from 'hooks/useStepper/StepperContext';

import { Box, Grid, Typography } from '@mui/material';

import history from 'framework/history';
import { LIST_UFS } from 'helpers/constants/uf';

import { AsyncLoad } from 'components/AsyncLoad';
import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import SelectField from 'components/SelectField';

import { REQUEST_ACCESS_ROUTES } from '../../../../constants/routes';
import { useRequestAccessContext } from '../../../../Context';
import { requestAccessUseCases } from '../../../../provider';
import { StyledBox } from '../../styles';
import ActionsButtons from '../ActionsButtons';

const ufOptions = LIST_UFS.map(ufItem => ({
  id: ufItem.initials,
  name: ufItem.name
}));

const SelectCity = () => {
  const translate = useLangContext();

  const { fnNext } = useStepperContext();
  const { requestData, setRequestData } = useRequestAccessContext();

  const [cityOptions, setCityOptions] = useState([]);

  const formMethods = useForm({
    defaultValues: {
      uf: requestData?.uf,
      city: requestData?.city
    }
  });

  const {
    control,
    setValue,
    reset,
    formState: { isDirty }
  } = formMethods;

  const { city: watchCity, uf: watchUf } = useWatch({
    control,
    name: ['city', 'uf']
  });

  const fetchCity = useCallback(async () => {
    if (!watchUf) return;
    try {
      const response = await requestAccessUseCases.getCityList(watchUf);

      setCityOptions(response);
    } catch (e) {
      console.error(e);
    }
  }, [watchUf]);

  const handleBack = () => {
    history.push(REQUEST_ACCESS_ROUTES.IDENTIFY_USER);
  };

  const submitLocality = useCallback(
    async formData => {
      if (isDirty) {
        try {
          const resp = await requestAccessUseCases.putRequestAccessTeacher({
            payload: { ...requestData, ...formData, inep: null, school: null },
            requestAccessTeacherId: requestData.id
          });

          setRequestData(prev => ({
            ...prev,
            ...resp
          }));
          fnNext();
        } catch (error) {
          console.error(error);
        }
      } else {
        fnNext();
      }
    },
    [fnNext, isDirty, requestData, setRequestData]
  );

  useEffect(() => {
    if (watchUf !== requestData?.uf) reset({ city: null, uf: watchUf });
  }, [reset, setValue, requestData, watchUf]);

  return (
    <StyledBox>
      <Typography component="h3" variant="h6">
        {translate('SELECT_CITY')}
      </Typography>
      <Box mt={3}>
        <Form formMethods={formMethods} onSubmit={submitLocality}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectField
                name="uf"
                label={translate('UF')}
                placeholder={translate('COMMONS:UF_PLACEHOLDER')}
                displayEmpty
                options={ufOptions}
                renderDynamically={false}
                formMethods={formMethods}
                enableFilter
              />
            </Grid>
            <Grid item xs={12}>
              <AsyncLoad promiseFn={fetchCity}>
                <SelectField
                  name="city"
                  label={translate('COMMONS:CITY')}
                  placeholder={translate('COMMONS:CITY_PLACEHOLDER')}
                  displayEmpty
                  options={cityOptions}
                  renderDynamically={false}
                  formMethods={formMethods}
                  isDisabled={!watchUf}
                  enableFilter
                />
              </AsyncLoad>
            </Grid>
          </Grid>
          <ActionsButtons
            confirmButtonDisable={!watchCity}
            customHandleBack={handleBack}
          />
        </Form>
      </Box>
    </StyledBox>
  );
};

export default SelectCity;
