import styled from 'styled-components';

export const Footer = styled.footer`
  align-items: center;
  background: ${props => props.theme.palette.background.white};
  border-top: 1px solid ${props => props.theme.palette.border.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  img {
    max-width: 100%;
  }

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    img {
      height: 80px;
      object-fit: cover;
    }
  }
`;

export const FooterCopy = styled.div`
  align-items: center;
  background: ${props => props.theme.palette.background.copyright};
  border-top: 1px solid ${props => props.theme.palette.border.primary};
  color: ${props => props.theme.palette.text.light};
  display: flex;
  font-size: 14px;
  height: 48px;
  justify-content: center;
  line-height: 16px;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    padding: 0 12px;
    text-align: center;
    height: 64px;
  }
`;

export const Img = styled.img`
  max-width: 100%;
`;
