import { formatString } from 'helpers/formatString';

import { LITERARY_DIDACTIC_WORKS } from '../constants/api';

export default class LiteraryDidacticWorksService {
  /**
   * @param {object} props
   * @param {import('adapters/axios').Axios} props.http
   */

  constructor({ http }) {
    this.http = http;
  }

  async getSchoolSegmentList() {
    return this.http.get(LITERARY_DIDACTIC_WORKS.GET_SCHOOLS_SEGMENT_LIST);
  }

  async getGradeList(inep, segmentId) {
    const url = formatString(LITERARY_DIDACTIC_WORKS.GET_GRADE_LIST, {
      inep,
      segmentId
    });
    return this.http.get(url);
  }

  async getTypeLiteraryList(inep, segmentId, seriesId) {
    const url = formatString(LITERARY_DIDACTIC_WORKS.GET_TYPE_LITERARY_LIST, {
      inep,
      segmentId,
      seriesId
    });
    return this.http.get(url);
  }

  async getComponentList(inep, segmentId, seriesId, typeLiterary) {
    const url = formatString(LITERARY_DIDACTIC_WORKS.GET_COMPONENT_LIST, {
      inep,
      segmentId,
      seriesId,
      typeLiterary
    });
    return this.http.get(url);
  }

  async postBooksTeacherSearch(payload) {
    return this.http.post(
      LITERARY_DIDACTIC_WORKS.POST_BOOKS_TEACHER_SEARCH,
      payload
    );
  }

  async getRelatedWorkList(inep, collectionId) {
    const url = formatString(LITERARY_DIDACTIC_WORKS.GET_RELATED_WORK_LIST, {
      inep,
      collectionId
    });
    return this.http.get(url);
  }

  async getSchoolSegmentStudentList() {
    return this.http.get(
      LITERARY_DIDACTIC_WORKS.GET_SCHOOLS_SEGMENT_STUDENT_LIST
    );
  }

  async getTypeLiteraryStudentList(inep, segmentId) {
    const url = formatString(
      LITERARY_DIDACTIC_WORKS.GET_TYPE_LITERARY_STUDENT_LIST,
      {
        inep,
        segmentId
      }
    );
    return this.http.get(url);
  }

  async getComponentStudentList(inep, segmentId) {
    const url = formatString(
      LITERARY_DIDACTIC_WORKS.GET_COMPONENT_STUDENT_LIST,
      {
        inep,
        segmentId
      }
    );
    return this.http.get(url);
  }

  async postBooksStudentSearch(payload) {
    return this.http.post(
      LITERARY_DIDACTIC_WORKS.POST_BOOKS_STUDENT_SEARCH,
      payload
    );
  }

  async postReceivePasswordByEmail() {
    return this.http.post(LITERARY_DIDACTIC_WORKS.RECEIVE_PASSWORD_BY_EMAIL);
  }

  async getFileUrl(id) {
    const url = formatString(LITERARY_DIDACTIC_WORKS.GET_FILE_URL, {
      id
    });
    return this.http.get(url);
  }

  async getLicensesInfo() {
    return this.http.get(LITERARY_DIDACTIC_WORKS.GET_LICENSES_PENDING);
  }
}
