/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

const regexToEmail = /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/;

Yup.addMethod(
  Yup.string,
  'email',
  function emailValidation(errorMessage = 'VALIDATION:INVALID_EMAIL') {
    return this.test({
      message: errorMessage,
      name: 'email',
      test: value => !value || value.match(regexToEmail)
    });
  }
);
