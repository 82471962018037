import styled from 'styled-components';

import { Box } from '@mui/material';

export const StyledBox = styled(Box)`
  max-width: 600px;
  margin: 50px auto;
  padding: 40px;
  border-radius: 5px;
  background: ${props => props.theme.palette.background.white};
  box-shadow: 0px 3px 6px ${props => props.theme.palette.auxColors.boxShadow};
`;

export const SchoolName = styled.div`
  color: ${props => props.theme.palette.text.primary};
  border: 1px solid ${props => props.theme.palette.border.light};
  padding: 15px;
  border-radius: 3px;
  background-color: ${props => props.theme.palette.border.primary};
  cursor: pointer;
`;

export const SummaryBox = styled(StyledBox)`
  max-width: 462px;
  padding: 40px 32px;
`;
