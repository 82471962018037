import React from 'react';

import { Box, Grid } from '@mui/material';

import { ContainerBackground, ItemList, TextCard } from './styles';

const TermsOfUseContent = () => (
  <ContainerBackground>
    <p>
      Neste documento são apresentados os termos e condições de uso do
      <b> Portal do Livro Digital e do Leitor Mobile do PNLD </b>
      (“Portal”), disponibilizados pelo{' '}
      <b>Fundo Nacional de Desenvolvimento da Educação</b> (“FNDE”) no âmbito de
      sua competência de operacionalizar o Programa Nacional do Livro e do
      Material Didático (“PNLD”).
      <br />
      <br />
      Em linhas gerais, nesse documento estão elencadas as regras para
      utilização do Portal.
    </p>
    <br />
    <p>
      <b>1. CARACTERÍSTICAS GERAIS DO PORTAL. </b>
      O Portal é gerenciado pelo Fundo Nacional de Desenvolvimento da Educação
      (“FNDE”), autarquia federal vinculada ao Ministério da Educação (MEC) no
      âmbito da qual é executado o Programa Nacional do Livro e do Material
      Didático – PNLD.
      <br />
      <br />
      O Portal tem como objetivo disponibilizar obras e materiais educacionais
      adquiridos no âmbito do PNLD a estudantes, professores e
      representantes/dirigentes de escolas públicas de educação básica de forma
      gratuita e em formato digital.
      <br />
      <br />
      <b>2. ACESSO E UTILIZAÇÃO. </b>
      Para a utilização do Portal, é necessária a autenticação do usuário via
      acesso integrado com a plataforma gov.br.
    </p>
    <br />
    <p>
      O uso do Portal envolve diferentes perfis de usuários (em conjunto,
      “Usuários”):
    </p>
    <br />
    <ItemList>
      <p>
        <li>
          <b>Representantes de unidade de ensino: </b>
          responsável pela aprovação de cadastros de professores, estudantes,
          pais ou responsáveis legais, de sua respectiva unidade de ensino no
          Portal.
        </li>

        <li>
          <b>Professor: </b>
          professor que, mediante cadastro e aprovação pelo representante da
          unidade de ensino, poderá utilizar o Portal para acessar os livros e
          materiais didáticos do PNLD.
        </li>

        <li>
          <b>Estudante: </b>
          aluno maior de 12 anos que, mediante cadastro e aprovação pelo
          representante da unidade de ensino, poderá utilizar o Portal para
          acessar os livros e materiais didáticos do PNLD.
        </li>

        <li>
          <b>Mãe, pai ou responsável legal: </b>
          usuários que, mediante cadastro, Termo de Consentimento e aprovação
          pelo representante da unidade de ensino, poderão utilizar o Portal
          para que aluno menor de 12 anos tenha acesso aos livros e materiais
          didáticos do PNLD.
        </li>
      </p>
    </ItemList>
    <br />
    <p>
      Ao utilizar a Plataforma, os Usuários assumem responsabilidade pelas
      informações fornecidas quando do cadastro. A aprovação do cadastro está
      condicionada ao fornecimento de informações verídicas, precisas e
      atualizadas.
    </p>
    <br />
    <p>
      O tratamento de dados pessoais (conforme classificação contida na Lei
      Federal nº 13.709/2018) de crianças (indivíduos menores de 12 anos, nos
      termos da Lei Federal nº 8.069/1990) está condicionado à coleta de
      consentimento específico e destacado de um dos pais ou representante
      legal. A inobservância desse requisito acarretará a inabilitação do
      cadastro e/ou a exclusão das informações da criança.
    </p>
    <br />
    <p>
      Em caso de suspeita de uso irregular, inclusive em desacordo com as regras
      trazidas nos presentes Termos de Uso, o Usuário responsável fica sujeito à
      suspensão e cancelamento do cadastro, sem que seja devida qualquer
      indenização ou compensação, bem como a demais medidas legais.
    </p>
    <br />
    <p>
      <b>3. DEVERES / RESPONSABILIDADES DOS USUÁRIOS. </b>O Portal deve ser
      utilizado única e exclusivamente para os propósitos relacionados à
      disponibilização e acesso de obras e materiais de apoio à prática
      educativa no âmbito do PNLD, sendo vedada sua utilização para finalidades
      diversas.
    </p>
    <br />
    <p>
      Importante registrar que o FNDE não se responsabiliza pelo uso do Portal
      para finalidades distintas daquelas definidas no escopo de suas
      competências relativas ao Programa Nacional do Livro e do Material
      Didático.
    </p>
    <br />
    <p>
      Para o bom funcionamento do Portal, os usuários se declaram cientes e
      concordam com os deveres e condições apresentados a seguir:
    </p>
    <br />
    <Grid container>
      <Grid item xs={12} lg={6}>
        <TextCard>São deveres dos usuários:</TextCard>
        <Box padding={2}>
          <p>
            <ItemList>
              <li>
                Garantir a veracidade e legalidade das informações fornecidas
                quando do cadastro no Portal;
              </li>
              <li>
                Manter a guarda e a integridade das credenciais de acesso,
                ciente de que estas são de uso pessoal, intransferível e
                confidencial;
              </li>
              <li>
                Alterar, imediatamente, suas credenciais de acesso caso haja
                suspeita ou confirmação quanto à perda da confidencialidade
                dessas informações;
              </li>
              <li>Reportar qualquer situação irregular no uso do Portal;</li>
              <li>
                Utilizar o Portal de acordo com a boa-fé e com as leis
                aplicáveis, inclusive a de proteção de dados pessoais.
              </li>
            </ItemList>
          </p>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextCard>É vedado aos usuários:</TextCard>
        <Box padding={2}>
          <p>
            <ItemList>
              <li>
                Utilizar as credenciais de acesso de outra pessoa, fazendo-se
                passar por terceiro ou manipulando identificadores;
              </li>
              <li>
                Compartilhar, exibir, circular ou transferir as credenciais de
                acesso a terceiros;
              </li>
              <li>
                Inserir, compartilhar ou reproduzir qualquer tipo de conteúdo
                ilegal, ameaçador, fraudulento, difamatório, abusivo, ofensivo,
                enganoso, discriminatório ou que seja capaz de violar os
                direitos do Portal e/ou de terceiros;
              </li>
              <li>
                Interferir no funcionamento operacional do Portal, seus
                servidores e/ou redes;
              </li>
              <li>
                Vender, licenciar, copiar, reproduzir, transferir ou aferir
                qualquer forma de lucro a partir dos dados e conteúdos
                disponibilizados no Portal;
              </li>
              <li>
                Introduzir vírus, worms, códigos maliciosos e/ou qualquer tipo
                de arquivo ou programa que tenha como finalidade prejudicar o
                funcionamento da Portal.
              </li>
            </ItemList>
          </p>
        </Box>
      </Grid>
    </Grid>
    <br />
    <br />
    <p>
      Sem prejuízo das medidas cabíveis em âmbito judicial ou administrativo, o
      FNDE poderá bloquear, desativar ou remover, temporária ou definitivamente,
      o perfil de acesso e/ou quaisquer conteúdos disponibilizados por usuários,
      caso identifique o descumprimento das condições acima relacionadas.
    </p>
    <br />
    <p>
      <b>4. DIREITOS AUTORIAIS E CONEXOS. </b>
      Todas as obras e materiais educacionais disponibilizados no Portal são de
      propriedade exclusiva dos respectivos autores, detentores de direitos
      autorais, sendo destinados exclusivamente ao uso não comercial,
      respeitadas as finalidades do Portal e os limites previstos na Lei Federal
      n.º 9.610/1998 (Lei de Direitos Autorais).
    </p>
    <br />
    <p>
      É vedado aos Usuários reproduzir, publicar, alugar, vender, transferir,
      distribuir, oferecer ou expor qualquer cópia, parcial ou integral, de
      quaisquer dos materiais disponibilizados no Portal, ressalvada a
      utilização no contexto escolar, desde que respeitados os limites da
      legislação aplicável.
    </p>
    <br />
    <p>
      <b>5. SEGURANÇA. </b>Para a disponibilização do Portal e manutenção da
      infraestrutura, sistemas e bases de dados, são adotadas medidas para
      analisar o desempenho, corrigir erros e garantir o funcionamento adequado
      da plataforma, além de controles de segurança físicos, eletrônicos e
      operacionais com o objetivo de prevenir a ocorrência de acessos não
      autorizados e/ou situações acidentais ou ilícitas de destruição, perda,
      alteração, comunicação ou difusão, nos termos da Política Nacional de
      Segurança da Informação.
    </p>
    <br />
    <p>
      Ainda assim, não é possível assegurar - de forma absoluta - que o Portal
      se manterá livre de instabilidades, interrupções e/ou ações indevidas por
      parte de terceiros. Nesse sentido, embora o Portal tenha como pressuposto
      a disponibilidade contínua, não se pode assegurar que as funcionalidades
      estarão disponíveis de modo ininterrupto, sendo possível a ocorrência de
      instabilidades ou indisponibilidades decorrentes de questões diversas de
      ordem técnica, as quais devem ser esperadas pelos usuários, tais como a
      realização de manutenção dos servidores, ajustes emergenciais aos sistemas
      de informação, entre outros.
    </p>
    <br />
    <p>
      <b>6. CONDIÇÕES GERAIS. </b>A concordância com os Termos de Uso é condição
      necessária para a utilização do Portal. Estes Termos poderão sofrer
      atualizações e ajustes a qualquer tempo. Caso haja atualização relevante
      nas condições de uso e acesso do Portal, utilizaremos os meios disponíveis
      para informar os usuários quanto ao teor das alterações.
    </p>
    <br />
    <p>
      <b>7. CANAL DE CONTATO. </b>Em caso de dúvidas, sugestões ou reporte de
      problemas técnicos relacionados ao Portal, você poderá entrar em contato
      com o FNDE através do(s) seguinte(s) canal(is): Serviço de Atendimento ao
      Cidadão através do telefone: 0800-616161 ou e-mail: ouvidoria@fnde.gov.br.
    </p>
    <br />
    <p>Data da última atualização: 16/10/2024.</p>
  </ContainerBackground>
);

export default TermsOfUseContent;
