import React from 'react';

import { Box, Button, DialogActions, DialogContent, Grid } from '@mui/material';

import history from 'framework/history';

import { Dialog, DialogTitle } from 'components/Dialog';
import { useLangContext } from 'components/Globalization';

import { APP_ROUTES } from 'modules/App/constants/route';

const RefuseModal = ({ open, closeModal }) => {
  const translate = useLangContext();

  return (
    <Dialog maxWidth="sm" onClose={closeModal} open={open}>
      <DialogTitle style={{ padding: '24px' }}>
        {translate('REFUSE_TERMS_MODAL.TITLE')}
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          {translate('REFUSE_TERMS_MODAL.CONTENT')}
        </Grid>
      </DialogContent>
      <Box m={3}>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              closeModal();
              history.push(APP_ROUTES.BASE_ROUTES);
            }}
          >
            {translate('COMMONS:YES')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              closeModal();
            }}
          >
            {translate('COMMONS:NO')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default RefuseModal;
