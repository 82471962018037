import React from 'react';

import { SnackbarProvider } from 'notistack';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  containerRoot: {
    top: '139px !important',
    maxWidth: '100% !important',
    left: '0 !important',
    width: '100% !important',
    '& .MuiCollapse-root': {
      width: '100%'
    }
  },
  variantError: {
    backgroundColor: `${theme.palette.error.light} !important`,
    borderRadius: '0px !important',
    color: `${theme.palette.error.main} !important`,
    fontSize: '16px !important',
    height: '72px',
    padding: '0 48px !important',
    width: '100%'
  },
  variantSuccess: {
    backgroundColor: `${theme.palette.success.light} !important`,
    borderRadius: '0px !important',
    color: `${theme.palette.success.main} !important`,
    fontSize: '16px !important',
    height: '72px',
    padding: '0 48px !important',
    width: '100%'
  },
  variantWarning: {
    backgroundColor: `${theme.palette.warning.light} !important`,
    borderRadius: '0px !important',
    color: `${theme.palette.warning.contrastText} !important`,
    fontSize: '16px !important',
    height: '72px',
    padding: '0 48px !important',
    width: '100%'
  }
}));

const NotificationProvider = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      classes={classes}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotificationProvider;
