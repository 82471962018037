import styled from 'styled-components';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button, Typography } from '@mui/material';

export const ConfirmRegisterButton = styled(Button)`
  width: '127px';
`;

export const EditIcon = styled(ModeEditIcon)`
  color: ${props => props.theme.palette.primary.main};
`;

export const CheckData = styled(Typography)`
  &.MuiTypography-root {
    font-size: 34px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 30px;
      width: 100%;
      text-align: center;
    }
  }
`;
