import { zonedTimeToUtc } from 'date-fns-tz';
import * as yup from 'yup';

function asUTCDate() {
  return this.transform((value, originalValue) => {
    try {
      if (originalValue === null) return originalValue;

      return zonedTimeToUtc(originalValue);
    } catch {
      return yup.date.INVALID_DATE;
    }
  });
}

yup.addMethod(yup.date, 'asUTCDate', asUTCDate);
