import React, { useCallback } from 'react';

import { Add, Delete } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import history from 'framework/history';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { notificationUseCases } from 'modules/Notification/providers';
import { REQUEST_ACCESS_ROUTES } from 'modules/RequestAccess/constants/routes';
import { useRequestAccessStepContext } from 'modules/RequestAccess/fragments/Context';
import { requestAccessUseCases } from 'modules/RequestAccess/provider';

const ManagerComplementaryData = ({ data, onAdd, onRemove, userGovBr }) => {
  const translate = useLangContext();
  const { setStep } = useRequestAccessStepContext();

  const handleFinish = useCallback(async () => {
    const result = data.reduce((acc, obj) => {
      const key = `${obj.school.id}_${obj.learningLevel.id}`;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, Object.create(null));

    const greaterThanOne = Object.keys(result).filter(key => result[key] > 1);

    if (greaterThanOne.length > 0) {
      notificationUseCases.error('SAME_REQUESTS', {
        namespace: 'ERROR'
      });
      return null;
    }

    const requestList = data?.map(item => ({
      educationalInstitution: item.school.id,
      learningLevel: item.learningLevel.id
    }));

    const payload = {
      ...userGovBr,
      profile: 'ROLE_TEACHER',
      requestList
    };

    try {
      await requestAccessUseCases.postTeacherRequest(payload);
      setStep(3);
    } catch (error) {
      if (error.response.status === 201) {
        notificationUseCases.error('USER_ALREADY_HAS_REGISTRATION', {
          namespace: 'ERROR'
        });
      } else if (
        (error.response.status === 406,
        error.response.data.message === 'user.with.another.permission')
      ) {
        notificationUseCases.error('USER_WITH_ANOTHER_PERMISSION', {
          namespace: 'ERROR'
        });
      } else if (
        (error.response.status === 406,
        error.response.data.message === 'cpf.already.requested.for.teacher')
      ) {
        notificationUseCases.error('CPF_ALREADY_REQUESTED_FOR_TEACHER', {
          namespace: 'ERROR'
        });
      } else if (error.response.status === 404) {
        history.push(REQUEST_ACCESS_ROUTES.REQUEST_ACCESS);
      } else {
        console.error(error);
      }
    }
    return null;
  }, [data, setStep, userGovBr]);

  return (
    <>
      <Grid container spacing={3} py={3}>
        <Grid item container justifyContent="flex-end" xs={12}>
          <IconButton color="primary" aria-label="add" onClick={() => onAdd()}>
            <Add />
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">{translate('UF')}</TableCell>
                <TableCell align="left">{translate('CITY')}</TableCell>
                <TableCell align="left">{translate('SCHOOL')}</TableCell>
                <TableCell align="left">
                  {translate('STAGE_EDUCATION')}
                </TableCell>
                <TableCell align="left">
                  {translate('COMMONS:ACTIONS')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <TableRow key={row.id}>
                  <TableCell align="left">{row.uf.name}</TableCell>
                  <TableCell align="left">{row.city.name}</TableCell>
                  <TableCell align="left">{row.school.name}</TableCell>
                  <TableCell align="left">{row.learningLevel.name}</TableCell>
                  <TableCell align="left">
                    <IconButton
                      aria-label="delete"
                      onClick={() => onRemove(row)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item container justifyContent="flex-end" xs={12}>
          <Button
            variant="contained"
            disabled={data.length === 0}
            onClick={() => handleFinish()}
          >
            {translate('COMMONS:FINISH')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default wrapper(ManagerComplementaryData, {
  namespace: 'REQUEST_ACCESS',
  mapState: state => ({
    userGovBr: state.auth.userGovBr
  })
});
