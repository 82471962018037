export const LITERARY_DIDACTIC_WORKS = {
  GET_SCHOOLS_SEGMENT_LIST:
    '/distribution-digital/books-teacher/schools-segments',
  GET_GRADE_LIST:
    '/distribution-digital/books-teacher/{inep}/grade/{segmentId}',
  GET_TYPE_LITERARY_LIST:
    '/distribution-digital/books-teacher/{inep}/type-literary/{segmentId}/{seriesId}',
  GET_COMPONENT_LIST:
    '/distribution-digital/books-teacher/{inep}/component/{segmentId}/{seriesId}/{typeLiterary}',
  POST_BOOKS_TEACHER_SEARCH: '/distribution-digital/books-teacher/search',
  GET_RELATED_WORK_LIST:
    '/distribution-digital/books-teacher/{inep}/collection/{collectionId}',
  GET_SCHOOLS_SEGMENT_STUDENT_LIST:
    '/distribution-digital/books-student/schools-segments',
  GET_TYPE_LITERARY_STUDENT_LIST:
    '/distribution-digital/books-student/type-record/{inep}/{segmentId}',
  GET_COMPONENT_STUDENT_LIST:
    '/distribution-digital/books-student/componente/{inep}/{segmentId}',
  POST_BOOKS_STUDENT_SEARCH: '/distribution-digital/books-student/search',
  RECEIVE_PASSWORD_BY_EMAIL: '/integration-drm/reset-password',
  GET_FILE_URL: '/document/{id}',
  GET_LICENSES_PENDING: '/users/status-license'
};
