import React, { useCallback, useState } from 'react';

import { useStepperContext } from 'hooks/useStepper/StepperContext';

import { Button, Grid } from '@mui/material';

import { AsyncLoad } from 'components/AsyncLoad';
import { useLangContext } from 'components/Globalization';
import SelectField from 'components/SelectField';
import wrapper from 'components/Wrapper';

import { notificationUseCases } from 'modules/Notification/providers';
import { requestAccessUseCases } from 'modules/RequestAccess/provider';

import { Box } from '../../../../styles';

const SelectStageActingEducation = ({ userGovBr }) => {
  const translate = useLangContext();
  const { fnBack, fnSetDataByKey, stepperData, fnNext } = useStepperContext();
  const [learningLevel, setLearningLevel] = useState();
  const [learningLevelOptions, setLearningLevelOptions] = useState([]);

  const fetchLearningLevelOptions = useCallback(async () => {
    try {
      const response = await requestAccessUseCases.getLearningLevelList(
        stepperData.school.id
      );
      setLearningLevelOptions(
        response.map(({ id, description }) => ({ id, name: description }))
      );
    } catch (error) {
      console.error(error);
    }
  }, [stepperData.school.id]);

  const handleSelectedLearningLevel = async () => {
    const { id, name } = learningLevelOptions.find(
      item => item.id === learningLevel
    );

    try {
      await requestAccessUseCases.getCheckTeacherAccessRequest(
        userGovBr.cpf,
        stepperData.school.id,
        id
      );

      fnSetDataByKey('learningLevel', { id, name });
      fnNext();
    } catch (error) {
      if (error.response.status === 409) {
        notificationUseCases.error('TEACHER_ACCESS_ALREADY_GRANTED', {
          namespace: 'ERROR'
        });
      } else {
        console.error(error);
      }
    }
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AsyncLoad promiseFn={fetchLearningLevelOptions}>
            <SelectField
              name="learningLevel"
              idKey="id"
              label={translate('STAGE_EDUCATION')}
              placeholder={translate('STAGE_EDUCATION_PLACEHOLDER')}
              displayEmpty
              options={learningLevelOptions}
              onChange={evt => {
                setLearningLevel(evt.target.value);
              }}
              style={{ width: '100%' }}
            />
          </AsyncLoad>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button variant="outlined" onClick={() => fnBack()}>
            {translate('COMMONS:BACK')}
          </Button>
          <Button
            variant="contained"
            disabled={!learningLevel}
            style={{ marginLeft: '15px' }}
            onClick={() => handleSelectedLearningLevel()}
          >
            {translate('COMMONS:CONTINUE')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default wrapper(SelectStageActingEducation, {
  namespace: 'REQUEST_ACCESS',
  mapState: state => ({
    userGovBr: state.auth.userGovBr
  })
});
