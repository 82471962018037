import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

export const CustomLink = styled(Link)`
  text-decoration: none;
  padding: 10px 20px;
  font-weight: 600;
  line-height: 16px;

  ${props => css`
    color: ${props.active
      ? props.theme.palette.primary.dark
      : props.theme.palette.primary.main};

    &:active,
    &:hover,
    &:focus {
      color: ${props.theme.palette.primary.light};
    }
  `}
`;
