export const emailOrDocumentValidation = value => {
  const emailRegex =
    /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const documentRegex = /(\d{11})+$/;

  const isValidEmail = emailRegex.test(value);
  const isValidPhone = documentRegex.test(value);

  return isValidEmail || isValidPhone;
};
