import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { FaCaretDown } from 'react-icons/fa';

import { authUseCases } from 'services/providers/auth';
import { ThemeContext } from 'styled-components';
import { formatCPF } from 'utils/textFormatUtils';

import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@mui/material';

import { useLangContext } from 'components/Globalization';

import { Container, ImgUser, ProfileToggler } from './styles';

const ProfileHeaderMenu = ({ user }) => {
  const [open, setOpen] = useState(false);

  const translate = useLangContext();
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const handleToggle = () => {
    setOpen(isPrevOpen => !isPrevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleSair = useCallback(async () => {
    await authUseCases.signOut();
  }, []);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const firstLetter = user.name.charAt(0);

  const theme = useContext(ThemeContext);

  return (
    <Container>
      <ProfileToggler
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        variant="text"
        onClick={handleToggle}
      >
        <ImgUser>{firstLetter}</ImgUser>
        <FaCaretDown style={{ color: theme.palette.primary.contrastText }} />
      </ProfileToggler>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    sx={{ alignItems: 'flex-start', flexDirection: 'column' }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {translate('LOGIN:USER')}
                    </Typography>
                    <Typography variant="body2">{user.name}</Typography>
                  </MenuItem>
                  <MenuItem
                    sx={{ alignItems: 'flex-start', flexDirection: 'column' }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {translate('LOGIN:EMAIL')}
                    </Typography>
                    <Typography variant="body2">{user.email}</Typography>
                  </MenuItem>
                  <MenuItem
                    sx={{ alignItems: 'flex-start', flexDirection: 'column' }}
                    divider
                  >
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {translate('LOGIN:CPF')}
                    </Typography>
                    <Typography variant="body2">
                      {formatCPF(user.cpf)}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    sx={{ justifyContent: 'flex-end', padding: '20px 16px' }}
                  >
                    <Button variant="outlined" onClick={handleSair}>
                      {translate('LOGIN:LOGOUT')}
                    </Button>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Container>
  );
};

export default ProfileHeaderMenu;
