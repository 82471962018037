import { awsTokenManager } from 'managers';

import { Axios } from './axios';

const http = new Axios({
  baseURL: window.env.REACT_APP_API_URL
  // withCredentials: true
});

http.useInterceptor(async config => {
  const token = await awsTokenManager.getToken();
  if (token && !config.removeAuth) config.headers.Authorization = token;

  return config;
});

export { http };
