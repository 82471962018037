import React, { useCallback } from 'react';

import useTabs from 'hooks/useTabs';

import { Grid } from '@mui/material';

import { AsyncLoad } from 'components/AsyncLoad';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { LITERARY_DIDACTIC_WORKS_ROUTES } from './constants/routes';
import ResourcesWorks from './fragments/ResourcesWorks';
import { literaryDidacticWorksUseCases } from './provider';
import { StyledContainer, ThickDivider, StyledTitle } from './styles';
import LiteraryDidacticWorksTab from './submodules/LiteraryDidacticWorksTab';

const TABS_ENUM = {
  LITERARY_DIDACTIC_WORKS: 0,
  OPEN_DIGITAL_RESOURCES: 1,
  LITERARY_DIDACTIC_WORKS_LIST: 2
};

const tabList = [
  {
    key: TABS_ENUM.LITERARY_DIDACTIC_WORKS,
    label: 'Livros didáticos, literários e pedagógicos',
    Component: LiteraryDidacticWorksTab
  },
  {
    key: TABS_ENUM.OPEN_DIGITAL_RESOURCES,
    label: 'Recursos Digitais abertos',
    Component: ResourcesWorks
  }
];

const LiteraryDidacticWorks = () => {
  const translate = useLangContext();

  const { TabContainer, tabContainerProps } = useTabs({
    tabs: tabList,
    defaultTab: 0
  });

  const fetchLicensesInfo = useCallback(async () => {
    try {
      await literaryDidacticWorksUseCases.getLicensesInfo();
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <StyledContainer>
      <AsyncLoad promiseFn={fetchLicensesInfo} />
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Grid mb="32px">
            <StyledTitle
              fontSize={{ lg: '34px', md: '28px', sm: '28px', xs: '28px' }}
              variant="h1"
              component="h1"
            >
              {translate('LITERARY_DIDACTIC_WORKS:TITLE')}
            </StyledTitle>
            <ThickDivider />
          </Grid>
        </Grid>
      </Grid>
      <TabContainer {...tabContainerProps} />
    </StyledContainer>
  );
};

export default wrapper(LiteraryDidacticWorks, {
  namespace: 'LITERARY_DIDACTIC_WORKS',
  route: {
    path: LITERARY_DIDACTIC_WORKS_ROUTES.LITERARY_DIDACTIC_WORKS,
    breadcrumb: 'LITERARY_DIDACTIC_WORKS'
  }
});
