import React, { useCallback } from 'react';

import { authUseCases } from 'services/providers/auth';

import { Button, Container, Grid, Typography } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import WarningTag from 'components/Warnings/fragments/Tag';
import wrapper from 'components/Wrapper';

const SuccessMessage = () => {
  const translate = useLangContext();

  const handleClose = useCallback(async () => {
    await authUseCases.signOut();
  }, []);

  return (
    <Container>
      <Grid container spacing={3} py={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {translate('SUCCESS_MESSAGE_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <WarningTag
            warning={{
              message: translate('SUCCESS_MESSAGE'),
              options: { variant: 'success', hideCloseButton: true },
              style: {
                minWidth: '100%'
              }
            }}
          />
        </Grid>
        <Grid item container justifyContent="flex-end" xs={12}>
          <Button variant="contained" onClick={handleClose}>
            {translate('COMMONS:CLOSE')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default wrapper(SuccessMessage, {
  namespace: 'REQUEST_ACCESS'
});
