/* eslint-disable react/no-danger */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { ThemeContext } from 'styled-components';

import { Box, Container, Grid, Typography, Hidden } from '@mui/material';

import { downloadFile } from 'helpers/downloadFile';

import { AsyncLoad } from 'components/AsyncLoad';
import Carousel from 'components/Carousel';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { LITERARY_DIDACTIC_WORKS_ROUTES } from 'modules/LiteraryDidacticWorks/constants/routes';
import { literaryDidacticWorksUseCases } from 'modules/LiteraryDidacticWorks/provider';
import { notificationUseCases } from 'modules/Notification/providers';

import debian from 'assets/img/logo-debian.png';
import linux from 'assets/img/logo-linux.png';
import mac from 'assets/img/logo-mac.png';
import windows from 'assets/img/logo-windows.png';

import {
  CustomButton,
  DownloadLink,
  Img,
  DownloadImg,
  Tag,
  Tags,
  BoxContainer,
  CustomBox,
  CircleImageContainer,
  DividerSpace,
  CircleNumberContainer,
  CustomGrid,
  Title,
  TitleInfo,
  TextInfo,
  SubTitle
} from './styles';

const LiteraryDidacticWorksDetail = ({ role }) => {
  const history = useHistory();
  const translate = useLangContext();
  const { state } = useLocation();
  const { workInfo, inep } = state;
  const [relatedWorks, setRelatedWorks] = useState([]);
  const theme = useContext(ThemeContext);

  const fetchRelatedWorks = useCallback(async () => {
    try {
      const response = await literaryDidacticWorksUseCases.getRelatedWorkList(
        inep,
        workInfo.volumeCollection
      );
      setRelatedWorks(response);
    } catch (error) {
      console.error(error);
    }
  }, [inep, workInfo.volumeCollection]);

  const isRoleStudent = role === 'ROLE_STUDENT';

  const sendPasswordByEmail = async () => {
    try {
      await literaryDidacticWorksUseCases.postReceivePasswordByEmail();
      notificationUseCases.success('WILL_RECEIVE_AN_EMAIL_WITH_PASSWORD');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = async id => {
    try {
      const res = await literaryDidacticWorksUseCases.getFileUrl(id);
      downloadFile(res, workInfo?.titleBook);
    } catch (error) {
      console.error(error);
    }
  };

  const handleView = data => {
    const path = generatePath(
      LITERARY_DIDACTIC_WORKS_ROUTES.LITERARY_DIDACTIC_WORKS_DETAIL,
      {
        volumeCode: data.volumeCode
      }
    );

    history.push({
      pathname: path,
      state: {
        workInfo: data,
        inep
      }
    });
  };

  const backgroundColor =
    workInfo.component.backgroundColor || theme.palette.text.gray;

  const authorInfo = useMemo(() => {
    if (workInfo.authorList.length <= 3) {
      return [workInfo.authorList.join(', ')].filter(Boolean).join(' | ');
    }
    return [
      workInfo.titleBook,
      `${workInfo.authorList.slice(0, 2).join(', ')} et al.`,
      workInfo.publisher
    ]
      .filter(Boolean)
      .join(' | ');
  }, [workInfo]);

  const downloadLinks = [
    {
      href: 'https://apps.microsoft.com/store/detail/thorium-reader/9NFZP1G7M2SC?hl=en-us&gl=us',
      platform: 'Windows',
      imgSrc: windows
    },
    {
      href: 'https://github.com/edrlab/thorium-reader/releases/download/v2.4.1/Thorium-2.4.1.AppImage',
      platform: 'Linux',
      imgSrc: linux
    },
    {
      href: 'https://github.com/edrlab/thorium-reader/releases/download/v2.4.1/Thorium-2.4.1.dmg',
      platform: 'MacOs',
      imgSrc: mac
    },
    {
      href: 'https://github.com/edrlab/thorium-reader/releases/download/v2.4.1/EDRLab.ThoriumReader_2.4.1_amd64.deb',
      platform: 'Debian',
      imgSrc: debian
    }
  ];

  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: '60vh'
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <Hidden lgUp>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} mt={2} mb={3}>
                <Title variant="h1" color="primary">
                  {workInfo.titleBook}
                </Title>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item container xs={12}>
                <TitleInfo component="h4">
                  {translate(`LITERARY_DIDACTIC_WORKS:YEAR`)}:
                </TitleInfo>
                <TextInfo component="p" fontSize="18px">
                  <b>{workInfo.typeYearSeries.name}</b>
                </TextInfo>
              </Grid>
              <Grid item container xs={12}>
                <TitleInfo component="h4">
                  {translate(`LITERARY_DIDACTIC_WORKS:AUTHORS`)}:
                </TitleInfo>
                <TextInfo component="p">{authorInfo}</TextInfo>
              </Grid>
              <Grid item container xs={12}>
                <TitleInfo component="h4">
                  {translate(`LITERARY_DIDACTIC_WORKS:PUBLISHER`)}:
                </TitleInfo>
                <TextInfo component="p">{workInfo.publisher}</TextInfo>
              </Grid>
            </Grid>
          </Hidden>
          <Img src={workInfo.urlCover} alt="cover" />
        </Grid>

        <CustomGrid item xs={12} lg={9}>
          <Tags>
            <Tag backgroundColor={backgroundColor}>
              {workInfo.segment.description}
            </Tag>
            <Tag backgroundColor={backgroundColor}>
              {translate(
                `LITERARY_DIDACTIC_WORKS:TYPE_LITERARY_OPTIONS.${workInfo.bookType}`
              )}
            </Tag>
            <Tag backgroundColor={backgroundColor}>
              {workInfo.component.name}
            </Tag>
          </Tags>
          <Hidden lgDown>
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <Box mt={2} mb={3}>
                  <Title variant="h1" color="primary">
                    {workInfo.titleBook}
                  </Title>
                </Box>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden lgDown>
            <Grid container>
              <Grid item container xs={12}>
                <TitleInfo component="h4">
                  {translate(`LITERARY_DIDACTIC_WORKS:YEAR`)}:
                </TitleInfo>
                <TextInfo component="p" fontSize="18px">
                  <b>{workInfo.typeYearSeries.name}</b>
                </TextInfo>
              </Grid>
              <Grid item container xs={12}>
                <TitleInfo component="h4">
                  {translate(`LITERARY_DIDACTIC_WORKS:AUTHORS`)}:
                </TitleInfo>
                <TextInfo component="p">{authorInfo}</TextInfo>
              </Grid>
              <Grid item container xs={12}>
                <TitleInfo component="h4">
                  {translate(`LITERARY_DIDACTIC_WORKS:PUBLISHER`)}:
                </TitleInfo>
                <TextInfo component="p">{workInfo.publisher}</TextInfo>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <CustomBox warn>
              <Typography
                mb={5}
                variant="p"
                component="p"
                dangerouslySetInnerHTML={{
                  __html: translate('ALERT_LABEL')
                }}
              />

              <Typography
                variant="p"
                component="p"
                dangerouslySetInnerHTML={{
                  __html: translate('ALERT_LABEL_COMPLETE')
                }}
              />
            </CustomBox>
          </Hidden>

          <Hidden lgDown>
            <>
              <DividerSpace>
                <SubTitle component="h3">{translate('DIVIDER_TEXT')}</SubTitle>
              </DividerSpace>
              <BoxContainer spacing={3} container alignItems="stretch">
                <Grid item xs={3} md={4}>
                  <CustomBox container spacing={0}>
                    <CircleNumberContainer transparent>1</CircleNumberContainer>
                    <Typography
                      mb="24px"
                      variant="p"
                      component="p"
                      fontSize={14}
                      sx={{
                        padding: '0 8px 0 8px'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: translate('DOWNLOAD_DESK')
                      }}
                    />
                    <Grid
                      alignContent="center"
                      justifyContent="space-evenly"
                      container
                      spacing={3}
                    >
                      {downloadLinks.map(link => (
                        <Grid
                          sx={{
                            width: { xs: 20, md: 60 }
                          }}
                          item
                          key={link.platform}
                        >
                          <DownloadLink
                            href={link.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CircleImageContainer
                              sx={{
                                width: { xs: 10, md: 10 },
                                height: { xs: 10, md: 10 }
                              }}
                              pointer
                            >
                              <DownloadImg src={link.imgSrc} />
                            </CircleImageContainer>
                            {translate('DOWNLOAD_READER:PLATFORM', {
                              platform: link.platform
                            })}
                          </DownloadLink>
                        </Grid>
                      ))}
                    </Grid>
                  </CustomBox>
                </Grid>
                <Grid item xs={3} md={4}>
                  <CustomBox container spacing={0}>
                    <CircleNumberContainer transparent>2</CircleNumberContainer>
                    <Typography
                      mb="24px"
                      variant="p"
                      fontSize={14}
                      component="p"
                      sx={{
                        padding: '0 8px 0 8px'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: translate('RECEIVE_PASSWORD_LABEL')
                      }}
                    />

                    <Typography
                      sx={{
                        padding: '0 8px 0 8px'
                      }}
                      fontSize={14}
                      variant="p"
                      component="p"
                    >
                      {translate('RECEIVE_PASSWORD_LABEL_COMPLETE')}
                    </Typography>
                    <CustomButton
                      transparent
                      variant="outlined"
                      onClick={sendPasswordByEmail}
                    >
                      {translate('REQUEST_PASSWORD')}
                    </CustomButton>
                  </CustomBox>
                </Grid>
                <Grid item xs={3} md={4}>
                  <CustomBox container spacing={0}>
                    <CircleNumberContainer transparent>3</CircleNumberContainer>
                    <Typography
                      variant="p"
                      component="p"
                      fontSize={14}
                      padding={1}
                      dangerouslySetInnerHTML={{
                        __html: translate('DOWNLOAD_BOOK_LABEL')
                      }}
                    />
                    <CustomButton
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        handleDownload(workInfo.digitalVolumeDocument.id)
                      }
                    >
                      {translate('DOWNLOAD_BOOK')}
                    </CustomButton>
                  </CustomBox>
                </Grid>
              </BoxContainer>
            </>
          </Hidden>
        </CustomGrid>

        {!isRoleStudent && (
          <AsyncLoad promiseFn={fetchRelatedWorks}>
            {relatedWorks.length > 0 && (
              <Grid item xs={12}>
                <Box mt={4}>
                  <Carousel
                    data={relatedWorks}
                    handleView={handleView}
                    volumeCode={workInfo.volumeCode}
                  />
                </Box>
              </Grid>
            )}
          </AsyncLoad>
        )}
      </Grid>
    </Container>
  );
};

export default wrapper(LiteraryDidacticWorksDetail, {
  namespace: 'LITERARY_DIDACTIC_WORKS_DETAIL',
  mapState: state => ({
    role: state.auth.role
  }),
  route: {
    path: LITERARY_DIDACTIC_WORKS_ROUTES.LITERARY_DIDACTIC_WORKS_DETAIL
  }
});
