import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;
export const ScrollableContainer = styled.div`
  margin-bottom: 25px;
`;
export const DataProcessingContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  padding: 0 20px 20px 0;
`;
export const ContainerPopUp = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  padding: 15px;

  ${props =>
    props.show
      ? css`
          &:hover,
          &:active,
          &:focus {
            & div {
              display: flex;
            }
          }
        `
      : css``}
`;
export const TitlePopUp = styled.h5`
  margin: 15px 0 25px;
  font-weight: normal;
  font-size: 14px;
`;
