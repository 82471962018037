import styled from 'styled-components';

import { Box, Button, Typography, Grid } from '@mui/material';

export const Img = styled.img`
  border: 1px solid ${props => props.theme.palette.border.primary};
  max-width: 456px;
  width: 100%;
  display: block;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    margin: 0 auto;
    max-width: 85%;
  }

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    max-width: 100%;
    margin: 0;
    object-fit: cover;
    height: 210px;
  }
`;

export const DownloadImg = styled.img`
  height: 70%;
`;

export const Top = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    flex-wrap: wrap;
  }
`;

export const Tags = styled.div`
  align-items: strech;
  display: flex;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    order: 2;
  }
`;

export const Tag = styled.div`
  align-items: center;
  border-radius: 4px;
  color: ${props => props.theme.palette.text.light};
  background-color: ${props =>
    props.backgroundColor || props.theme.palette.text.light};
  display: flex;
  font-size: 14px;
  padding: 6px 24px;
  text-align: center;
  white-space: nowrap;
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    padding: 6px 13px;
    max-width: 100%;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }
`;

const getVariantStyles = (variant, theme) => {
  switch (variant) {
    case 'outlined':
      return `
        background-color: ${theme.palette.background.white};
      `;

    default:
      return `
			background-color: ${theme.palette.interactive.main};
			border: 10px solid #F0F0F0;
			color: ${theme.palette.primary.contrastText};
			font-size: 20px;
			`;
  }
};

export const CustomButton = styled(Button)`
  &.MuiButtonBase-root {
    display: block;
    padding: 8px 24px;
    font-weight: 600;
    font-size: 16px;
    margin: 15px auto 0;
    border-radius: 40px;
    text-transform: none;
    ${({ variant, theme }) => `
      ${getVariantStyles(variant, theme)}
    `} @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
      font-size: 16px;
    }
  }
`;

export const TypographyWithMargin = styled(Typography)`
  padding: 30px 0;
`;

export const DownloadLink = styled.a`
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  color: ${props => props.theme.palette.primary.main};
  cursor: inherit;
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    font-size: 10px;
    margin-bottom: 15px;
  }
`;

export const CustomParagraph = styled(Typography)`
  font-size: 14px;
  display: block;
  padding: 30px 0;
  padding-top: 30px;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const CustomButtonHere = styled.button`
  font-weight: bold;
  color: ${props => props.theme.palette.info.main};
  cursor: pointer;
  margin: 0 0 2px 2px;
  border: 0;
  background: none;
  font-size: 23px;
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    font-size: 16px;
  }
`;

export const BoxContainer = styled(Grid)`
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    font-size: 16px;
  }
`;

export const CustomBox = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  background-color: ${props =>
    props.warn
      ? props.theme.palette.warning.light
      : props.theme.palette.text.light};
  @media (max-width: 1420px) {
    font-size: 14px;
    padding: 8px 8px;
  }
  @media (max-width: ${props => props.theme.breakpoints.values.lg}px) {
    font-size: 16px;
    padding: 32px 32px;
    margin: 24px 0 24px 0;
    height: auto;
  }
`;

export const CircleImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin: 10px auto 3px auto;
  background-color: ${props =>
    props.transparent ? 'transparent' : props.theme.palette.background.white};
  border: ${props => `1px solid ${props.theme.palette.primary.main}`};
  color: ${props => props.theme.palette.primary.main};
  width: 48px;
  height: 48px;
  cursor: ${props => (props.pointer ? 'pointer' : 'inherit')};
`;

export const CircleNumberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin: 10px auto 15px auto;
  background-color: ${props =>
    props.transparent ? 'transparent' : props.theme.palette.background.white};
  border: ${props => `1px solid ${props.theme.palette.primary.main}`};
  color: ${props => props.theme.palette.primary.main};
  width: 40px;
  height: 40px;
  cursor: ${props => (props.pointer ? 'pointer' : 'inherit')};
`;

export const DividerSpace = styled(Box)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.primary.main};
  margin: 1% 0 1% 0;
`;

export const CustomGrid = styled(Grid)`
  margin-bottom: 24px;
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-size: 41px;
    border-bottom: 2px solid ${props => props.theme.palette.interactive.main};
    width: 100%;
    padding-bottom: 16px;
  }
`;

export const TitleInfo = styled(Typography)`
  &.MuiTypography-root {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

export const TextInfo = styled(Typography)`
  &.MuiTypography-root {
    font-size: ${props => props.fontSize || '14px'};
    margin-left: 5px;
  }
  b {
    margin-top: -2px;
    display: block;
  }
`;

export const SubTitle = styled(Typography)`
  &.MuiTypography-root {
    border-bottom: 2px solid ${props => props.theme.palette.interactive.main};
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 8px;
  }
`;
