import React from 'react';

import { useStepperContext } from 'hooks/useStepper/StepperContext';

import { Button, Grid } from '@mui/material';

import history from 'framework/history';

import { useLangContext } from 'components/Globalization';

import { REQUEST_ACCESS_ROUTES } from '../../../../constants/routes';

const ActionsButtons = ({
  confirmButtonDisable = false,
  customHandleBack = () => {}
}) => {
  const translate = useLangContext();
  const { fnBack } = useStepperContext();

  const backHandler = () => customHandleBack() || fnBack();

  const cancelHandler = () =>
    history.push(REQUEST_ACCESS_ROUTES.SELECT_PROFILE);

  return (
    <Grid container justifyContent="space-between" py={3}>
      <Grid item>
        <Button onClick={cancelHandler}>{translate('COMMONS:CANCEL')}</Button>
      </Grid>

      <Grid item>
        <Button variant="outlined" onClick={backHandler}>
          {translate('COMMONS:BACK')}
        </Button>

        <Button
          type="submit"
          variant="contained"
          disabled={confirmButtonDisable}
          style={{ marginLeft: '15px' }}
        >
          {translate('COMMONS:GO_FORWARD')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ActionsButtons;
