import * as Yup from 'yup';

Yup.addMethod(Yup.boolean, 'fromString', function fromString() {
  return this.transform(val => {
    if (typeof val === 'boolean') {
      return val;
    }

    if (val === 'true') {
      return true;
    }

    if (val === 'false') {
      return false;
    }

    return null;
  }).nullable();
});
