export const formatCPF = value => {
  if (!value) return '';
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const formatCNPJ = value => {
  if (!value) return '';
  if (value.length === 14)
    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  return value;
};

export const formatPhoneNumber = value => {
  if (!value) return '';

  if (value.length === 10) {
    return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  if (value.length === 11) {
    return value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $4-$4');
  }

  return value;
};

export const formatCEP = value => {
  if (!value) return '';
  if (value.length === 8) return value.replace(/(\d{5})(\d{3})/, '$1-$2');
  return value;
};

export const formatCPFText = value => {
  const cpf = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '***.$2.$3-**');
  return cpf;
};
