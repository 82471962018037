/* eslint-disable class-methods-use-this */
export class LocalStorage {
  _getKeyCognito() {
    return Object.keys(localStorage).filter(
      key => key.indexOf('CognitoIdentityServiceProvider') >= 0
    );
  }

  set(key, value) {
    if (typeof value === 'object') {
      value = `[object]${JSON.stringify(value)}`;
    }

    localStorage.setItem(key, value);
  }

  get(key) {
    let value = localStorage.getItem(key);

    if (value && value.startsWith('[object]')) {
      value = JSON.parse(value.replace('[object]', ''));
    }

    return value;
  }

  isCognitoValid() {
    return this._getKeyCognito().length === 4;
  }

  removeTokenCognito() {
    this._getKeyCognito().forEach(key => {
      this.remove(key);
    });
  }

  remove(key) {
    return localStorage.removeItem(key);
  }

  getStorage() {
    return localStorage;
  }
}
