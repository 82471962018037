import styled from 'styled-components';

export const TagWarning = styled.div`
  min-width: 980px;
  padding: 15px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
`;
